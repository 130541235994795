//React
import React from "react"

//Components
import Layout from "../../components/layout"
import SignUpForm from "./signUpForm"

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Layout>
        <div class="container d-flex flex-grow-1">
          <div class="row d-flex flex-grow-1 flex-row justify-content-center align-items-start mt-3 mt-lg-4">
            <div class="col-md-8 p-3 pt-5 text-center ">
              <SignUpForm />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default Snippet
