//React
import React from "react"

//Packages

//Icons
import { MdFormatColorFill } from "react-icons/md"
import { BiRectangle, BiCircle, BiImage, BiPointer } from "react-icons/bi"
import { BsTextareaT } from "react-icons/bs"
import { MdLayersClear } from "react-icons/md"
import {
  RiSubtractLine,
  RiImageAddFill,
  RiAddBoxLine,
  RiAddCircleLine,
} from "react-icons/ri"

const Snippet = ({
  elements,
  addNewElement,
  updatePublisherField,
  deselectStage,
}) => {
  return (
    <>
      <div
        class="d-flex flex-row flex-wrap align-items-center justify-content-center py-2 px-2 tools-bg"
        // style={{ borderRadius: 6, padding: 6, backgroundColor: "#f2f2f2" }}
      >
        {/* <button
          class="btn btn-slim btn-tool-add p-1 mr-1"
          type="button"
          onClick={() => {
            addNewElement("line")
          }}
        >
          <RiSubtractLine size={22} />
        </button> */}
        <button
          class="btn btn-slim btn-tool-add p-1 mr-1"
          type="button"
          onClick={() => {
            deselectStage()
          }}
        >
          <BiPointer size={22} />
        </button>
        <div
          class="border-right-1-gray-5 ml-1 mr-2"
          style={{ width: 1, height: 20, marginTop: 4 }}
        ></div>
        <button
          class="btn btn-slim btn-tool-add p-1 mr-1"
          type="button"
          onClick={() => {
            addNewElement("rect")
          }}
        >
          <RiAddBoxLine size={22} />
        </button>
        <button
          class="btn btn-slim btn-tool-add p-1 mr-1"
          type="button"
          onClick={() => {
            addNewElement("circle")
          }}
        >
          <RiAddCircleLine size={22} />
        </button>
        <button
          class="btn btn-slim btn-tool-add p-1 mr-1"
          type="button"
          onClick={() => {
            addNewElement("text")
          }}
        >
          <BsTextareaT size={22} />
        </button>
        <button
          class="btn btn-slim btn-tool-add p-1 mr-1"
          type="button"
          onClick={() => {
            // deselectStage()
            deselectStage().then(() => {
              addNewElement("image")
            })
          }}
        >
          <RiImageAddFill size={22} />
        </button>
        <div
          class="border-right-1-gray-5 ml-1 mr-2"
          style={{ width: 1, height: 20, marginTop: 4 }}
        ></div>
        <button
          class="btn btn-slim btn-tool-add p-1 mr-1"
          type="button"
          data-toggle="modal"
          data-target="#alertNewCanvasModal"
          disabled={elements && elements.length === 0}
        >
          <MdLayersClear size={22} />
        </button>
      </div>
    </>
  )
}

export default Snippet
