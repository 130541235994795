import React from "react"
import { FaInstagram, FaSnapchatGhost } from "react-icons/fa"

export default class Snippet extends React.Component {
  constructor(props) {
    super(props)
  }

  displayIcon(platformName) {
    if (platformName === "Instagram") {
      return <FaInstagram size={30} className="social-icon blue-1" />
    } else if (platformName === "Snapchat") {
      return <FaSnapchatGhost size={26} className="social-icon blue-1" />
    } else if (platformName === "TikTok") {
      return (
        // <img
        //   width="26"
        //   height="26"
        //   src="https://storylavamedia.s3.amazonaws.com/static/icons/tiktok.svg"
        // />
        <div class="btn-intent-social-tiktok-icon"></div>
      )
    }
  }
  render() {
    let downloadLinkIOS
    let intentLinkAndroid
    let intentLinkIOS
    let platformName = ""

    if (this.props.platformName === "Instagram") {
      intentLinkAndroid =
        "intent://instagram.com/#Intent;package=com.instagram.android;scheme=https;end"
      downloadLinkIOS = "https://apps.apple.com/us/app/instagram/id389801252"
      intentLinkIOS = "instagram://camera"
      platformName = "instagram"
    } else if (this.props.platformName === "Snapchat") {
      intentLinkAndroid =
        "intent://snapchat.com/#Intent;package=com.snapchat.android;scheme=https;end"
      downloadLinkIOS = "https://apps.apple.com/us/app/snapchat/id447188370"
      intentLinkIOS = "snapchat://"
      platformName = "snapchat"
    } else if (this.props.platformName === "TikTok") {
      intentLinkAndroid =
        "intent://tiktok.com/#Intent;package=com.zhiliaoapp.musically;scheme=https;end"
      downloadLinkIOS =
        "https://apps.apple.com/us/app/tiktok-make-your-day/id835599320"
      intentLinkIOS = "musically://"
      platformName = "tiktok"
    }

    console.log("[[[this.props--]]]")
    console.log(this.props)
    console.log("[[[intentLinkAndroid]]]")
    console.log(intentLinkAndroid)
    return (
      <div class="row d-flex flex-row align-items-center justify-content-start">
        <div class="share-instruction-sub">
          {this.props.currentOS === "iOS" ? (
            this.props.toggleChecked ? (
              <a
                href={intentLinkIOS}
                class={`btn btn-intent btn-intent-social btn-intent-social-${platformName.toLowerCase()} blue-1 d-flex flex-row justify-content-center align-items-center text-center`}
              >
                {this.displayIcon(this.props.platformName)}
                {/* <FaInstagram size={40} className="social-icon blue-1" /> */}

                <div class="pl-2">Open {this.props.platformName} on iOS</div>
              </a>
            ) : (
              <a
                href={downloadLinkIOS}
                target="_blank"
                norel
                nofollow
                class="btn btn-intent btn-intent-social blue-1 d-flex flex-row justify-content-center align-items-center text-center"
              >
                {this.displayIcon(this.props.platformName)}
                {/* <FaInstagram size={40} className="social-icon blue-1" /> */}

                <div class="pl-2">
                  Download {this.props.platformName} for iOS
                </div>
              </a>
            )
          ) : null}
          {this.props.currentOS === "Android" ? (
            <a
              href={intentLinkAndroid}
              class="btn btn-intent btn-intent-social blue-1 d-flex flex-row justify-content-center align-items-center text-center"
            >
              {this.displayIcon(this.props.platformName)}
              {/* <FaInstagram size={40} className="social-icon blue-1" /> */}

              <div class="pl-2">Open {this.props.platformName} on Android</div>
            </a>
          ) : null}
        </div>
      </div>
    )
  }
}
