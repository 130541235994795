import React, { useEffect } from "react"

//Packages
import Konva, { Image, Transformer, Group, Rect } from "react-konva"

const ImageSnippet = ({
  stageRef,
  imageRef,
  isSelected,
  shapeProps,
  onSelect,
  onChange,
  clipped,
  clipX,
  clipY,
  clipWidth,
  clipHeight,
  image,
}) => {
  const shapeRef2 = React.useRef()
  const trRef2 = React.useRef()
  const rectRef2 = React.useRef()
  const groupRef2 = React.useRef()

  // useEffect(() => {
  //   console.log("considered - shapeRef2 - SUB - batchDraw")
  //   // console.log("image")
  //   // console.log(image)
  //   // console.log("shapeRef2")
  //   // console.log(shapeRef2)
  //   console.log(clipX)
  //   console.log(clipY)
  //   console.log(clipWidth)
  //   console.log(clipHeight)
  //   console.log("*****")
  //   if (image && shapeRef2 && shapeRef2.current) {
  //     // shapeRef2.current.cache()
  //     console.log("useEffect - shapeRef2")
  //     console.log(shapeRef2)
  //     console.log("useEffect - shapeRef2.current")
  //     console.log(shapeRef2.current)
  //     // shapeRef2.current.getLayer().draw()
  //     groupRef2.current.draw()
  //   }
  // }, [image])

  return (
    <React.Fragment>
      <Group
        clipX={clipX}
        clipY={clipY}
        clipWidth={clipWidth}
        clipHeight={clipHeight}
        ref={groupRef2}
      >
        <Image
          onClick={onSelect}
          onTap={onSelect}
          ref={shapeRef2}
          {...shapeProps}
          image={image}
        />
      </Group>
    </React.Fragment>
  )
}

export default ImageSnippet
