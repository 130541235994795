import React, { useEffect, useState } from "react"

import { Auth } from "aws-amplify"

//Components
import TemplateTags from "./templateTags"
import Loading from "../loading"

//Icons
import { FaArrowRight } from "react-icons/fa"
import { MdInfoOutline } from "react-icons/md"

const Snippet = (props) => {
  const {
    saveTemplateComplete,
    forms,
    updateFormField,
    isLoadingTemplateCreate,
    removeTemplate,
    updateTemplate,
    clearForm,
    publisher,
    userRole,
    userPlan,
    deleteButton,
    showNewElements,
    imageThumbElements,
    setImageThumbElements,
    imageThumb,
    setImageThumb,
    resetTemplateDelete,
  } = props

  const [userSub, setUserSub] = useState(null)

  const [templateId, setTemplateId] = useState(null)
  const [templateTitle, setTemplateTitle] = useState(null)

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      setUserSub(user.username)
    })
  }, [])

  useEffect(() => {
    if (forms?.edittemplate?.thumb) {
      setImageThumb(forms.edittemplate.thumb)
    }
    if (forms?.edittemplate?.thumbelements) {
      setImageThumbElements(forms.edittemplate.thumbelements)
    }
    if (forms?.edittemplate?.templateid) {
      setTemplateId(forms.edittemplate.templateid)
    }
  })

  // console.log("****userSub")
  // console.log(userSub)
  // console.log("templateSaveForm - forms")
  // console.log(forms)
  // console.log("templateSaveForm - imageThumb")
  // console.log(imageThumb)
  // console.log("****!!forms")
  // console.log(forms)
  // console.log("****!!publisher")
  // console.log(publisher)
  // console.log("****!!templateId")
  // console.log(templateId)
  console.log("**** TEMPLATEEDITFORM !!publisher?.stage")
  console.log(publisher?.stage)

  const currentTemplateTags =
    publisher &&
    publisher.templates &&
    publisher.templates.find((template) => template.id === templateId) &&
    publisher.templates.find((template) => template.id === templateId).tags
      ? publisher.templates.find((template) => template.id === templateId).tags
      : []

  console.log("TEMPLATEEDITFORM ----- forms.savetemplate.globaltemplate")
  console.log(forms.savetemplate.globaltemplate)
  return (
    <div class="reporting-container w-100 p-4">
      <div class="form-group row d-flex flex-row align-items-center justify-content-start">
        <div class="offset-sm-2 col-sm-10 d-flex flex-row align-items-center justify-content-start">
          <img src={imageThumb} width={150} class="border-1-gray-5" />
          {showNewElements ? (
            <>
              <FaArrowRight size={22} class="black-5 mx-3" />
              <img
                src={imageThumbElements}
                width={150}
                class="border-1-gray-5"
              />
            </>
          ) : null}
        </div>
      </div>
      <div class="form-group row d-flex flex-row align-items-center justify-content-start">
        <label
          for="template-name-edit"
          class="col-sm-2 col-form-label font-weight-light line-height-1"
        >
          Template name
        </label>
        <div class="col-sm-10">
          <input
            type="text"
            class="form-control"
            id="template-name-edit"
            placeholder="Enter the template name"
            value={
              forms.edittemplate && forms.edittemplate.title
                ? forms.edittemplate.title
                : ""
            }
            onChange={(e) => {
              updateFormField("edittemplate", "title", e.target.value)
              updateFormField("savetemplate", "title", e.target.value)
            }}
          />
        </div>
      </div>
      <div class="form-group row d-flex flex-row align-items-center justify-content-start">
        <label
          for="template-tags"
          class="col-sm-2 col-form-label font-weight-light line-height-1"
        >
          Tags
        </label>
        <div class="col-sm-10">
          <div class="d-flex flex-row align-items-center justify-content-start">
            <TemplateTags
              context="edittemplate"
              updateFormField={updateFormField}
              templateId={templateId}
              currentTemplateTags={currentTemplateTags}
              currentTags={
                forms.edittemplate && forms.edittemplate.tags
                  ? forms.edittemplate.tags
                  : []
              }
            />
          </div>
        </div>
      </div>

      <div class="form-group row d-flex flex-row align-items-center justify-content-start">
        <label
          for="accessControl"
          class="col-sm-2 col-form-label font-weight-light line-height-1"
        >
          Access
        </label>
        <div class="col-sm-10">
          <div class="d-flex flex-row align-items-center justify-content-start">
            <select
              class="form-control"
              id="accessControl"
              onChange={(e) => {
                console.log("templateEditForm - accessControl - e.target")
                console.log(e.target.value)
                updateFormField(
                  "edittemplate",
                  "globaltemplate",
                  e.target.value
                )
                updateFormField(
                  "savetemplate",
                  "globaltemplate",
                  e.target.value
                )
              }}
              value={forms.edittemplate.globaltemplate}
            >
              <option value="public">Public</option>
              {userRole &&
              userRole === "admin" &&
              userPlan &&
              userPlan === "proplus" ? (
                <option value="global">My company</option>
              ) : null}

              {userPlan && userPlan === "proplus" ? (
                <option value="private">Only me</option>
              ) : null}
            </select>
            <a
              href={`https://${process.env.HOSTNAME_SUPPORT}/article/template-access/`}
              target="_blank"
              class="d-flex flex-row p-2"
            >
              <MdInfoOutline size={20} className="gray-2" />{" "}
              {/* <span class="gray-2 small">Access levels</span> */}
            </a>
          </div>
        </div>
      </div>

      {/* {userRole && userRole === "admin" ? (
        <div class="form-group row d-flex flex-row align-items-center justify-content-start">
          <label
            for="globaltemplate"
            class="col-sm-2 col-form-label font-weight-light line-height-1"
          >
            Save as global company template
          </label>
          <div class="col-sm-10">
            <div class="d-flex flex-row align-items-center justify-content-start pl-3">
              <input
                class="form-check-input"
                type="checkbox"
                // value={forms.edittemplate.globaltemplate}
                // defaultValue={1}
                checked={forms.edittemplate.globaltemplate}
                id="globaltemplate"
                onChange={(e) => {
                  console.log("templateEditForm - e.target")
                  console.log(e.target)
                  let checkedVal = event.target.checked ? 1 : 0
                  updateFormField("edittemplate", "globaltemplate", checkedVal)
                }}
              />
            </div>
          </div>
        </div>
      ) : null} */}

      {/* <div class="form-group row d-flex flex-row align-items-center justify-content-start">
        <label
          for="publictemplate"
          class="col-sm-2 col-form-label font-weight-light line-height-1"
        >
          Save as public template
        </label>
        <div class="col-sm-10">
          <div class="d-flex flex-row align-items-center justify-content-start pl-3">
            <input
              class="form-check-input"
              type="checkbox"
              // value={forms.edittemplate.publictemplate}
              // defaultValue={1}
              checked={forms.edittemplate.publictemplate}
              id="publictemplate"
              onChange={(e) => {
                console.log("templateEditForm - e.target")
                console.log(e.target)
                let checkedVal = event.target.checked ? 1 : 0
                updateFormField("edittemplate", "publictemplate", checkedVal)
              }}
            />
          </div>
        </div>
      </div> */}

      <div class="mt-2 d-flex flex-row align-items-center justify-content-center">
        {/* {deleteButton ? (
          <button
            class="btn btn-danger mr-auto"
            onClick={() => {
              removeTemplate(templateId)
            }}
          >
            Delete
          </button>
        ) : null} */}
        <button
          class="btn btn-link"
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            clearForm("savetemplate")
            clearForm("edittemplate")
            clearForm("workingtags")
            setImageThumb(null)
            setImageThumbElements(null)
            resetTemplateDelete()
            $("#pills-new-tab").tab("show") // Select tab by name
          }}
        >
          Cancel
        </button>
        <button
          class="btn btn-primary"
          onClick={() => {
            setImageThumb(null)
            setImageThumbElements(null)
            updateTemplate(
              templateId,
              forms.edittemplate.title,
              forms.edittemplate.tags,
              forms.edittemplate.globaltemplate,
              forms.edittemplate?.elements,
              forms.edittemplate?.thumbelements,
              forms.edittemplate?.fname,
              forms.edittemplate?.lname,
              publisher?.stage
            )
          }}
        >
          Save
        </button>

        {isLoadingTemplateCreate ? <Loading class="small ml-3" /> : null}
      </div>
    </div>
  )
}
export default Snippet
