import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"

import { navigate } from "@reach/router"

import { logoutUser } from "./userManagement"
import { unauthorizedMessage } from "../utils/constants"

export function updateFormField(group, prop, val) {
  console.log("###actions - updateFormField - pre dispatch###")
  // console.log("updateFormField - group")
  // console.log(group)
  // console.log("updateFormField - prop")
  // console.log(prop)
  // console.log("updateFormField - val")
  // console.log(val)
  return (dispatch) => {
    dispatch({
      type: "UPDATE_FORM_FIELD",
      data: { group, prop, val },
    })
  }
}
export function updateFormFieldSingle(prop, val) {
  console.log("###actions - updateFormFieldSingle - pre dispatch###")
  console.log("updateFormField - prop")
  console.log(prop)
  console.log("updateFormField - val")
  console.log(val)
  return (dispatch) => {
    dispatch({
      type: "UPDATE_FORM_FIELD_SINGLE",
      data: { prop, val },
    })
  }
}
export function clearFormField(prop) {
  console.log("###actions - clearFormField - pre dispatch###")
  console.log(prop)
  return (dispatch) => {
    dispatch({
      type: "CLEAR_FORM_FIELD",
      data: { prop },
    })
  }
}
export function socialModalClear() {
  console.log("###actions - socialModalClear - pre dispatch###")
  return (dispatch) => {
    dispatch({
      type: "SOCIAL_MODAL_USERDETAIL_CLEAR",
    })
  }
}

export function updateShareField(prop, val) {
  console.log("###actions - updateShareField - pre dispatch###")
  return (dispatch) => {
    dispatch({
      type: "SHARE_FIELD_UPDATE",
      data: { prop, val },
    })
  }
}

export function copyProfile(userdetail) {
  console.log("###actions - copyProfile - pre dispatch###")
  return (dispatch) => {
    let returnObj = { type: "PROFILE_EDIT_COPY" }
    if (userdetail) returnObj.data = userdetail
    dispatch(returnObj)
  }
}
export function copyProfileEmail(userdetailemail) {
  console.log("###actions - copyProfileEmail - pre dispatch###")
  return (dispatch) => {
    let returnObj = { type: "PROFILE_EDIT_EMAIL_COPY" }
    if (userdetailemail) returnObj.data = userdetailemail
    dispatch(returnObj)
  }
}

export function updateTemplateCollapse(bool) {
  console.log("###actions - updateTemplateCollapse - pre dispatch###")
  return (dispatch) => {
    dispatch({
      type: "TEMPLATETRAY_FIELD_UPDATE",
      data: bool,
    })
  }
}
