import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"

import { unauthorizedMessage } from "../utils/constants"

export function createUserFollow(mode, followid) {
  console.log("actions - createUserFollow - start")

  let cid = Cache.getItem("cid")

  console.log("actions - createUserFollow - cid")
  console.log(cid)

  let userFollowId = followid

  console.log("actions - createUserFollow - userFollowId")
  console.log(userFollowId)

  let apiName = "Storylavaweb"

  let path = "admin/userfollow/update"

  return (dispatch) => {
    dispatch({
      type: "LOADING_FOLLOW_START",
    })
    Auth.currentAuthenticatedUser()
      .then((user) => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log(
          "actions - createUserFollow - Auth.currentAuthenticatedUser - user"
        )
        console.log(user)
        let jwtToken = user.signInUserSession.accessToken.jwtToken

        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            cid,
          },
          body: {
            uid: user.username,
            followid: userFollowId,
            mode,
          },
        }

        console.log(
          "actions - createUserFollow - Auth.currentAuthenticatedUser - options"
        )
        console.log(options)

        //begin promise
        new Promise((resolve) => {
          API.post(apiName, path, options)
            .then((response) => {
              const data = response || {}

              let result = data

              console.log("actions - createUserFollow - API.post - result")
              console.log(result)

              // navigate("/app/sendgroup/")

              return resolve(
                dispatch({
                  type: "LOADING_FOLLOW_UPDATE",
                  data: result?.follow,
                })
              )
            })
            .catch((error) => {
              if (
                error &&
                error.response &&
                error.response.status &&
                (error.response.status === 401 || error.response.status === 403)
              ) {
                dispatch(logoutUser(unauthorizedMessage))
              }
              console.log("actions - createUserFollow - catch - error")
              console.log(error)
              console.log("actions - createUserFollow - catch - error.response")
              console.log(error.response)
            })
        }) //end promise
      })
      .catch((error) => {
        console.log(error)
      }) //end get Auth token
  }
}
