import React from "react"
import Konva, { Rect, Transformer, Path } from "react-konva"

const Rectangle = ({ shapeProps, isSelected, onSelect, onChange }) => {
  const shapeRef2 = React.useRef()
  const trRef = React.useRef()

  React.useEffect(() => {
    // if (isSelected) {
    // we need to attach transformer manually
    trRef.current.nodes([shapeRef2.current])
    trRef.current.getLayer().batchDraw()
    // }
  }, [isSelected])

  return (
    <React.Fragment>
      <Rect
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef2}
        {...shapeProps}
        // draggable
        // onDragEnd={(e) => {
        //   onChange({
        //     ...shapeProps,
        //     x: e.target.x(),
        //     y: e.target.y(),
        //   })
        // }}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef2.current
          const scaleX = node.scaleX()
          const scaleY = node.scaleY()

          // we will reset it back
          // node.scaleX(1)
          // node.scaleY(1)

          const transform = node.getAbsoluteTransform()
          const attrs = transform.decompose()
          console.log("rect - transform")
          console.log(transform)
          console.log("rect - attrs")
          console.log(attrs)

          // onChange({
          //   ...shapeProps,
          //   ...attrs,
          //   // x: node.x(),
          //   // y: node.y(),
          //   // set minimal value
          //   width: Math.max(5, node.width() * scaleX),
          //   height: Math.max(node.height() * scaleY),
          // })
        }}
      />
      {/* {isSelected && ( */}
      <Transformer
        ref={trRef}
        rotateEnabled={false}
        enabledAnchors={["middle-right", "middle-left"]}
        borderEnabled={true}
        borderStroke="#F8CE46"
        borderStrokeWidth={5}
        anchorStroke="white"
        anchorStrokeWidth={1}
        // boundBoxFunc={(oldBox, newBox) => {
        //   // limit resize
        //   if (newBox.width < 5 || newBox.height < 5) {
        //     return oldBox
        //   }
        //   return newBox
        // }}
      />

      {/* )} */}
    </React.Fragment>
  )
}

export default Rectangle
