import React, { useEffect, useState } from "react"

import { Auth } from "aws-amplify"

//Components
import ImagePreviewTray from "./imagePreviewTray"
import ImageLibraryFormUpload from "./imageLibraryFormUpload"
import Loading from "../loading"

//Icons
import { FaTimes, FaPlus } from "react-icons/fa"
import { BsFillPersonCheckFill, BsPersonPlus } from "react-icons/bs"

const renderTemplate = (props, template) => {
  const { loadNewTemplate } = props

  return (
    <div class="d-flex flex-column">
      <button
        class={`btn btn-slim`}
        // type="button"
        // class="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={(e) => {
          loadNewTemplate(template.id, template.context).then(() => {
            console.log("loadNewTemplate - THEN ***")
            // redrawStage()
          })
        }}
      >
        <img
          src={template.thumb}
          class={`border-1-gray-1 mt-1 mx-1`}
          height="150"
          // width="90"
        />
      </button>
      <div class="gray-4 template-editor-label-title">{template.title}</div>
    </div>
  )
}

const Snippet = (props) => {
  const {
    publisher,
    social,
    userRole,
    loadNewTemplate,
    isLoadingTemplateCreate,
    createUserFollow,
    userdetail,
  } = props

  console.log("userProfileModalForm - social")
  console.log(social)
  console.log("userProfileModalForm - userdetail")
  console.log(userdetail)

  const [userSub, setUserSub] = useState(null)

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      setUserSub(user.username)
    })
  }, [])

  const followStatus =
    userdetail &&
    userdetail.follow &&
    userdetail.follow.includes(social?.userdata?.id)
      ? true
      : false

  const followString = followStatus ? "unfollow" : "follow"
  return (
    <div class="reporting-container w-100 p-4">
      <div class={`d-flex flex-column w-100`}>
        {isLoadingTemplateCreate ? (
          <div class="w-100 p-4">
            <Loading class="small d-flex align-self-center" />
          </div>
        ) : null}
        <div class="profile-user-details d-flex flex-row align-items-center">
          <div class="profile-user-follow pl-1">
            {social?.userdata?.id === userdetail.id ? (
              <button class={`btn btn-outline-secondary`}>
                <BsFillPersonCheckFill size={24} />
              </button>
            ) : (
              <button
                class={`btn ${
                  followStatus ? "btn-primary" : "btn-outline-primary"
                }`}
                onClick={() => {
                  createUserFollow(followString, social?.userdata?.id)
                }}
              >
                {followStatus ? (
                  <div class="d-flex flex-row">
                    <BsFillPersonCheckFill size={24} />
                    <div class="follow-label">Following</div>
                  </div>
                ) : (
                  <div class="d-flex flex-row">
                    <BsPersonPlus size={24} />
                    <div class="follow-label">Follow</div>
                  </div>
                )}
              </button>
            )}
          </div>
          <div class="profile-user-container d-flex flex-grow-1 flex-row align-items-center">
            {/* <div class="profile-user-photo">
              <img class="rounded-circle" src={social?.userdata?.photo} />
            </div> */}
            <div class="profile-user-description">
              {social?.userdata?.description}
            </div>
          </div>
        </div>
        <div class="d-flex flex-row flex-wrap">
          {!isLoadingTemplateCreate && social && social.usertemplates
            ? social.usertemplates.map((template) =>
                renderTemplate(props, template)
              )
            : null}
        </div>
      </div>
    </div>
  )
}
export default Snippet
