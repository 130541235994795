//React
import React, { useRef, useState, useEffect, useCallback } from "react"
import { connect } from "react-redux"
import { Location, useLocation } from "@reach/router"
import { Auth } from "aws-amplify"

//Packages
import { v4 as uuidv4 } from "uuid"
import { customAlphabet } from "nanoid"
import update from "immutability-helper"

//Actions
import {
  updatePublisherField,
  loadPublisherTemplates,
  createTemplate,
  sendUploadCloud,
  setSocialModal,
  removeTemplate,
  finishVideoPreview,
  loadImageLibrary,
  updateActiveToolField,
} from "../../actions/createPublisher"
import { changeAttr } from "../../actions/modifyPublisher"
import { updateFormField, clearFormField } from "../../actions/updateForm"
import {
  sendVoice,
  setConversationState,
} from "../../actions/conversationManagement"

//Components
import EditorCanvas from "./editorCanvas"
import EditorTools from "./editorTools"
import EditorTemplates from "./editorTemplates"
import EditorSidebar from "./editorSidebar"
import PreviewVideo from "./previewVideo"
import PreviewTimeline from "./previewTimeline"
import MediaSharerModal from "./mediaSharerModal"
import TemplateSaveModal from "./templateSaveModal"
import TemplateEditModal from "./templateEditModal"
import ImageLibraryModal from "./imageLibraryModal"
import UserProfileModal from "./userProfileModal"
import EditorToolsIcons from "./tools/editorToolsIcons"
import SidebarTitle from "./sidebarTitle"
import NotificationSaveModal from "../appHome/notificationSaveModal"
import AlertModal from "./alertModal"

//Icons
import {
  FaChevronRight,
  FaInstagram,
  FaFacebookSquare,
  FaPlus,
} from "react-icons/fa"
import { FaCommentDots, FaRobot, FaShare } from "react-icons/fa"
import { MdFileDownload, MdPhonelinkRing, MdShowChart } from "react-icons/md"
import { BsLayoutTextSidebarReverse } from "react-icons/bs"

//Other

//Redux
const mapStateToProps = ({
  forms,
  publisher,
  isLoading,
  socialmodal,
  activeTool,
  conversationContext,
  userdetail,
  usersub,
}) => {
  return {
    forms,
    publisher,
    isLoading,
    socialmodal,
    activeTool,
    conversationContext,
    userdetail,
    usersub,
  }
}

const mapDispatchToProps = {
  updateActiveToolField,
  loadImageLibrary,
  finishVideoPreview,
  updatePublisherField,
  loadPublisherTemplates,
  createTemplate,
  setSocialModal,
  sendUploadCloud,
  updateFormField,
  clearFormField,
  sendVoice,
  setConversationState,
  changeAttr,
}

const Snippet = (props) => {
  const {
    forms,
    publisher,
    activeTool,
    socialmodal,
    updatePublisherField,
    loadPublisherTemplates,
    createTemplate,
    setSocialModal,
    sendUploadCloud,
    updateFormField,
    clearFormField,
    finishVideoPreview,
    loadImageLibrary,
    updateActiveToolField,
    userRole,
    userPlan,
    sendVoice,
    setConversationState,
    conversationContext,
    changeAttr,
    userdetail,
    usersub,
    context,
  } = props
  // console.log("state - publisher")
  // console.log(publisher)
  // console.log("publisherTemplateDetail - userRole")
  // console.log(userRole)
  console.log("publisherTemplateDetail - forms")
  console.log(forms)

  const stageRef = useRef(null)
  const stageWrapperRef = useRef(null)
  const toolsWrapperMobileRef = useRef(null)
  const toolsWrapperSidebarRef = useRef(null)

  const { href, pathname } = useLocation()
  const [userSub, setUserSub] = useState(null)
  const [webHostedImage, setWebHostedImage] = useState(null)
  const [directImageSrc, setDirectImageSrc] = useState(null)
  const [directImageRatio, setDirectImageRatio] = useState(1)
  const [directImagePreview, setDirectImagePreview] = useState(null)
  const [canvasBorderShow, setCanvasBorderShow] = useState(true)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [staticResetTextarea, setStaticResetTextarea] = useState(false)

  const [conversationListening, setConversationListening] = useState(false)

  const [windowSize, setWindowSize] = useState({
    width: typeof window !== undefined ? window.innerWidth : 0,
    height: typeof window !== undefined ? window.innerHeight : 0,
  })

  useEffect(() => {
    //like componentDidMount
    Auth.currentAuthenticatedUser().then((user) => {
      setUserSub(user.username)
    })
    // updatePublisherField("elements", [])
    // updatePublisherField("loadedScenes", [])
    // updatePublisherField("templateSelected", "")
    // updatePublisherField("sceneSelected", {})

    // if (props.context === "video") {
    //   updatePublisherField("mode", "video")
    // } else {
    //   updatePublisherField("mode", "image")
    // }

    loadPublisherTemplates()
    loadImageLibrary("user")
    loadImageLibrary("brand")
    const webpSupport = canUseWebP()
    console.log("webpSupport")
    console.log(webpSupport)
    updatePublisherField("webp", webpSupport)
  }, [])

  useEffect(() => {
    //like componentDidMount
    updatePublisherField("imagemode", "resize")
  }, [publisher.elementSelected])

  useEffect(() => {
    //like componentDidMount
    document.addEventListener("touchstart", handleClickOutsideStage, false)
    document.addEventListener("click", handleClickOutsideStage, false)
    return () => {
      document.removeEventListener("touchstart", handleClickOutsideStage, false)
      document.removeEventListener("click", handleClickOutsideStage, false)
    }
  }, [stageWrapperRef.current])

  useEffect(() => {
    if (typeof window !== undefined) {
      const checkSize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      }

      window.addEventListener("resize", checkSize)
      return () => window.removeEventListener("resize", checkSize)
    }
  }, [])

  const nanoid = customAlphabet(
    "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
    10
  )

  const recordAudio = useCallback(() => {
    // Older browsers might not implement mediaDevices at all, so we set an empty object first
    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {}
    }

    // Some browsers partially implement mediaDevices. We can't just assign an object
    // with getUserMedia as it would overwrite existing properties.
    // Here, we will just add the getUserMedia property if it's missing.
    if (navigator.mediaDevices.getUserMedia === undefined) {
      var getUserMedia =
        navigator.webkitGetUserMedia || navigator.mozGetUserMedia

      // navigator.mediaDevices.getUserMedia = function (constraints) {
      //   // First get ahold of the legacy getUserMedia, if present
      //   var getUserMedia =
      //     navigator.webkitGetUserMedia || navigator.mozGetUserMedia

      //   // Some browsers just don't implement it - return a rejected promise with an error
      //   // to keep a consistent interface
      //   if (!getUserMedia) {
      //     return Promise.reject(
      //       new Error("getUserMedia is not implemented in this browser")
      //     )
      //   }

      //   // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
      //   return new Promise(function (resolve, reject) {
      //     getUserMedia.call(navigator, constraints, resolve, reject)
      //   })
      // }
    }

    if (navigator.mediaDevices.getUserMedia) {
      console.log("**** GET USER MEDIA ****")
      var monochannel = null
      var leftchannel = []
      var rightchannel = []
      var recorder = null
      var recordingLength = 0
      var volume = null
      var mediaStream = null
      var sampleRate = 44100
      var audioContext = null
      var blob = null
      var soundEffect = null

      window.AudioContext = window.AudioContext || window.webkitAudioContext
      if (!audioContext) {
        audioContext = new AudioContext()
      }

      setConversationState(true)
      soundEffect = new Audio()
      // soundEffect.play()

      const playResponseSound = (soundEffect, source) => {
        console.log("playResponseSound -- START")
        console.log(source)
        soundEffect.src = source
        soundEffect.play()
      }

      navigator.mediaDevices
        // .getUserMedia({ audio: true })
        .getUserMedia({ audio: { channelCount: 1 } })
        .then(function (stream) {
          // start_microphone(stream)
          // creates the audio audioContext
          console.log("**** INSIDE navigator.mediaDevices.getUserMedia ****")
          playResponseSound(soundEffect, `/audio/tone-beep.wav`)

          // creates an audio node from the microphone incoming stream
          mediaStream = audioContext.createMediaStreamSource(stream)

          // https://developer.mozilla.org/en-US/docs/Web/API/AudioContext/createScriptProcessor
          // bufferSize: the onaudioprocess event is called when the buffer is full
          var bufferSize = 2048
          var numberOfInputChannels = 2
          var numberOfOutputChannels = 1
          if (audioContext.createScriptProcessor) {
            recorder = audioContext.createScriptProcessor(
              bufferSize,
              numberOfInputChannels,
              numberOfOutputChannels
            )
          } else {
            recorder = audioContext.createJavaScriptNode(
              bufferSize,
              numberOfInputChannels,
              numberOfOutputChannels
            )
          }

          recorder.onaudioprocess = function (stream) {
            // console.log("**onaudioprocess**")
            // console.log(stream)
            monochannel = stream.inputBuffer.getChannelData(0)
            // console.log("**onaudioprocess - monochannel**")
            // console.log(monochannel)
            leftchannel.push(
              new Float32Array(stream.inputBuffer.getChannelData(0))
            )
            rightchannel.push(
              new Float32Array(stream.inputBuffer.getChannelData(1))
            )
            recordingLength += bufferSize
          }

          // we connect the recorder
          mediaStream.connect(recorder)
          recorder.connect(audioContext.destination)
          // playResponseSound(soundEffect, `/audio/tone-beep.wav`)

          detectSilence(
            stream,
            onSilence,
            onSpeak,
            playResponseSound,
            audioContext
          )
        })
        .catch(function (err) {
          console.log(err.name + ": " + err.message)
        })
    } else {
      console.log("getUserMedia not supported in this browser.")
    }

    //See https://stackoverflow.com/a/46781986
    function detectSilence(
      stream,
      onSoundEnd = (_) => {},
      onSoundStart = (_) => {},
      playResponseSound,
      audioContext,
      silence_delay = 250,
      min_decibels = -80
    ) {
      // const ctx = audioContext
      const analyser = audioContext.createAnalyser()
      mediaStream.connect(analyser)
      analyser.minDecibels = min_decibels

      const data = new Uint8Array(analyser.frequencyBinCount) // will hold our data
      let silence_start = performance.now()
      let triggered = false // trigger only once per silence event
      // let i = 0
      let elapsed_time_start = performance.now()
      let elapsed_time

      function loop(time) {
        console.log("time")
        console.log(time)
        // i++
        elapsed_time = performance.now() - elapsed_time_start
        // console.log("i")
        // console.log(i)
        // console.log("elapsed_time")
        // console.log(elapsed_time)
        if (triggered) {
          cancelAnimationFrame(triggered)
          triggered = false
          return
        }
        requestAnimationFrame(loop) // we'll loop every 60th of a second to check
        analyser.getByteFrequencyData(data) // get current data
        if (data.some((v) => v)) {
          // if there is data above the given db limit
          onSoundStart()

          // if (triggered) {
          //   triggered = false
          // }
          silence_start = time // set it to now
        }
        if (
          !triggered &&
          elapsed_time > 2000 &&
          time - silence_start > silence_delay
        ) {
          onSoundEnd(stream, audioContext, mediaStream, playResponseSound)
          triggered = true
        }
      }
      loop()
    }

    function onSilence(stream, audioContext, streamNode, playResponseSound) {
      // console.log("silence")
      // console.log("stream")
      // console.log(stream)
      // console.log("audioContext")
      // console.log(audioContext)
      // console.log("streamNode")
      // console.log(streamNode)
      stream.getTracks().forEach(function (track) {
        console.log("stopping")
        track.stop()
      })

      // stop recording
      recorder.disconnect(audioContext.destination)
      mediaStream.disconnect(recorder)

      // var mergedBuffers = flattenArray(leftchannel, recordingLength)

      var mergedBuffers = mergeBuffers(leftchannel, recordingLength)
      var downsampledBuffer = downsampleBuffer(
        mergedBuffers,
        16000,
        audioContext.sampleRate
      )
      var encodedWav = encodeWAV(downsampledBuffer, 16000)
      // var encodedWav = encodeWAV(mergedBuffers, audioContext.sampleRate)
      var audioBlob = new Blob([encodedWav], {
        type: "audio/wav",
      })

      // playResponseSound(soundEffect, `/audio/tone-beep.wav`)

      var reader = new window.FileReader()
      reader.readAsDataURL(audioBlob)

      reader.onloadend = () => {
        let base64 = reader.result
        // base64 = base64.split(",")[1]
        base64 = base64.replace("data:audio/wav;base64,", "")
        console.log(base64)
        console.log("conversationContext")
        console.log(conversationContext)
        sendVoice(conversationContext, base64, 16000).then((result) => {
          console.log("****sendVoice - result")
          console.log(result)
          // playResponseSound(soundEffect, `/audio/tone-beep.wav`)

          const responseAudio = `data:audio/wav;base64,${result}`
          playResponseSound(soundEffect, responseAudio)
          audioContext.close().catch(() => {
            console.log("Closing AudioContext failed")
          })
        })
        // sendVoice(conversationContext, base64, audioContext.sampleRate)
        // setConversationListening(false)
      }
    } //end onSilence
    function onSpeak() {
      console.log("speaking")
    }

    function downsampleBuffer(buffer, exportSampleRate, recordSampleRate) {
      if (exportSampleRate === recordSampleRate) {
        return buffer
      }
      var sampleRateRatio = recordSampleRate / exportSampleRate
      var newLength = Math.round(buffer.length / sampleRateRatio)
      var result = new Float32Array(newLength)
      var offsetResult = 0
      var offsetBuffer = 0
      while (offsetResult < result.length) {
        var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio)
        var accum = 0,
          count = 0
        for (
          var i = offsetBuffer;
          i < nextOffsetBuffer && i < buffer.length;
          i++
        ) {
          accum += buffer[i]
          count++
        }
        result[offsetResult] = accum / count
        offsetResult++
        offsetBuffer = nextOffsetBuffer
      }
      return result
    }

    function mergeBuffers(bufferArray, recLength) {
      // console.log("**mergeBuffers - bufferArray")
      // console.log(bufferArray)
      // console.log("**mergeBuffers - recLength")
      // console.log(recLength)
      var result = new Float32Array(recLength)
      // console.log("**mergeBuffers - result")
      // console.log(result)
      var offset = 0
      for (var i = 0; i < bufferArray.length; i++) {
        result.set(bufferArray[i], offset)
        offset += bufferArray[i].length
      }
      return result
    }

    function floatTo16BitPCM(output, offset, input) {
      for (var i = 0; i < input.length; i++, offset += 2) {
        var s = Math.max(-1, Math.min(1, input[i]))
        output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true)
      }
    }

    function writeString(view, offset, string) {
      for (var i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i))
      }
    }

    function encodeWAV(samples, recordSampleRate) {
      var buffer = new ArrayBuffer(44 + samples.length * 2)
      var view = new DataView(buffer)

      writeString(view, 0, "RIFF")
      view.setUint32(4, 32 + samples.length * 2, true)
      // view.setUint32(4, 44 + samples.length * 2, true)
      writeString(view, 8, "WAVE")
      writeString(view, 12, "fmt ")
      view.setUint32(16, 16, true)
      view.setUint16(20, 1, true)
      view.setUint16(22, 1, true)
      view.setUint32(24, recordSampleRate, true)
      view.setUint32(28, recordSampleRate * 2, true)
      view.setUint16(32, 2, true)
      view.setUint16(34, 16, true)
      writeString(view, 36, "data")
      view.setUint32(40, samples.length * 2, true)
      floatTo16BitPCM(view, 44, samples)

      return view
    }
    //end recordAudio
  }, [])

  //See: https://medium.com/@JackPu/how-to-detect-browser-support-webp-446b8f91504
  const canUseWebP = () => {
    var elem = document.createElement("canvas")
    if (!!(elem.getContext && elem.getContext("2d"))) {
      // was able or not to get WebP representation
      return elem.toDataURL("image/webp").indexOf("data:image/webp") == 0
    }
    // very old browser like IE 8, canvas not supported
    return false
  }

  const clearCanvas = () => {
    updatePublisherField("templateSelected", "")
    updatePublisherField("elements", [])
    updatePublisherField("elementSelected", {})
  }
  const handleClickOutsideStage = (event) => {
    console.log("handleClickOutsideStage - event")
    console.log(event)
    console.log("handleClickOutsideStage - publisher")
    console.log(publisher)

    const composedPath = event.composedPath()
    console.log("composedPath")
    console.log(composedPath)

    if (
      event &&
      composedPath &&
      composedPath.every(
        (element) =>
          element.id !== "stageWrapper" &&
          element.id !== "toolsWrapperMobile" &&
          element.id !== "toolsWrapperSidebar" &&
          element.id !== "layer-visibility" &&
          element.id !== "layer-select" &&
          element.id !== "imageLibraryModal"
      ) //need to test if this is coming from the tools palette or the layer hide icon, the layer select square, somewhere on the imageLibraryModal
    ) {
      console.log("||||||| handleClickOutsideStage - DESELECTING |||||||")
      deselectStage()
    }
  }

  const deselectStage = useCallback(() => {
    return new Promise((resolve, reject) => {
      updatePublisherField("elementSelected", {}) //deselect elements
      updateActiveToolField("canvas")
      if (
        publisher &&
        publisher.elementSelected &&
        publisher.elementSelected.shape &&
        publisher.elementSelected.shape === "text"
      ) {
        setStaticResetTextarea(true)
      }

      resolve()
    })
  }, [])

  const getViewport = useCallback(() => {
    // https://stackoverflow.com/a/8876069
    const width = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    )
    if (width < 576) return "xs"
    if (width < 768) return "sm"
    if (width < 992) return "md"
    if (width < 1200) return "lg"
    return "xl"
  }, [])

  //STAGE DIMENSIONS & SCALE
  let stageWidth = 700 //add 2 to show right border correctly
  let stageHeight = 700
  let stageExport = {}

  console.log("======== getViewport ==== publisher.stage======")
  console.log(publisher.stage)

  if (props.context === "video") {
    // stageWidth =  1080 / 4
    // stageHeight = 1920 / 4
    stageWidth = 400
    stageHeight = 400
  }
  // else if (publisher.stage.width && publisher.stage.height) {
  //   stageWidth = publisher.stage.width
  //   stageHeight = publisher.stage.height
  // }

  if (publisher.stage.export) {
    stageExport = publisher.stage.export
    // console.log("publisherTemplateDetail -:: ::- stageExport")
    // console.log(stageExport)
    // console.log("((publisher.stage.export.x))")
    // console.log(publisher.stage.export.x)
    // console.log("((publisher.stage.export.y))")
    // console.log(publisher.stage.export.y)
  }
  const viewportSize = getViewport()
  let stageScale = 1
  let containerWidth = stageWidth
  let containerHeight = stageHeight
  let containerExport = stageExport
  if (
    typeof window !== `undefined` &&
    (viewportSize === "xs" || viewportSize === "sm")
  ) {
    // stageWidth = stageWidth * 0.7
    // stageHeight = stageHeight * 0.7
    //See https://stackoverflow.com/questions/49943763/responsive-canvas-with-react-and-konva
    stageScale = Math.min(
      windowSize.width / stageWidth,
      windowSize.height / stageHeight
    )
    stageScale = stageScale >= 1 ? 1 : stageScale
    stageScale = stageScale - 0.04

    // containerExport.x = containerExport.x * 1.04 //some reason need this and the line below to make sizes work
    // containerExport.y = containerExport.y * 1.04
  } else if (typeof window !== `undefined` && viewportSize === "md") {
    stageScale = 0.84
    stageScale = stageScale - 0.04
  } else if (typeof window !== `undefined` && viewportSize === "lg") {
    stageScale = 0.8
    stageScale = stageScale - 0.04
  }

  containerWidth = containerWidth * stageScale
  containerHeight = containerHeight * stageScale
  // containerExport.x = containerExport.x * stageScale
  // containerExport.y = containerExport.y * stageScale

  console.log("[[[viewportSize]]]")
  console.log(viewportSize)
  console.log("[[[[stageScale]]]]")
  console.log(stageScale)

  // console.log("stageExport.x")
  // console.log(stageExport.x)
  // console.log("stageExport.y")
  // console.log(stageExport.y)

  const stageDimensions = {
    stageScale,
    stageWidth,
    stageHeight,
    stageExport,
    containerExport,
    containerWidth,
    containerHeight,
  }
  //END STAGE DIMENSIONS & SCALE

  const dataURItoBlob = (dataURI) => {
    var binary = atob(dataURI.split(",")[1])
    var array = []
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i))
    }
    return new Blob([new Uint8Array(array)], { type: "image/png" })
  }
  const saveImage = useCallback(() => {
    deselectStage().then(() => {
      const dataURL = stageRef.current.toDataURL({
        mimeType: "image/png",
        quality: 1.0,
        // pixelRatio: 2,
        pixelRatio:
          publisher && publisher.stage && publisher.stage.pixelRatio
            ? publisher.stage.pixelRatio
            : 1,
        width:
          publisher &&
          publisher.stage &&
          publisher.stage.export &&
          publisher.stage.export.w
            ? publisher.stage.export.w
            : 500,
        height:
          publisher &&
          publisher.stage &&
          publisher.stage.export &&
          publisher.stage.export.h
            ? publisher.stage.export.h
            : 500,
        x:
          publisher &&
          publisher.stage &&
          publisher.stage.export &&
          publisher.stage.export.x
            ? publisher.stage.export.x
            : 50,
        y:
          publisher &&
          publisher.stage &&
          publisher.stage.export &&
          publisher.stage.export.y
            ? publisher.stage.export.y
            : 50,
      })
      downloadURI(dataURL, "image.png")
    })
  }, [])

  const saveImageCloud = useCallback(() => {
    console.log("(((((((saveImageCloud - stageDimensions)))))))")
    console.log(stageDimensions)
    const filenameId = uuidv4()
    setCanvasBorderShow(false)
    deselectStage().then(() => {
      setSocialModal(filenameId)
      const webHostedImageLocal = `https://${process.env.HOSTNAME_SLVA_MEDIA}/media/${userSub}/${filenameId}.jpg`
      const directImageSrcLocal = `https://${process.env.S3BUCKETMEDIA}.s3.amazonaws.com/public/${userSub}/${filenameId}.jpg`

      setWebHostedImage(webHostedImageLocal)
      setDirectImageSrc(directImageSrcLocal)

      if (publisher.mode === "image") {
        let pixelRatio =
          publisher && publisher.stage && publisher.stage.pixelRatio
            ? publisher.stage.pixelRatio
            : 1

        pixelRatio = pixelRatio * 3
        let stageWidth = stageRef.current.width()
        let stageHeight = stageRef.current.height()

        console.log("saveImageCloud || pixelRatio")
        console.log(pixelRatio)
        console.log("saveImageCloud || stageWidth")
        console.log(stageWidth)
        console.log("saveImageCloud || stageHeight")
        console.log(stageHeight)
        setDirectImageRatio(stageWidth / stageHeight)
        // // if (viewportSize === "xs") {
        // // pixelRatio = pixelRatio / stageDimensions.stageScale
        // stageWidth = stageWidth / stageDimensions.stageWidth
        // stageHeight = stageHeight / stageDimensions.stageHeight
        // // }

        const oldScale = stageRef.current.scale()
        // change size into required size
        // stageRef.current.size({
        //   width: stageDimensions.stageWidth,
        //   height: stageDimensions.stageHeight,
        // })
        stageRef.current.scale({ x: 1, y: 1 })

        console.log("(((((saveImageCloud - stageWidth)))))")
        console.log(stageWidth)
        console.log("(((((saveImageCloud - stageHeight)))))")
        console.log(stageHeight)
        console.log("(((((saveImageCloud - oldScale)))))")
        console.log(oldScale)

        console.log("((((((saveImageCloud - stageRef.current))))))")
        console.log(stageRef.current)

        //Dont need to use any scale factor in exporting because we have already set the stage scale as 1 above
        const dataURL = stageRef.current.toDataURL({
          mimeType: "image/jpeg",
          quality: 1.0,
          // pixelRatio: 2,
          pixelRatio,
          width:
            stageDimensions.stageExport.scaleW -
            stageDimensions.stageExport.x * 2,
          height:
            stageDimensions.stageExport.scaleH -
            stageDimensions.stageExport.y * 2,
          x: stageDimensions.stageExport.x,
          y: stageDimensions.stageExport.y,
        })
        setDirectImagePreview(dataURL)
        // restore size
        // const dataURLSmall = stageRef.current.toDataURL({
        //   mimeType: "image/jpeg",
        //   quality: 1,
        //   // pixelRatio: 2,
        //   pixelRatio: pixelRatio * 0.5,
        // })
        // restore size
        stageRef.current.scale(oldScale)

        const blobData = dataURItoBlob(dataURL)
        // const blobDataSmall = dataURItoBlob(dataURLSmall)

        //These options were moved to the actual sendUploadCloud action
        // const options = {
        //   level: "public",
        //   acl: "public-read",
        //   contentType: "image/jpeg",
        //   contentDisposition: "attachment",
        //   // ContentEncoding: "base64",
        // }
        const options = {}
        console.log("**pre uploadCloud")
        console.log("saveImageCloud - stageRef.current")
        console.log(stageRef.current)
        const attrs = {
          width: stageWidth,
          height: stageHeight,
        }
        sendUploadCloud(
          filenameId,
          blobData,
          `${filenameId}.jpg`,
          options,
          attrs,
          webHostedImageLocal,
          "orig"
        )
        setCanvasBorderShow(true)
        // sendUploadCloud(
        //   `${filenameId}-sm`,
        //   blobDataSmall,
        //   `${filenameId}-sm.jpg`,
        //   options,
        //   attrs,
        //   webHostedImageLocal,
        //   "preview"
        // )
      } else {
        finishVideoPreview()
      }
    })
  }, [publisher])

  const clearForm = useCallback((prop) => {
    clearFormField(prop)
  }, [])

  const editTemplate = useCallback(
    (
      templateid,
      thumb,
      title,
      tags,
      division,
      elements,
      elementsmode,
      fname,
      lname,
      stage
    ) => {
      console.log("**templateid")
      console.log(templateid)
      console.log("**editTemplate - tags")
      console.log(tags)
      updateFormField("edittemplate", "thumb", thumb)
      updateFormField("edittemplate", "templateid", templateid)
      updateFormField("edittemplate", "title", title)
      updateFormField("edittemplate", "tags", tags)
      updateFormField("edittemplate", "stage", stage)

      // let divisionVal = division === "global" ? 1 : 0
      let divisionVal = division
      updateFormField("edittemplate", "globaltemplate", divisionVal)

      let elementsModeVal = elementsmode === "elements" ? 1 : 0
      updateFormField("edittemplate", "elementsmode", elementsModeVal)

      if (elements) {
        updateFormField("edittemplate", "elements", elements)
      }
      if (fname) {
        updateFormField("edittemplate", "fname", fname)
      }
      if (lname) {
        updateFormField("edittemplate", "lname", lname)
      }

      deselectStage().then(() => {
        console.log("publisher.elements")
        console.log(publisher.elements)
        const oldScale = stageRef.current.scale()
        stageRef.current.scale({ x: 1, y: 1 })

        const dataURL = stageRef.current.toDataURL({
          mimeType: "image/png",
          quality: 1.0,
          // pixelRatio: 0.5,
          width:
            stageDimensions.stageExport.scaleW -
            // 2 -
            stageDimensions.stageExport.x * stageDimensions.stageScale * 2,
          height:
            stageDimensions.stageExport.scaleH -
            // 2 -
            stageDimensions.stageExport.y * stageDimensions.stageScale * 2,
          x: stageDimensions.stageExport.x * stageDimensions.stageScale,
          y: stageDimensions.stageExport.y * stageDimensions.stageScale,
        })
        updateFormField("edittemplate", "thumbelements", dataURL)
        stageRef.current.scale(oldScale)
        console.log("**saveedittemplateTemplate - end")
      })
    },
    [publisher]
  )

  const saveTemplate = useCallback(() => {
    clearForm("savetemplate")
    clearForm("edittemplate")
    clearForm("workingtags")
    deselectStage().then(() => {
      console.log("publisher.elements")
      console.log(publisher.elements)
      const oldScale = stageRef.current.scale()
      stageRef.current.scale({ x: 1, y: 1 })
      const dataURL = stageRef.current.toDataURL({
        mimeType: "image/png",
        quality: 1.0,
        // pixelRatio: 1,
        width:
          stageDimensions.stageExport.scaleW -
          // 2 -
          stageDimensions.stageExport.x * stageDimensions.stageScale * 2,
        height:
          stageDimensions.stageExport.scaleH -
          // 2 -
          stageDimensions.stageExport.y * stageDimensions.stageScale * 2,
        x: stageDimensions.stageExport.x * stageDimensions.stageScale,
        y: stageDimensions.stageExport.y * stageDimensions.stageScale,
      })
      updateFormField("savetemplate", "thumb", dataURL)
      updateFormField("savetemplate", "id", uuidv4())
      console.log("**saveTemplate - end")

      let currentSelectedTemplate = publisher?.templates.find(
        (template) => template.id === publisher?.templateSelected
      )

      const templateAccess =
        currentSelectedTemplate && currentSelectedTemplate.division
          ? currentSelectedTemplate.division
          : "public"
      const templateTags =
        currentSelectedTemplate && currentSelectedTemplate.tags
          ? currentSelectedTemplate.tags
          : []
      const templateStage = publisher && publisher.stage ? publisher.stage : {}

      console.log("**saveTemplate - templateTags")
      console.log(templateTags)

      updateFormField("savetemplate", "globaltemplate", templateAccess)
      updateFormField("savetemplate", "tags", templateTags)
      updateFormField("savetemplate", "stage", templateStage)

      if (currentSelectedTemplate && usersub === currentSelectedTemplate?.uid) {
        console.log("**saveTemplate - currentSelectedTemplate - edit")

        updateFormField(
          "savetemplate",
          "title",
          `${currentSelectedTemplate.title} (copy)`
        )

        editTemplate(
          currentSelectedTemplate.id,
          currentSelectedTemplate.thumb,
          currentSelectedTemplate.title,
          currentSelectedTemplate.tags,
          templateAccess,
          currentSelectedTemplate.elements,
          currentSelectedTemplate.elementsmode,
          currentSelectedTemplate.fname,
          currentSelectedTemplate.lname,
          templateStage
        )
      }
      stageRef.current.scale(oldScale)
    })
  }, [publisher])

  const saveTemplateComplete = useCallback(() => {
    console.log("@@@@ saveTemplateComplete - publisher")
    console.log(publisher)
    console.log("@@@@ saveTemplateComplete - forms")
    console.log(forms)

    console.log("@@@@ saveTemplateComplete - pathname")
    console.log(pathname)
    let contextValue
    if (pathname === "/app/publisher/") {
      contextValue = "image"
    } else if (pathname === "/app/video/") {
      contextValue = "video"
    }
    let divisionVal = forms.savetemplate.globaltemplate
      ? forms.savetemplate.globaltemplate
      : "none"

    console.log("@@@@ saveTemplateComplete - contextValue")
    console.log(contextValue)

    let elementsModeVal = forms.savetemplate.elementsmode
      ? "elements"
      : "template"
    createTemplate(
      [{ sceneid: "scene1", data: publisher.elements }],
      publisher?.stage,
      forms.savetemplate.thumb,
      forms.savetemplate.title,
      forms.savetemplate.tags ? forms.savetemplate.tags : [],
      divisionVal,
      contextValue,
      elementsModeVal,
      userdetail
    ).then(() => {
      console.log("****** createTemplate done")
      loadPublisherTemplates()
      $("#pills-new-tab").tab("show") // Select tab by name
    })
  }, [publisher, forms, userdetail])

  const downloadURI = useCallback((uri, name) => {
    // function from https://stackoverflow.com/a/15832662/512042

    var link = document.createElement("a")
    link.download = name
    link.href = uri
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    // delete link
  }, [])

  const addNewElement = useCallback(
    (shape, clone) => {
      //Copy target scene's elements into working publisher.elements item
      console.log("addNewElement - publisher")
      console.log(publisher)
      let newElements = [...publisher.elements]
      let newShape = {}
      let newShapes = []

      if (clone) {
        newShape = clone
      } else if (shape === "line") {
        console.log("****ADDING LINE")
        newShape = {
          id: `shape-${nanoid()}`,
          shape: "line",
          // x: 10,
          // y: 10,
          // width: 200,
          // height: 1,
          points: [10, 10, 210, 10],
          stroke: "#000000",
          strokeWidth: 20,
          tension: 0,
          // closed: true,
          x: 0,
          y: 0,
        }
      } else if (shape === "text") {
        newShape = {
          id: `shape-${nanoid()}`,
          shape: "text",
          align: "left",
          content: "Text",
          fill: "#000000",
          // fontFamily: "Open Sans",
          fontSize: 36,
          height: 36,
          width: 120,
          x: 60,
          y: 60,
        }
      } else if (shape === "rect") {
        newShape = {
          id: `shape-${nanoid()}`,
          shape: "rect",
          fill: "#cccccc",
          height: 40,
          width: 100,
          x: 60,
          y: 60,
          cornerRadius: [0, 0, 0, 0],
        }
      } else if (shape === "circle") {
        newShape = {
          id: `shape-${nanoid()}`,
          shape: "circle",
          fill: "#cccccc",
          height: 40,
          width: 40,
          x: 90,
          y: 90,
          radius: 40,
          // stroke: "black",
          // strokeWidth: 4,
        }
      } else if (shape === "image") {
        newShape = {
          id: `shape-${nanoid()}`,
          shape: "image",
          height: 200,
          src:
            "https://storylavaprime.s3.amazonaws.com/static/publisher/icons/addimage.png",
          width: 200,
          x: 30,
          y: 30,
        }
      } else {
        console.log("--publisherTemplateDetail - else")
        console.log("newShapes")
        console.log(newShapes)
        console.log("shape")
        console.log(shape)
        newShapes = [...newShapes, ...shape]
      }

      if (clone) {
        newElements.unshift(newShape)
      } else {
        if (newShape.hasOwnProperty("id")) {
          newElements.push(newShape) //push individual shape
        }
        newElements = [...newElements, ...newShapes] //merge with multiple shapes
      }

      console.log("--publisherTemplateDetail - newElements")
      console.log(newElements)
      // updatePublisherField("sceneSelected", {
      //   sceneid: targetSceneId,
      // })

      updatePublisherField("elements", newElements)
    },
    [publisher]
  )

  const updateTemplateElements = (sceneid) => {
    //Get current template and scene ids
    const currentTemplateId = publisher.templateSelected
    const currentSceneId = publisher.sceneSelected.sceneid
      ? publisher.sceneSelected.sceneid
      : "scene1"
    const targetSceneId = sceneid

    //Get index for active template
    const activeTemplateIndex = publisher.templates.findIndex(
      (template) => template.id === currentTemplateId
    )

    //Get index for active elements group in template
    const activeElementsGroupIndex = [...publisher.templates][
      activeTemplateIndex
    ].elements.findIndex(
      (elementGroup) => elementGroup.sceneid === currentSceneId
    )
    //Get index for target elements group in template

    const targetElementsGroupIndex = [...publisher.templates][
      activeTemplateIndex
    ].elements.findIndex(
      (elementGroup) => elementGroup.sceneid === targetSceneId
    )

    //Make a copy of the templates
    let newTemplates = [...publisher.templates]

    //Update active template with current active elements
    newTemplates[activeTemplateIndex].elements[activeElementsGroupIndex] = {
      ...newTemplates[activeTemplateIndex].elements[activeElementsGroupIndex], //copy all current attributes
      data: publisher.elements, //replace active elements
    }

    //Copy target scene's elements into working publisher.elements item
    let newElements = [...publisher.templates][activeTemplateIndex].elements[
      targetElementsGroupIndex
    ].data

    updatePublisherField("sceneSelected", {
      sceneid: targetSceneId,
    })
    updatePublisherField("elements", newElements)
    updatePublisherField("templates", newTemplates)
  }

  const reorderLayers = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = publisher.elements[dragIndex]

      const newElements = update(publisher.elements, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      })
      console.log("reorderLayers----publisher.elements")
      console.log(publisher.elements)
      console.log("reorderLayers----newElements")
      console.log(newElements)
      updatePublisherField("elements", newElements)
    },
    [publisher]
  )

  const removeSelectedElement = useCallback(() => {
    console.log("removeSelectedElement - START")
    const newElements = publisher.elements.slice()
    console.log("newElements")
    console.log(newElements)
    const selectedElementId = publisher.elementSelected.id
    const deleteIndex = publisher.elements.findIndex(
      (element) => element.id === selectedElementId
    )

    newElements.splice(deleteIndex, 1)

    updatePublisherField("elements", newElements)
    updatePublisherField("elementSelected", {})
  }, [publisher])

  return (
    <>
      <div class="d-flex d-lg-none flex-row mx-1">
        <button
          class="d-flex flex-row align-items-center justify-content-center btn btn-outline-dark btn-drawer-toggle"
          onClick={() => {
            setDrawerOpen(true)
          }}
        >
          <BsLayoutTextSidebarReverse size={20} />
        </button>
        <button
          class="btn btn-link btn-drawer-label d-flex align-items-start justify-content-center px-2"
          onClick={() => {
            setDrawerOpen(true)
          }}
        >
          Social Design &amp; Templates
        </button>
      </div>
      <div class="d-flex flex-row">
        <div class="col-lg-3 d-none d-lg-block">
          <EditorSidebar
            context={context}
            userRole={userRole}
            editTemplate={editTemplate}
            addNewElement={addNewElement}
            setDrawerOpen={setDrawerOpen}
            getViewport={getViewport}
            uniqueIdentifier="0"
          />
        </div>
        <div class={`side-drawer ${drawerOpen ? "open" : ""}`}>
          <EditorSidebar
            context={context}
            userRole={userRole}
            editTemplate={editTemplate}
            addNewElement={addNewElement}
            setDrawerOpen={setDrawerOpen}
            getViewport={getViewport}
            uniqueIdentifier="1"
          />
        </div>
        <div class="col-md-9">
          <div class="reporting-container">
            {props.context === "video" ? (
              <PreviewTimeline
                publisher={publisher}
                updateTemplateElements={updateTemplateElements}
              />
            ) : null}
            <div
              id="toolsWrapperMobile"
              class="d-flex flex-row d-md-none m-1 mt-2"
              ref={toolsWrapperMobileRef}
            >
              <div class="col-3 d-flex flex-row flex-wrap align-items-center justify-content-center bg-black-3 rounded-corners row-tools-master">
                <EditorToolsIcons
                  activeTool={activeTool}
                  publisher={publisher}
                  updateActiveToolField={updateActiveToolField}
                  deselectStage={deselectStage}
                />
              </div>
              <div class="col-9 d-flex align-items-center justify-content-center p-0">
                {viewportSize === "xs" || viewportSize === "sm" ? (
                  <EditorTools
                    editorContext="top"
                    saveImage={saveImage}
                    saveImageCloud={saveImageCloud}
                    saveTemplate={saveTemplate}
                    changeAttr={changeAttr}
                    downloadURI={downloadURI}
                    // publisher={publisher}
                    addNewElement={addNewElement}
                    reorderLayers={reorderLayers}
                    removeSelectedElement={removeSelectedElement}
                    deselectStage={deselectStage}
                    recordAudio={recordAudio}
                    conversationListening={conversationListening}
                  />
                ) : null}
              </div>
            </div>
            <div class="d-flex flex-row">
              <div class="mt-2">
                {props.context === "video" ? (
                  <PreviewVideo stageRef={stageRef} changeAttr={changeAttr} />
                ) : (
                  <div id="stageWrapper" ref={stageWrapperRef}>
                    <EditorCanvas
                      stageRef={stageRef}
                      canvasBorderShow={canvasBorderShow}
                      changeAttr={changeAttr}
                      addNewElement={addNewElement}
                      updateActiveToolField={updateActiveToolField}
                      stageDimensions={stageDimensions}
                      removeSelectedElement={removeSelectedElement}
                      staticResetTextarea={staticResetTextarea}
                      setStaticResetTextarea={setStaticResetTextarea}
                    />
                  </div>
                )}
              </div>
              <div class="canvas-tools-container canvas-tools-container-desktop ml-auto pl-2 d-none d-md-block">
                {viewportSize === "md" ||
                viewportSize === "lg" ||
                viewportSize === "xl" ? (
                  <div id="toolsWrapperSidebar" ref={toolsWrapperSidebarRef}>
                    <EditorTools
                      editorContext="side"
                      saveImage={saveImage}
                      saveImageCloud={saveImageCloud}
                      saveTemplate={saveTemplate}
                      changeAttr={changeAttr}
                      downloadURI={downloadURI}
                      // publisher={publisher}
                      addNewElement={addNewElement}
                      reorderLayers={reorderLayers}
                      removeSelectedElement={removeSelectedElement}
                      deselectStage={deselectStage}
                      recordAudio={recordAudio}
                      conversationListening={conversationListening}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <MediaSharerModal
              webHostedImage={webHostedImage}
              directImageSrc={directImageSrc}
              directImageRatio={directImageRatio}
              directImagePreview={directImagePreview}
              viewportSize={viewportSize}
              userPlan={userPlan}
            />
            <TemplateSaveModal
              saveTemplateComplete={saveTemplateComplete}
              clearForm={clearForm}
              userRole={userRole}
              userPlan={userPlan}
              editTemplate={editTemplate}
            />
            <TemplateEditModal
              saveTemplateComplete={saveTemplateComplete}
              clearForm={clearForm}
              userRole={userRole}
              userPlan={userPlan}
            />
            <ImageLibraryModal
              changeAttr={changeAttr}
              loadImageLibrary={loadImageLibrary}
              userRole={userRole}
            />
            <UserProfileModal userRole={userRole} />
            {drawerOpen ? <div className="backdrop-drawer" /> : null}
            <NotificationSaveModal context="save" />
            <NotificationSaveModal context="delete" />
            <AlertModal context="newcanvas" clearCanvas={clearCanvas} />
          </div>
        </div>
      </div>
    </>
  )
}
// class Snippet extends React.Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       scrolled: "",
//     }
//   }
//   componentDidMount() {}

//   render() {

//   }
// }

// export default Snippet
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
