import React, { useEffect, useState } from "react"

import { Auth } from "aws-amplify"

//Components
import Loading from "../loading"

//Icons

const Snippet = (props) => {
  const {
    forms,
    saveTemplate,
    createNotify,
    updateFormField,
    isLoadingNotifyCreate,
    company,
  } = props

  const [userSub, setUserSub] = useState(null)
  const [imageThumb, setImageThumb] = useState(null)
  const [templateContext, setTemplateContext] = useState(null)

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      setUserSub(user.username)
    })
  }, [])
  useEffect(() => {
    if (
      props.publisher &&
      props.publisher.templates &&
      props.publisher.templates.find(
        (template) => template.id === forms.notify.templateid
      )
    ) {
      setImageThumb(
        props.publisher.templates.find(
          (template) => template.id === forms.notify.templateid
        ).thumb
      )
      setTemplateContext(
        props.publisher.templates.find(
          (template) => template.id === forms.notify.templateid
        ).context
      )
    }
  })

  // console.log("****userSub")
  // console.log(userSub)
  // console.log("****props.publisher")
  // console.log(props.publisher)

  return (
    <div class="reporting-container w-100 p-4">
      <div class="form-group row d-flex flex-row align-items-center justify-content-start">
        <label
          for="notification-content"
          class="col-sm-2 col-form-label font-weight-light line-height-1"
        >
          Post content
        </label>
        <div class="col-sm-10">
          <textarea
            class="form-control"
            id="notification-content"
            rows="3"
            placeholder="What would you like to say?"
            value={
              forms.notify && forms.notify.content ? forms.notify.content : ""
            }
            onChange={(e) =>
              updateFormField("notify", "content", e.target.value)
            }
          ></textarea>
        </div>
      </div>
      <div class="form-group row d-flex flex-row align-items-center justify-content-start">
        <label
          for="templateid"
          class="col-sm-2 col-form-label font-weight-light line-height-1"
        >
          Template link
        </label>
        <div class="col-sm-10">
          <div class="d-flex flex-row align-items-center justify-content-start">
            {/* <button
              class="btn btn-sm btn-primary"
              style={{
                borderRadius: "0.25rem",
                marginTop: 0,
                marginBottom: 0,
                marginLeft: 2,
                marginRight: 2,
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
                width: 24,
                height: 24,
              }}
            >
              <FaPlus class="white-1" style={{ marginTop: -4 }} size={10} />
            </button> */}
            <select
              class="form-control font-weight-light"
              id="templateid"
              style={{ fontSize: 16 }}
              onChange={(e) => {
                updateFormField("notify", "templateid", e.target.value)
              }}
              value={
                forms.notify && forms.notify.templateid
                  ? forms.notify.templateid
                  : ""
              }
            >
              <option selected>Choose...</option>
              {props.publisher && props.publisher.templates
                ? props.publisher.templates.map((template) => (
                    <option value={template.id}>{template.title}</option>
                  ))
                : null}
            </select>
          </div>
        </div>
      </div>
      {forms.notify.templateid ? (
        <div class="offset-sm-2 form-group row d-flex flex-row align-items-center justify-content-center">
          {/* {props.publisher &&
          props.publisher.templates &&
          props.publisher.templates.find(
            (template) => template.id === forms.notify.templateid
          ) ? (
            <img
              src={
                props.publisher.templates.find(
                  (template) => template.id === forms.notify.templateid
                ).thumb
              }
              width={150}
            />
          ) : null} */}

          <img src={imageThumb} width={150} />
          {/* <img src={} width={150} /> */}
        </div>
      ) : null}
      <hr size={1} />
      <div class="form-group row d-flex flex-row align-items-center justify-content-start">
        <label
          for="notifyemail"
          class="col-4 col-form-label font-weight-light line-height-1"
        >
          Send email
        </label>
        <div class="col-6">
          <div class="d-flex flex-row align-items-center justify-content-start pl-3">
            <input
              class="form-check-input"
              type="checkbox"
              checked={forms.notify && forms.notify.notifyemail ? true : false}
              // value="1"
              // defaultValue="0"
              id="notifyemail"
              onChange={(e) =>
                updateFormField("notify", "notifyemail", e.target.checked)
              }
            />
          </div>
        </div>
      </div>
      {company && company.sms && company.sms === true ? (
        <div class="form-group row d-flex flex-row align-items-center justify-content-start">
          <label
            for="notifysms"
            class="col-4 col-form-label font-weight-light line-height-1"
          >
            Send SMS
          </label>
          <div class="col-6">
            <div class="d-flex flex-row align-items-center justify-content-start pl-3">
              <input
                class="form-check-input"
                type="checkbox"
                checked={forms.notify && forms.notify.notifysms ? true : false}
                // value="1"
                // defaultValue="0"
                id="notifysms"
                onChange={(e) => {
                  console.log("SMS -- e.target.checked")
                  console.log(e.target.checked)
                  updateFormField("notify", "notifysms", e.target.checked)
                }}
              />
            </div>
          </div>
        </div>
      ) : null}

      <div class="d-flex flex-row align-items-center justify-content-center">
        <button
          class="btn btn-link"
          type="button"
          data-dismiss="modal"
          aria-label="Close"
        >
          Cancel
        </button>
        <button
          class="btn btn-primary"
          onClick={() => {
            createNotify(
              forms.notify.content,
              imageThumb,
              forms.notify.templateid,
              templateContext,
              forms.notify.notifyemail,
              forms.notify.notifysms
            )
          }}
        >
          Post
        </button>
        {isLoadingNotifyCreate ? <Loading class="small ml-3" /> : null}
      </div>
    </div>
  )
}
export default Snippet
