import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"

import { logoutUser } from "./userManagement"
import { unauthorizedMessage } from "../utils/constants"

export function getNotify() {
  let cid = Cache.getItem("cid")

  console.log("actions - getNotify - cid")
  console.log(cid)

  let apiName = "Storylavaweb"
  let path = "admin/notify/list"

  return (dispatch) => {
    dispatch({
      type: "LOADING_START",
    })
    Auth.currentAuthenticatedUser()
      .then((user) => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log(
          "actions - getNotify - Auth.currentAuthenticatedUser - user"
        )
        console.log(user)

        let jwtToken = user.signInUserSession.accessToken.jwtToken
        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            cid,
          },
        }

        //begin promise
        new Promise((resolve) => {
          API.get(apiName, path, options)
            .then((response) => {
              const data = response || {}

              console.log("actions - getNotify - API.get - data")
              console.log(data)

              //Sort with latest template on top
              data.sort((a, b) => (a.datecreated > b.datecreated ? -1 : 1))

              return resolve(
                dispatch({
                  type: "NOTIFY_REPLACE",
                  data,
                })
              )
            })
            .catch((error) => {
              console.log(error.response)
              if (
                error &&
                error.response &&
                error.response.status &&
                (error.response.status === 401 || error.response.status === 403)
              ) {
                dispatch(logoutUser(unauthorizedMessage))
              }
            })
        }) //end promise
      })
      .catch((error) => {
        console.log(error)
      }) //end get Auth token
  }
}
