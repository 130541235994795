//React
import React, { useRef, useState, useEffect } from "react"
import { connect } from "react-redux"

//Components
import EditorCanvas from "./editorCanvas"
import PreviewTrimmer from "./previewTrimmer"
import ElementsNone from "./elementsNone"

//Packages
const agentParser = require("ua-parser-js")

//Redux
const mapStateToProps = ({ publisher, isLoading, isLoadingCanvasImage }) => {
  return {
    publisher,
    isLoading,
    isLoadingCanvasImage,
  }
}

const mapDispatchToProps = {}

const Snippet = (props) => {
  const { stageRef, changeAttr, publisher } = props

  const [currentBrowser, setCurrentBrowser] = React.useState("")
  const videoRef = React.createRef()

  console.log("**videoRef")
  console.log(videoRef)

  useEffect(() => {
    //like componentDidMount
    const agentObj = agentParser(window.navigator.userAgent)
    console.log("** agentObj")
    console.log(agentObj)
    setCurrentBrowser(agentObj.browser.name)
  }, [])

  useEffect(() => {
    const { current } = videoRef

    const handleFocus = () => {
      console.log("input is focussed")
    }
    const handleBlur = () => {
      console.log("input is blurred")
    }
    if (current) {
      current.addEventListener("focus", handleFocus)
      current.addEventListener("blur", handleBlur)

      return () => {
        current.removeEventListener("focus", handleFocus)
        current.removeEventListener("blur", handleBlur)
      }
    }
  })

  const currentSceneId =
    publisher.sceneSelected && publisher.sceneSelected.sceneid
      ? publisher.sceneSelected.sceneid
      : "scene1"
  const currentScene = publisher.loadedScenes
    ? publisher.loadedScenes.find((item) => item.sceneid === currentSceneId)
    : []
  console.log("**previewVideo currentScene")
  console.log(currentScene)

  const videoWidth = 400
  const videoHeight = 400
  // const videoWidth = 270
  // const videoHeight = 480

  // const VideoElement = React.forwardRef((props, ref) => (
  //   <video
  //     width={props.videoWidth}
  //     height={props.videoHeight}
  //     autoPlay
  //     muted
  //     loop
  //     key={props.videoSrc}
  //     ref={props.ref}
  //   >
  //     <source src={props.videoSrc} type="video/mp4" />
  //   </video>
  // ))

  return (
    <>
      <div
        class=" d-flex flex-row"
        // class="border-1-gray-7 shadow-sm d-flex flex-row"
        style={{
          // width: publisher.stage.w + 2 || 500 + 2, //add 2 to show right border correctly
          // height: publisher.stage.h || 500,
          width: 500,
          height: 500,
        }}
      >
        {/* <div style={{ height: 500, width: 200 }}></div> */}
        {currentScene &&
        currentScene.scenelayers &&
        currentScene.scenelayers.length > 0 ? (
          currentScene.scenelayers.map((layer, i) => {
            let videoSrc

            console.log("** currentBrowser")
            console.log(currentBrowser)
            if (layer.mode === "video") {
              if (
                currentBrowser &&
                currentBrowser.includes("Safari") === false
              ) {
                videoSrc = layer.src.replace(".mov", ".webm")
                videoSrc = videoSrc.replace(".MOV", ".webm")
              } else {
                videoSrc = layer.src
              }
              console.log("** videoSrc")
              console.log(videoSrc)
              return (
                <div
                  class="border-1-gray-5"
                  style={{
                    width: videoWidth,
                    height: videoHeight,
                    position: "absolute",
                  }}
                  onClick={() => {
                    // alert("clicked")
                  }}
                >
                  {/* <VideoElement
                      ref={videoRef}
                      // forwardRef={videoRef}
                      videoWidth={videoWidth}
                      videoHeight={videoHeight}
                      videoSrc={videoSrc}
                    /> */}
                  <video
                    width={videoWidth}
                    height={videoHeight}
                    // autoPlay
                    muted
                    loop
                    key={videoSrc}
                    ref={videoRef}
                  >
                    <source src={videoSrc} type="video/mp4" />
                  </video>
                </div>
              )
            } else if (layer.mode === "image") {
              return (
                <div style={{ position: "absolute" }}>
                  <EditorCanvas
                    stageRef={stageRef}
                    changeAttr={changeAttr}
                    context="video"
                  />
                </div>
              )
            }
          })
        ) : (
          <ElementsNone />
        )}
      </div>
      {currentScene && currentScene.scenelayers ? (
        <div class="row">
          <PreviewTrimmer videoRef={videoRef} />
        </div>
      ) : null}
    </>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
