//React
import React, { useState } from "react"

//Packages
import { SketchPicker } from "react-color"
import FontPicker from "font-picker-react"

//Components
import SidebarTitle from "../sidebarTitle"
import DeleteElement from "./deleteElement"

//Icons
import {
  MdFormatColorText,
  MdFormatAlignLeft,
  MdFormatAlignCenter,
  MdFormatAlignRight,
  MdFormatLineSpacing,
} from "react-icons/md"

//Constants
import { defaultSwatches } from "../../../utils/constants"

const FontFaceObserver = require("fontfaceobserver")

const Snippet = ({
  changeAttr,
  elements,
  elementSelected,
  context,
  removeSelectedElement,
}) => {
  const currentElement = elements.find(
    (element) => element.id === elementSelected.id
  )

  const [collapse, setCollapse] = useState(true)
  const [lineHeightCollapse, setLineHeightCollapse] = useState(true)

  const fontSizes = []

  for (let i = 10; i < 300; i += 2) {
    fontSizes.push(<option key={i}>{i}</option>)
  }

  const lineHeightSizesInput = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2, 2.5, 3]

  const lineHeightSizes = lineHeightSizesInput.map((i) => (
    <button
      key={i}
      class={`btn-strokewidth btn btn-slim px-3 ${
        i === 0.5 ? "round-corners-top" : ""
      } ${i === 5 ? "round-corners-bottom" : ""}`}
      onClick={() => {
        changeAttr("image", "lineHeight", i)
        // if (!currentElement?.stroke) {
        //   changeAttr("image", "stroke", "#000000")
        // }
        setLineHeightCollapse(true)
      }}
    >
      <span
        class={`small ${
          currentElement?.lineHeight === i
            ? "font-weight-bold"
            : "font-weight-normal"
        }`}
      >
        {i}
      </span>
    </button>
  ))

  return (
    <>
      <div class="d-none d-md-block w-100 my-2">
        <SidebarTitle text="Font attributes" />
      </div>
      <div class="d-flex flex-row flex-wrap align-items-center justify-content-center p-1">
        <div class="mr-1">
          <FontPicker
            apiKey=""
            activeFontFamily={
              currentElement && currentElement.fontFamily
                ? currentElement.fontFamily
                : "Open Sans"
            }
            limit={447}
            onChange={(nextFont) => {
              console.log("nextFont")
              console.log(nextFont)
              //Need fontobserver - See https://github.com/samuelmeuli/font-picker-react/issues/38
              const font = new FontFaceObserver(nextFont.family)

              font.load().then(
                () => {
                  changeAttr(context, "fontFamily", `${nextFont.family}`)
                },
                () => {
                  console.log(`font not available`)
                }
              )

              // let fontPathString = `static/fonts/${nextFont.license}/${nextFont.idshort}/${nextFont.fontmachinestring}.ttf`
              // redrawLayer()
            }}
          />
        </div>
      </div>
      <div class="d-flex flex-row flex-wrap align-items-center justify-content-center  p-1">
        {/* <div class="form-group mr-1">
          <select
            class="form-control form-control-sm"
            style={{ fontSize: 11 }}
            onChange={(e) => changeAttr(context, "fontFamily", e.target.value)}
          >
            <option>Open Sans</option>
            <option>Arial</option>
            <option>Helvetica</option>
            <option>Georgia</option>
          </select>
        </div> */}
        <div>
          <div class="d-flex flex-row mx-1 ">
            <button
              class={`btn btn-slim mr-1 border-1-gray-6 ${
                currentElement &&
                currentElement.fill &&
                currentElement.fill === "#ffffff"
                  ? "bg-gray-2"
                  : ""
              }`}
              style={{
                borderRadius: 4,
                paddingLeft: 2,
                paddingRight: 2,
                paddingTop: 0,
                paddingBottom: 1,
                marginTop: 0,
              }}
              type="button"
              // data-toggle="collapse"
              // data-target="#fontColor"
              // aria-expanded="false"
              aria-controls="fontColor"
              onClick={() => {
                setCollapse(!collapse)
              }}
            >
              <MdFormatColorText
                size={18}
                color={
                  currentElement &&
                  currentElement.fill &&
                  currentElement.fill === "#ffffff"
                    ? "#cccccc"
                    : currentElement.fill
                    ? currentElement.fill
                    : "#000000"
                }
              />
            </button>
          </div>
          <div>
            <div
              class={collapse ? "d-none" : "d-block"}
              id="fontColor"
              // ref={collapseRef}
              style={{ position: "absolute", marginTop: 4, zIndex: 100 }}
            >
              <SketchPicker
                color={currentElement.fill}
                presetColors={defaultSwatches}
                onChangeComplete={(e) => {
                  console.log("onChangeComplete - e - font")
                  console.log(e)
                  const colorValue = `rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`
                  changeAttr(context, "fill", colorValue)
                  setCollapse(true)
                }}
              />
            </div>
          </div>
        </div>
        <div class="mr-1" style={{ width: 50, height: 27 }}>
          <select
            class="form-control form-control-sm"
            style={{ fontSize: 11 }}
            onChange={(e) => changeAttr(context, "fontSize", e.target.value)}
            value={
              currentElement && currentElement.fontSize
                ? currentElement.fontSize
                : 20
            }
          >
            {fontSizes}
          </select>
        </div>
        <div class="mt-1 mr-1 d-flex flex-row flex-wrap align-items-center">
          <button
            class={`btn btn-slim mr-1 ${
              currentElement &&
              currentElement.align &&
              currentElement.align === "left"
                ? "dkblue-1"
                : "gray-1"
            }`}
            style={{
              borderRadius: 4,
              paddingLeft: 2,
              paddingRight: 2,
              marginBottom: 4,
            }}
            type="button"
            onClick={(e) => changeAttr(context, "align", "left")}
          >
            <MdFormatAlignLeft size={22} />
          </button>
          <button
            class={`btn btn-slim mr-1 ${
              currentElement &&
              currentElement.align &&
              currentElement.align === "center"
                ? "dkblue-1"
                : "gray-1"
            }`}
            style={{
              borderRadius: 4,
              paddingLeft: 2,
              paddingRight: 2,
              marginBottom: 4,
            }}
            type="button"
            onClick={(e) => changeAttr(context, "align", "center")}
          >
            <MdFormatAlignCenter size={22} />
          </button>
          <button
            class={`btn btn-slim mr-1 ${
              currentElement &&
              currentElement.align &&
              currentElement.align === "right"
                ? "dkblue-1"
                : "gray-1"
            }`}
            style={{
              borderRadius: 4,
              paddingLeft: 2,
              paddingRight: 2,
              marginBottom: 4,
            }}
            type="button"
            onClick={(e) => changeAttr(context, "align", "right")}
          >
            <MdFormatAlignRight size={22} />
          </button>
          <div
            class="border-right-1-gray-5 mr-1"
            style={{ width: 1, height: 20, marginTop: 4 }}
          ></div>
          <button
            class={`btn btn-slim gray-1`}
            style={{
              borderRadius: 4,
              paddingLeft: 2,
              paddingRight: 2,
              marginBottom: 4,
            }}
            type="button"
            onClick={(e) => {
              setLineHeightCollapse(false)
            }}
          >
            <MdFormatLineSpacing size={22} />
          </button>
        </div>
        <DeleteElement removeSelectedElement={removeSelectedElement} />

        <div
          class={`${
            lineHeightCollapse ? "d-none" : "d-flex"
          } fade-in-now flex-column border-1-gray-5 round-corners`}
          id="lineHeight"
        >
          {lineHeightSizes}
        </div>
      </div>
    </>
  )
}

export default Snippet
