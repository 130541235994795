//React
import React from "react"
import { connect } from "react-redux"

//Amplify
import Amplify from "@aws-amplify/core"
import { amplifyconfig } from "../utils/config"

//Packages
import parse from "url-parse"

//Actions
// import { listTags } from "../actions/manageTags"
import { refreshLoginData } from "../actions/userManagement"

//Components
import Layout from "../components/layout"
import NavApp from "../components/nav/navApp"
// import MenuBack from "../components/appReporting/menuBack"
import PublisherCreator from "../components/appPublisher/publisherTemplateDetail"
import MediaContainer from "../components/appPublisher/mediaContainer"
import ProfileContainer from "../components/appAdmin/profileContainer"
import UserGroupCreate from "../components/appUserGroup/appUserGroupCreate"
import CalendarContainer from "../components/appCalendar/calendarContainer"

//Redux
const mapStateToProps = ({
  campaign,
  sendgroup,
  templatetags,
  userlogin,
  isLoading,
  company,
  userdetail,
}) => {
  return {
    campaign,
    sendgroup,
    templatetags,
    userlogin,
    isLoading,
    company,
    userdetail,
  }
}

const mapDispatchToProps = {
  // listTags: () => dispatch(listTags()),
  refreshLoginData,
}

//Configure
Amplify.configure(amplifyconfig)

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    console.log(">>>>>>this.props.campaign<<<<<<<")
    console.log(this.props.campaign)
    console.log(">>>>>>this.props.sendgroup<<<<<<<")
    console.log(this.props.sendgroup)
    const { company, userdetail, refreshLoginData } = this.props
    // if (!this.props.templatetags || this.props.templatetags.length === 0) {
    //   this.props.listTags()
    // }

    if (
      (company.constructor === Object &&
        Object.entries(company).length === 0) ||
      (userdetail.constructor === Object &&
        Object.entries(userdetail).length === 0)
    ) {
      //if company or userdetail has no object entries
      console.log("****** getting USER AND COMPANY ********")
      refreshLoginData()
      // getCompanyDetail(null, null, true) //include the third property to ensure userdetail profile data is copied correctly
    }
  }

  render() {
    // console.log("((this.props))")
    // console.log(this.props)

    const {
      campaign,
      sendgroup,
      sendgroupcomplete,
      isLoading,
      isLoadingTemplateCreate,
      templatetags,
      userlogin,
    } = this.props

    const userRole =
      userlogin &&
      userlogin.cdata &&
      userlogin.cdata[0] &&
      userlogin.cdata[0].role
        ? userlogin.cdata[0].role
        : ""
    const userPlan =
      userlogin &&
      userlogin.cdata &&
      userlogin.cdata[0] &&
      userlogin.cdata[0].plan
        ? userlogin.cdata[0].plan
        : ""

    // console.log("[[userRole]]")
    // console.log(userRole)

    const url = parse(window.location.href, true)

    // console.log("userlogin")
    // console.log(userlogin)
    // console.log("templatetags")
    // console.log(templatetags)

    const pathname = url.pathname
    // console.log("((pathname))")
    // console.log(pathname)
    const templateid = url.query.t
    const campaignDetail = campaign
      ? campaign.find(({ id }) => id === url.query.t)
      : {}

    const campaignDetailTitle =
      campaignDetail && campaignDetail.title ? campaignDetail.title : ""
    const campaignDetailPoster =
      campaignDetail && campaignDetail.poster ? campaignDetail.poster : ""
    // console.log("campaign - campaign")
    // console.log(campaign)
    // console.log("campaignDetail - campaign")
    // console.log(campaignDetail)

    // console.log("[[[[campaignDetail]]]]")
    // console.log(campaignDetail)
    return (
      <Layout userRole={userRole}>
        <div class="container-app-bg flex-grow-1">
          <NavApp pathname={pathname} />

          <div class="container-reporting">
            {/* {pathname === "/app/trends/" || pathname === "/app/detail/" ? (
              <div class="row pl-3">
                <MenuBack text="Back" link="/app/screener/" />
              </div>
            ) : null} */}

            <div class="">
              <>
                {/* {isLoading ? "true" : "false"} */}
                {pathname === "/app/publisher/" ? (
                  <PublisherCreator
                    context="image"
                    userRole={userRole}
                    userPlan={userPlan}
                  />
                ) : null}
                {pathname === "/app/video/" ? (
                  <div class="col-sm-12">
                    <PublisherCreator context="video" />
                  </div>
                ) : null}
                {pathname === "/app/profile/" ? (
                  <div class="col-sm-12">
                    <ProfileContainer />
                  </div>
                ) : null}
                {pathname === "/app/users/" ? (
                  <div class="col-sm-12">
                    <UserGroupCreate />
                  </div>
                ) : null}
                {pathname === "/app/calendar/" ? (
                  <div class="col-sm-12">
                    <CalendarContainer />
                  </div>
                ) : null}
              </>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

// export default App
export default connect(mapStateToProps, mapDispatchToProps)(App)
