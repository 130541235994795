import React from "react"
import { connect } from "react-redux"

//Actions
import { updatePublisherField } from "../../actions/createPublisher"

//Components
import PreviewTimelineElement from "./previewTimelineElement"

//Redux
const mapStateToProps = ({ publisher, isLoading, isLoadingCanvasImage }) => {
  return {
    publisher,
    isLoading,
  }
}

const mapDispatchToProps = {
  updatePublisherField,
}

const Snippet = (props) => {
  const { publisher, updatePublisherField, updateTemplateElements } = props

  const selectedTemplate =
    publisher && publisher.templateSelected ? publisher.templateSelected : null

  const targetTemplate = publisher.templates.find(
    (template) => template.id === selectedTemplate
  )

  return (
    <>
      {targetTemplate ? (
        <div class="row mt-4 mb-3">
          <div class="col-md-12 d-flex flex-row">
            {targetTemplate && targetTemplate.scene
              ? targetTemplate.scene.map((scene, i) => {
                  let sceneName
                  let sceneColor

                  let active = false

                  if (
                    publisher &&
                    publisher.sceneSelected &&
                    publisher.sceneSelected.sceneid &&
                    publisher.sceneSelected.sceneid === scene.sceneid
                  )
                    active = true

                  switch (scene.scenename) {
                    case "imagebase":
                      sceneName = ""
                      sceneColor = "dkblue"
                      break
                    case "videobase":
                      // code block
                      sceneName = ""
                      sceneColor = "dkgreen"
                    default:
                    // code block
                  }
                  return (
                    <PreviewTimelineElement
                      pos={i + 1}
                      title={`Scene ${i + 1}`}
                      color={sceneColor}
                      width={125}
                      active={active}
                      onSelect={() => {
                        updateTemplateElements(scene.sceneid)
                      }}
                    />
                  )
                })
              : null}
          </div>
        </div>
      ) : null}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
