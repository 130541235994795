//React
import React from "react"

//Gatsby

//Actions

//Components
//Icons
import { IoShareOutline, IoDocumentOutline } from "react-icons/io5"
import { MdLayersClear } from "react-icons/md"
import { AiOutlineSave, AiOutlineEdit } from "react-icons/ai"
import { HiMicrophone, HiOutlineMicrophone } from "react-icons/hi"

//Exceptions

//Redux

const Snippet = (props) => {
  const {
    saveImage,
    saveImageCloud,
    saveTemplate,
    publisherMode,
    publisher,
    recordAudio,
    conversationActive,
    company,
  } = props

  // console.log("(((conversationActive)))")
  // console.log(conversationActive)
  return (
    <>
      {company && company.voice && company.voice === true ? (
        <button
          class={`btn conv-container mt-1 flex-shrink-0 d-flex justify-content-center align-items-center ${
            conversationActive ? "listening" : ""
          }`}
          onClick={recordAudio}
        >
          <div class="conv d-flex flex-shrink-0 justify-content-center align-items-center align-self-center">
            <HiOutlineMicrophone size={30} class="white-1" />
          </div>
        </button>
      ) : null}
      <div
        class="d-flex flex-column flex-md-row p-2 align-items-center justify-content-center"
        style={{ marginLeft: 2 }}
      >
        {/* <div
    class="btn btn-sm btn-secondary"
    style={{ borderRadius: 4, fontSize: 11 }}
  >
    Download <MdFileDownload />
  </div> */}

        <button
          data-toggle="modal"
          data-target="#sharerModal"
          onClick={saveImageCloud}
          class="btn btn-sm btn-primary btn-editor-save m-1 px-2 px-md-4 px-2 btn-block"
          disabled={
            publisher && publisher.elements && publisher.elements.length === 0
          }
        >
          <IoShareOutline size={26} />
        </button>

        {/* {publisherMode === "video" ? (
      <Link to="/app/share/" class="btn btn-sm btn-primary m-1">
        Share
      </Link>
    ) : null} */}
        {publisherMode === "video" ? (
          <button
            onClick={saveImage}
            class="btn btn-sm btn-primary btn-editor-save m-1 px-2 px-md-4 px-2 btn-block"
          >
            Export
          </button>
        ) : null}
        {/* {publisherMode === "image" ? (
      <button onClick={saveImage} class="btn btn-sm btn-primary">
        Save
      </button>
    ) : null} */}
        <button
          data-toggle="modal"
          data-target="#templateModal"
          onClick={saveTemplate}
          class="btn btn-sm btn-orange btn-editor-save m-1 px-2 px-md-4 px-2 btn-block"
          disabled={
            publisher && publisher.elements && publisher.elements.length === 0
          }
        >
          <AiOutlineEdit size={26} />
          {/* Save <span class="d-none d-md-inline">template</span> as... */}
        </button>
      </div>
    </>
  )
}

export default Snippet
