//React
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

//Gatsby
import { Link } from "gatsby"

//Actions
import {
  updateFormField,
  copyProfile,
  copyProfileEmail,
} from "../../actions/updateForm"
import {
  getCompanyDetail,
  updateUserProfile,
  refreshLoginData,
} from "../../actions/userManagement"

//Components
import EmailVerifyModal from "./emailVerifyModal"

//Icons
import { FaSpinner, FaRegCheckCircle } from "react-icons/fa"

//Redux
const mapStateToProps = ({
  company,
  userdetail,
  userdetailemail,
  forms,
  isLoading,
  isProfileUpdateComplete,
}) => {
  return {
    company,
    userdetail,
    userdetailemail,
    forms,
    isLoading,
    isProfileUpdateComplete,
  }
}

const mapDispatchToProps = {
  updateFormField,
  getCompanyDetail,
  copyProfile,
  copyProfileEmail,
  updateUserProfile,
  refreshLoginData,
}

const Snippet = (props) => {
  const {
    company,
    userdetail,
    userdetailemail,
    forms,
    updateFormField,
    isLoading,
    getCompanyDetail,
    copyProfile,
    copyProfileEmail,
    updateUserProfile,
    isProfileUpdateComplete,
    refreshLoginData,
  } = props

  useEffect(() => {
    if (
      (company.constructor === Object &&
        Object.entries(company).length === 0) ||
      (userdetail.constructor === Object &&
        Object.entries(userdetail).length === 0)
    ) {
      //if company or userdetail has no object entries
      console.log("****** getting USER AND COMPANY ********")
      refreshLoginData()
      // getCompanyDetail(null, null, true) //include the third property to ensure userdetail profile data is copied correctly
    } else {
      console.log("**NOT getting company")
      copyProfile()
      copyProfileEmail()
    }
  }, [])

  console.log("((profileContainer - company))")
  console.log(company)
  console.log("((profileContainer - userdetail))")
  console.log(JSON.stringify(userdetail))
  console.log("((profileContainer - userdetailemail))")
  console.log(JSON.stringify(userdetailemail))
  console.log("((profileContainer - forms))")
  console.log(JSON.stringify(forms))
  // const [userSub, setUserSub] = useState(null)

  return (
    <div class="profile-container p-4">
      <div class="row">
        <div class="col-sm-12 ">
          <h3 class="mb-3">Profile</h3>
          {/* <p class="black-4">Manage tags below to group your templates</p> */}
        </div>
      </div>
      {/* {isLoading ? (
        <div class="row">
          <div class="col-sm-12 d-flex flex-row align-items-center justify-content-center">
            <div class="p-4">
              <Loading class="small" />
            </div>
          </div>
        </div>
      ) : null} */}
      <div class="row">
        <div class="col-sm-12 d-flex flex-column align-items-start justify-content-start">
          {forms && forms.userdetail && forms.userdetail.fname ? (
            <div class="form-group w-100">
              <label for="profile-fname">First name</label>
              <input
                type="text"
                class="form-control mr-2"
                id="profile-fname"
                aria-describedby="fnameHelp"
                placeholder="First name"
                onChange={(e) =>
                  updateFormField("userdetail", "fname", e.target.value)
                }
                value={forms.userdetail.fname}
              />
              {/* <small id="fnameHelp" class="form-text text-muted">
              We'll never share your email with anyone else.
            </small> */}
            </div>
          ) : null}
          {forms && forms.userdetail && forms.userdetail.lname ? (
            <div class="form-group w-100">
              <label for="profile-lname">Last name</label>
              <input
                type="text"
                class="form-control mr-2"
                id="profile-lname"
                aria-describedby="lnameHelp"
                placeholder="Last name"
                onChange={(e) =>
                  updateFormField("userdetail", "lname", e.target.value)
                }
                value={forms.userdetail.lname}
              />
              {/* <small id="lnameHelp" class="form-text text-muted">
              We'll never share your email with anyone else.
            </small> */}
            </div>
          ) : null}
          <div class="form-group w-100">
            <label for="profile-userDescription">About you</label>
            <textarea
              rows="3"
              class="form-control mr-2"
              id="profile-userDescription"
              aria-describedby="userDescription"
              placeholder="About you"
              onChange={(e) =>
                updateFormField("userdetail", "description", e.target.value)
              }
              value={forms.userdetail.description}
            />
            {/* <small id="lnameHelp" class="form-text text-muted">
              We'll never share your email with anyone else.
            </small> */}
          </div>
          {forms && forms.userdetailemail && forms.userdetailemail.email ? (
            <div class="form-group w-100">
              <label for="profile-email">Email address</label>
              <div class="d-flex flex-row align-items-center justify-content-start">
                <div class="profile-email-data">
                  {forms.userdetailemail.email}
                </div>
                <button
                  class="ml-3 btn btn-outline-success"
                  data-toggle="modal"
                  data-target="#emailVerifyModal"
                  // onClick={() => {
                  //   copyProfileEmail()
                  // }}
                >
                  Update email
                </button>
              </div>
              <div class="mt-4">
                <label for="emailNotify">Receive email notifications</label>
                <select
                  class="form-control col-md-3"
                  id="emailNotify"
                  onChange={(e) => {
                    console.log("userdetail - emailnotify - e.target")
                    console.log(e.target)
                    let formVal = e.target.value === "true" ? true : false
                    updateFormField("userdetail", "emailnotify", formVal)
                  }}
                  value={
                    forms.userdetail.emailnotify === true ? "true" : "false"
                  }
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>

              {/* <input
                type="email"
                class="form-control mr-2"
                id="profile-email"
                aria-describedby="emailHelp"
                placeholder="Your email"
                onChange={(e) =>
                  updateFormField("userdetail", "email", e.target.value)
                }
                value={forms.userdetail.email}
              /> */}
              {/* <small id="emailHelp" class="form-text text-muted">
              We'll never share your email with anyone else.
            </small> */}
            </div>
          ) : null}

          <div class="form-group w-100">
            <label for="profile-mobile">Mobile number</label>
            <div class="d-flex flex-row align-items-center justify-content-start">
              <div class="profile-mobile-data">
                {forms &&
                forms.userdetail &&
                forms.userdetail.mobile &&
                forms.userdetail.mobile.provided
                  ? forms.userdetail.mobile.provided
                  : "No number"}
              </div>
              <button
                class="ml-3 btn btn-outline-success"
                data-toggle="modal"
                data-target="#mobileVerifyModal"
                // onClick={() => {
                //   copyProfileEmail()
                // }}
              >
                Update mobile number
              </button>
            </div>

            <small
              id="mobileHelp"
              class="form-text text-muted line-height-1 my-2"
            >
              Get text messages from your organization with the latest templates
              and campaigns.
            </small>

            <div class="mt-4">
              <label for="mobileNotify">
                Receive text message notifications
              </label>
              <select
                class="form-control col-md-3"
                id="mobileNotify"
                onChange={(e) => {
                  console.log("userdetail - emailnotify - e.target")
                  console.log(e.target)
                  let formVal = e.target.value === "true" ? true : false
                  updateFormField("userdetail", "mobilenotify", formVal)
                }}
                value={
                  forms.userdetail.mobilenotify === true ? "true" : "false"
                }
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

            {/* <input
              type="email"
              class="form-control mr-2"
              id="profile-mobile"
              aria-describedby="mobileHelp"
              placeholder="Your mobile number"
              onChange={(e) =>
                updateFormField("userdetail", "mobile", e.target.value)
              }
              value={
                forms && forms.userdetail && forms.userdetail.mobile
                  ? forms.userdetail.mobile
                  : ""
              }
            /> */}
          </div>

          <div class="form-group w-100">
            <label for="profile-displayname">Public URL name</label>
            <div class="d-flex flex-row align-items-center justify-content-start">
              <div class="">{forms.userdetailemail.displayname}</div>
              <button
                class="ml-3 btn btn-outline-success"
                data-toggle="modal"
                data-target="#displayVerifyModal"
                // onClick={() => {
                //   copyProfileEmail()
                // }}
              >
                Update public URL
              </button>
            </div>
          </div>

          <div class="form-group w-100">
            <label for="profile-password">Password</label>

            <div class="w-100">
              <Link class="btn btn-outline-success" to="/app/password">
                Reset password
              </Link>
            </div>
          </div>
          <div class="form-group w-100">
            <label for="profile-close-account">Close account</label>

            <div class="w-100">
              <button
                class="btn btn-outline-danger"
                data-toggle="modal"
                data-target="#closeAccountModal"
                // onClick={() => {
                //   copyProfileEmail()
                // }}
              >
                Close account
              </button>
            </div>
          </div>
          <hr size={1} />

          <div class="form-group d-flex flex-row w-100 align-items-center justify-content-start">
            <Link
              class="btn btn-link"
              style={{ minWidth: 140 }}
              to="/app/publisher/"
            >
              Cancel
            </Link>
            <button
              class="btn btn-primary d-flex flex-row align-items-center justify-content-center"
              style={{ minWidth: 140 }}
              onClick={() => {
                updateUserProfile(forms.userdetail, userdetail)
              }}
            >
              Save
              {isLoading ? (
                <div className="pl-2">
                  <FaSpinner size={16} className="icon-spin" />
                </div>
              ) : null}
            </button>
            {isProfileUpdateComplete ? (
              <div class="d-flex flex-row align-items-center ml-3">
                <FaRegCheckCircle class="dkgreen-1" size={22} />
                <div class="dkgreen-1 pl-1 font-weight-light">
                  Profile updated
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <EmailVerifyModal context="verify" />
        <EmailVerifyModal context="verifymobile" />
        <EmailVerifyModal context="verifydisplay" />
        <EmailVerifyModal context="closeaccount" />
      </div>
    </div>
  )
}
// export default Snippet
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
