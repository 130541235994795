//React
import React, { useEffect } from "react"

//Components
import SidebarTitle from "../sidebarTitle"

//Icons
import { FaInstagram, FaFacebookSquare } from "react-icons/fa"
import { MdImage } from "react-icons/md"

const Snippet = ({ stage, updatePublisherField }) => {
  console.log("stageAttributes :: stage")
  console.log(stage)

  const widthAdder = 100
  const heightAdder = 100
  const templateTypes = [
    {
      id: "instagram",
      name: "Instagram Post",
      width: 700,
      height: 700,
      export: {
        w: 1080,
        h: 1080,
        scaleW: 700,
        scaleH: 700,
        x: widthAdder / 2,
        y: heightAdder / 2,
      },
    },
    {
      id: "facebook",
      name: "Facebook Post",
      width: 700,
      height: 700,
      export: {
        w: 1200,
        h: 628,
        scaleW: 700,
        scaleH: (700 * 628) / 1200,
        x: widthAdder / 2,
        y: heightAdder / 2,
      },
    },
    {
      id: "square",
      name: "Square",
      width: 700,
      height: 700,
      export: {
        w: 700,
        h: 700,
        scaleW: 700,
        scaleH: 700,
        x: widthAdder / 2,
        y: heightAdder / 2,
      },
    },
  ]

  useEffect(() => {
    const initialTemplate = templateTypes.find(
      (template) => template.id === "instagram"
    )
    console.log("stageAttributes :: initialTemplate")
    console.log(initialTemplate)

    if (!stage || (stage && !stage.id)) {
      updatePublisherField("stage", initialTemplate)
    }
  }, [])

  return (
    <>
      <div class="d-none d-md-block w-100 my-2">
        <SidebarTitle text="Canvas size" />
      </div>
      <div
        class="col-sm-12 mt-2 d-flex flex-row flex-wrap align-items-start justify-content-start tools-bg"
        // style={{ borderRadius: 6, padding: 6, backgroundColor: "#f2f2f2" }}
      >
        {templateTypes.map((template, i) => (
          <div class="m-2" style={{ width: 50 }}>
            <button
              class={`btn btn-slim d-flex align-items-center justify-content-center bg-gray-7 ${
                stage && stage.id && stage.id === template.id
                  ? "border-2-dkblue-1"
                  : "border-1-gray-1"
              }`}
              style={{
                width: 50,
                height: 50,
              }}
              onClick={() =>
                updatePublisherField("stage", {
                  ...stage,
                  id: template.id,
                  name: template.name,
                  width: template.width,
                  height: template.height,
                  w: template.width > 500 ? 500 : template.width,
                  h:
                    template.width > 500
                      ? (template.height * 500) / template.width //solve the proportion equation
                      : template.height,
                  pixelRatio:
                    template.width > 500 || template.height > 500
                      ? template.width / 500
                      : 1,
                  export: template.export,
                })
              }
            >
              {template.id === "instagram" ? (
                <FaInstagram
                  size={24}
                  className={`${
                    stage && stage.id && stage.id === template.id
                      ? "dkblue-1"
                      : "black-1"
                  }`}
                />
              ) : null}
              {template.id === "facebook" ? (
                <FaFacebookSquare
                  size={24}
                  className={`${
                    stage && stage.id && stage.id === template.id
                      ? "dkblue-1"
                      : "black-1"
                  }`}
                />
              ) : null}
              {template.id === "square" ? (
                <MdImage
                  size={24}
                  className={`${
                    stage && stage.id && stage.id === template.id
                      ? "dkblue-1"
                      : "black-1"
                  }`}
                />
              ) : null}
            </button>
            <div
              class="text-center black-4"
              style={{ fontSize: "10px", lineHeight: "10px" }}
            >
              {template.name}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default Snippet
