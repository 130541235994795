import { updatePublisherField } from "./createPublisher"

export const changeAttr = (context, prop, val, pos, multiple) => {
  return (dispatch, getState) => {
    const { publisher } = getState()
    console.log("***************changeAttr START")
    console.log(publisher)
    console.log("***************changeAttr prop")
    console.log(prop)
    console.log("***************changeAttr val")
    console.log(val)
    console.log("***************changeAttr pos")
    console.log(pos)

    let itemSelected
    let itemString
    let elementArray
    let elementIndex
    let sceneIndex
    if (context === "image") {
      itemSelected = "elementSelected"
      itemString = "elements"
      elementArray = publisher.elements

      elementIndex =
        typeof pos !== "undefined" && pos !== null //need this because can equal 0
          ? pos
          : elementArray.findIndex(
              (item) => item.id === publisher.elementSelected.id
            )
    } else if (context === "video") {
      itemSelected = "sceneSelected"
      itemString = "loadedScenes"
      sceneIndex = publisher.loadedScenes.findIndex(
        (scene) => scene.sceneid === publisher.sceneSelected.sceneid
      )
      elementArray = publisher.loadedScenes[sceneIndex].scenelayers

      // console.log("changeAttr :: elementArray - video")
      // console.log(elementArray)

      elementIndex =
        typeof pos !== "undefined" && pos !== null //need this because can equal 0
          ? pos
          : elementArray.findIndex(
              (item) => item.id === publisher.sceneSelected.layerid
            )
      // console.log("changeAttr :: elementIndex - video")
      // console.log(elementIndex)
    }

    console.log("changeAttr ::  elementIndex")
    console.log(elementIndex)
    let newAttrs = [...elementArray][elementIndex]

    if (multiple) {
      newAttrs = {
        ...newAttrs,
        ...multiple,
      }
    } else {
      newAttrs = {
        ...newAttrs,
        [prop]: val,
      }
    }
    console.log("changeAttr ::  newAttrs")
    console.log(newAttrs)
    const newElements = elementArray.slice()
    newElements[elementIndex] = newAttrs

    console.log("changeAttr ::  newElements")
    console.log(newElements)

    if (context === "video") {
      const newLoadedScenes = [...publisher.loadedScenes]
      newLoadedScenes[sceneIndex].scenelayers = newElements

      console.log("changeAttr ::  newLoadedScenes")
      console.log(newLoadedScenes)

      dispatch(updatePublisherField(itemString, newLoadedScenes))
    } else if (context === "image") {
      dispatch(updatePublisherField(itemString, newElements))
    }

    // if (context === "image") {
    // } else if (context === "video") {
    //   console.log("**new scene elements")
    //   console.log(newElements)
    // }

    console.log("changeAttr ::  publisher - post update")
    console.log(publisher)
    // setRectangles(rects)
    // if (prop === "fontFamily") {
    //   alert("updating fontFamily")
    // }
  }
}
