import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"

import { navigate } from "@reach/router"
import { logoutUser } from "./userManagement"
import { unauthorizedMessage } from "../utils/constants"

import {
  updatePublisherField,
  removeTemplate,
  loadNewTemplate,
} from "./createPublisher"
import { changeAttr } from "./modifyPublisher"

export function setConversationState(bool) {
  return (dispatch) => {
    if (bool) {
      dispatch({
        type: "CONV_START",
      })
    } else {
      dispatch({
        type: "CONV_END",
      })
    }
  }
}
export function setConversationContext(context) {
  return (dispatch) => {
    dispatch({
      type: "CONV_CONTEXT",
      data: context,
    })
  }
}

//For construction of dispatch after params, see: https://medium.com/collaborne-engineering/returning-promises-from-redux-action-creators-3035f34fa74b
export const sendVoice = (convContext, audio, sampleRate) => (
  dispatch,
  getState
) => {
  console.log("actions - sendVoice - start")

  let cid = Cache.getItem("cid")

  console.log("actions - sendVoice - cid")
  console.log(cid)

  let apiName = "Storylavaweb"

  let path = "conv/detect"

  return new Promise((resolve, reject) => {
    // dispatch({
    //   type: "LOADING_NOTIFY_CREATE",
    // })
    const { publisher } = getState()

    console.log("actions - sendVoice - preAuth")
    Auth.currentAuthenticatedUser()
      .then((user) => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log(
          "actions - sendVoice - Auth.currentAuthenticatedUser - user"
        )
        console.log(user)
        let jwtToken = user.signInUserSession.accessToken.jwtToken

        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            cid,
          },
          body: {
            uid: user.username,
            audio,
            sampleRate,
            convContext,
          },
        }

        console.log(
          "actions - sendVoice - Auth.currentAuthenticatedUser - options"
        )
        console.log(options)

        //begin promise
        // new Promise((resolve) => {
        API.post(apiName, path, options)
          .then((response) => {
            const data = response || {}

            let result = data

            console.log("actions - sendVoice - API.post - result")
            console.log(result)

            dispatch({
              type: "CONV_END",
            })

            const responseAction =
              result?.queryResult?.webhookPayload?.storylava?.responseAction
            const templateId =
              result?.queryResult?.webhookPayload?.storylava?.templateId
            const headlineText =
              result?.queryResult?.webhookPayload?.storylava?.headlineText
            const responseAudio = result?.outputAudio
            const responseContext = result?.queryResult?.outputContexts

            switch (responseAction) {
              case "templateLoad":
                console.log("actions - conversationManagement - templateLoad")
                dispatch(loadNewTemplate(templateId, "image"))
                break
              case "template.headline.edit":
                console.log(
                  "actions - conversationManagement - template.headline.edit"
                )
                const textIndex = publisher.elements.findIndex(
                  (element) => element.shape == "text"
                )
                dispatch(
                  changeAttr("image", "content", headlineText, textIndex)
                )
                break
              default:
              // code block
            }

            console.log("actions - setConversationContext - responseContext")
            console.log(responseContext)
            if (responseContext) {
              dispatch(setConversationContext(responseContext))
            } else {
              dispatch(setConversationContext([]))
            }

            if (responseAudio) {
              // console.log("actions - conversationManagement - responseAudio")
              // console.log(responseAudio)
              // var snd = new Audio(`data:audio/x-wav;base64, ${responseAudio}`)
              // snd.play()
            }
            // return resolve(dispatch(getNotify()))
            // return resolve()

            resolve(responseAudio)
          })
          .catch((error) => {
            if (
              error &&
              error.response &&
              error.response.status &&
              (error.response.status === 401 || error.response.status === 403)
            ) {
              dispatch(logoutUser(unauthorizedMessage))
            }
            console.log("actions - sendVoice - catch - error")
            console.log(error)
            console.log("actions - sendVoice - catch - error.response")
            console.log(error.response)
          })
        // }) //end promise
      })
      .catch((error) => {
        console.log(error)
      }) //end get Auth token
  }) //end promise
}
