//React
import React from "react"

//Icons
import { FaClipboardList, FaPencilRuler, FaMoneyCheckAlt } from "react-icons/fa"
import { MdPhonelinkRing, MdLock } from "react-icons/md"
import { IoIosRocket, IoMdBody, IoMdImages } from "react-icons/io"
import {
  AiOutlineTeam,
  AiFillThunderbolt,
  AiOutlineCloudDownload,
  AiFillNotification,
} from "react-icons/ai"
import { RiPencilRuler2Fill } from "react-icons/ri"

const Snippet = (props) => (
  <div
    id={props.context}
    class={`p-2 round-corners border-1-${props.color}-4 bg-${props.color}-1 d-flex flex-row align-items-center justify-content-start`}
  >
    {/* <div
      class={`p-2  d-flex flex-column align-items-center justify-content-center bg-${props.color}-6`}
      style={{ borderRadius: 10 }}
    > */}
    {props.context === "action" ? (
      <AiFillNotification
        // className={`${props.color}-1 fill-${props.color}-1`}
        style={{
          color: "#ffffff",
          fontSize: 16,
          opacity: 0.8,
          strokeWidth: 1,
        }}
      />
    ) : null}
    {/* </div> */}
    <span class={`white-1 font-weight-normal ml-2`}>{props.title}</span>
  </div>
)

export default Snippet
