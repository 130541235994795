import React from "react"
import { Circle, Ellipse, Transformer } from "react-konva"

const CircleShape = ({
  shapeProps,
  isSelected,
  stageDimensions,
  onSelect,
  onChange,
}) => {
  const shapeRef = React.useRef()
  const trRef = React.useRef()

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current])
      trRef.current.getLayer().batchDraw()
    }
  }, [isSelected])

  let shapePropsRevised = { ...shapeProps }
  // delete shapePropsRevised.radius //need to delete radius because it interferes with width and height in rendering

  return (
    <React.Fragment>
      <Ellipse
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapePropsRevised}
        draggable
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          })
        }}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          console.log("circle  - transform - e")
          console.log(e)

          const node = shapeRef.current
          console.log("circle  - transform - node")
          console.log(node)

          const scaleX = shapeProps.transformed
            ? node.scaleX()
            : node.scaleX() * (1 / stageDimensions.stageScale)
          const scaleY = shapeProps.transformed
            ? node.scaleY()
            : node.scaleY() * (1 / stageDimensions.stageScale)
          // we will reset it back
          node.scaleX(1)
          node.scaleY(1)

          const transform = node.getAbsoluteTransform()
          const attrs = transform.decompose()
          console.log("circle  - transform")
          console.log(transform)
          console.log("circle - attrs")
          console.log(attrs)

          onChange({
            ...shapeProps,
            ...attrs,
            x: node.x(),
            y: node.y(),
            // set minimal value
            // width: Math.max(5, node.width()),
            // height: Math.max(node.height()),
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
            rotation: node.rotation(),
            transformed: true,
          })
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox
            }
            return newBox
          }}
        />
      )}
    </React.Fragment>
  )
}

export default CircleShape
