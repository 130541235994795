import React from "react"
import { Rect, Transformer } from "react-konva"

const Rectangle = ({
  shapeProps,
  isSelected,
  stageDimensions,
  onSelect,
  onChange,
  elementId,
}) => {
  const shapeRef = React.useRef()
  const trRef = React.useRef()

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current])
      trRef.current.getLayer().batchDraw()
    }
  }, [isSelected])

  return (
    <React.Fragment>
      <Rect
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        draggable={elementId !== "layerBg" && elementId !== "canvasBorder"}
        onDragEnd={(e) => {
          console.log("rectangle - onDragEnd - e")
          console.log(e)
          console.log("rectangle - onDragEnd - e.target")
          console.log(e.target)
          console.log("rectangle - onDragEnd - e.target.x()")
          console.log(e.target.x())
          console.log("rectangle - onDragEnd - e.target.y()")
          console.log(e.target.y())
          console.log("rectangle - onDragEnd - shapeProps")
          console.log(shapeProps)

          // if (e && e.target && e.target.x() && e.target.y()) {
          if (elementId !== "layerBg" && elementId !== "canvasBorder") {
            onChange({
              ...shapeProps,
              x: e.target.x(),
              y: e.target.y(),
            })
          }
        }}
        onTransformStart={() => {
          const node = shapeRef.current

          console.log("rect transformStart - scaleX")
          console.log(node.scaleX())
          console.log("rect transformStart - scaleY")
          console.log(node.scaleY())
          console.log("rect - transformStart - node.width()")
          console.log(node.width())
          console.log("rect - transformStart - node.height()")
          console.log(node.height())
        }}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current
          // const scaleX = node.scaleX()
          // const scaleY = node.scaleY()
          const origScaleX = node.scaleX()
          const origScaleY = node.scaleY()
          const scaleX = shapeProps.transformed
            ? node.scaleX()
            : node.scaleX() * (1 / stageDimensions.stageScale)
          const scaleY = shapeProps.transformed
            ? node.scaleY()
            : node.scaleY() * (1 / stageDimensions.stageScale)
          console.log("rect - transform - stageDimensions.stageScale")
          console.log(stageDimensions.stageScale)
          console.log("rect - transform - 1/stageDimensions.stageScale")
          console.log(1 / stageDimensions.stageScale)
          console.log("rect - transform - node.scaleX()")
          console.log(node.scaleX())
          console.log("rect - transform - node.scaleY()")
          console.log(node.scaleY())
          console.log("rect - transform - scaleX")
          console.log(scaleX)
          console.log("rect - transform - scaleY")
          console.log(scaleY)
          // we will reset it back
          node.scaleX(1)
          node.scaleY(1)
          // node.scaleX(stageDimensions.stageScale)
          // node.scaleY(stageDimensions.stageScale)

          const transform = node.getAbsoluteTransform()
          const attrs = transform.decompose()
          console.log("<<<<<rect - transform")
          console.log(transform)
          console.log("<<<<<rect - attrs")
          console.log(attrs)

          console.log("Math.max(5, node.width() * scaleX)")
          console.log(Math.max(5, node.width() * scaleX))
          console.log("Math.max(node.height() * scaleY)")
          console.log(Math.max(node.height() * scaleY))

          console.log("rect - transform - node.width()")
          console.log(node.width())
          console.log("rect - transform - node.height()")
          console.log(node.height())

          onChange({
            ...shapeProps,
            ...attrs,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
            rotation: node.rotation(),
            transformed: true,
          })
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox
            }
            return newBox
          }}
        />
      )}
    </React.Fragment>
  )
}

export default Rectangle
