//React
import React from "react"
import { connect } from "react-redux"

import ReactTags from "react-tag-autocomplete"

//Actions
// import { listTags } from "../../actions/manageTags"
import {
  updateFormField,
  updateFormFieldSingle,
} from "../../actions/updateForm"

//Icons
import { FaSpinner } from "react-icons/fa"
import { MdRefresh } from "react-icons/md"

//Redux
const mapStateToProps = ({ forms, templatetags, isLoadingTags }) => {
  return { forms, templatetags, isLoadingTags }
}

const mapDispatchToProps = {
  updateFormField,
  updateFormFieldSingle,
}

const KeyCodes = {
  comma: "188",
  enter: "13",
}

const delimiters = [KeyCodes.comma, KeyCodes.enter]

class App extends React.Component {
  constructor(props) {
    super(props)

    // this.state = {
    //   tags: [{ id: "1", text: "1" }],
    //   suggestions: [{ id: "2", text: "2" }],
    // }
    this.state = {
      tags: [],
      suggestions: [],
    }
    this.handleDelete = this.handleDelete.bind(this)
    this.handleAddition = this.handleAddition.bind(this)
    // this.handleDrag = this.handleDrag.bind(this)
  }

  componentDidMount() {
    const {
      templatetags,
      imagetags,
      // listTags,
      currentTemplateTags,
      currentTags,
    } = this.props
    // listTags()
    console.log("templateTags - componentDidMount - currentTags")
    console.log(currentTags)
    // const tags =
    //   templatetags && templatetags.length > 0
    //     ? templatetags.map((tag) => ({ id: tag.content, text: tag.content }))
    //     : []
    // this.setState({ suggestions: tags })
    // this.setState({ tags: currentTags })
    let newTags =
      currentTags && currentTags.length > 0
        ? [...new Set(currentTags)]
            .filter((tag) => tag && tag !== null && typeof tag !== undefined)
            .map((tag) => {
              return tag
            })
        : []

    console.log("componentDidMount :: newTags")
    console.log(newTags)
    if (newTags && newTags.length > 0) {
      // this.handleAddition(null, newTags)
      // this.setState({ tags: newTags })
      updateFormFieldSingle("workingtags", newTags)
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { templateId, currentTags, updateFormFieldSingle, forms } = this.props
    // console.log("componentDidUpdate :: prevProps")
    // console.log(prevProps)
    // console.log("componentDidUpdate :: templateId")
    // console.log(templateId)
    if (
      prevProps.templateId !== templateId ||
      prevProps?.forms?.edittemplate?.tags !== forms?.edittemplate?.tags ||
      prevProps?.forms?.savetemplate?.tags !== forms?.savetemplate?.tags
    ) {
      // console.log("componentDidUpdate :: EVALUATING NEW TAGS")
      // console.log("componentDidUpdate :: currentTemplateTags")
      // console.log(currentTemplateTags)
      console.log("componentDidUpdate :: currentTags")
      console.log(currentTags)

      // the portion [...new Set(currentTemplateTags)]  filters for unique tags
      let newTags =
        currentTags && currentTags.length > 0
          ? [...new Set(currentTags)]
              .filter((tag) => tag && tag !== null && typeof tag !== undefined)
              .map((tag) => {
                return tag
              })
          : []

      console.log("componentDidUpdate :: newTags")
      console.log(newTags)
      if (newTags && newTags.length > 0) {
        // this.handleAddition(null, newTags)
        // this.setState({ tags: newTags })
        updateFormFieldSingle("workingtags", newTags)
      }
    }
  }

  handleDelete(i) {
    const { tags } = this.state
    const { forms, updateFormFieldSingle } = this.props
    // this.setState({
    //   tags: tags.filter((tag, index) => index !== i),
    // })
    const updatedTags = forms.workingtags.filter((tag, index) => index !== i)

    console.log("handleDelete - updatedTags")
    console.log(updatedTags)

    updateFormFieldSingle("workingtags", updatedTags)
  }

  handleAddition(tag, multipletags) {
    console.log("handleAddition - tag")
    console.log(tag)
    console.log("handleAddition - multipletags")
    console.log(multipletags)
    if (!multipletags && (tag === null || typeof tag == undefined)) {
      return
    }
    let multipleTags = []
    const { tags } = this.state
    console.log("handleAddition - tags - state")
    console.log(tags)
    const {
      forms,
      updateFormField,
      updateFormFieldSingle,
      context,
    } = this.props
    let tagContainer = []
    if (context === "image") {
      tagContainer =
        forms?.saveimage?.tags?.length > 0 ? forms.saveimage.tags : []
      console.log("tagContainer - image")
      console.log(tagContainer)
      if (multipletags) {
        multipleTags = multipletags.map((tag) => tag)
        updateFormField("saveimage", "tags", [...tagContainer, ...multipleTags])
      } else {
        updateFormField("saveimage", "tags", [...tagContainer, tag.name])
      }
    } else if (context === "edittemplate") {
      tagContainer =
        forms?.edittemplate?.tags?.length > 0 ? forms.edittemplate.tags : []
      console.log("tagContainer - edittemplate")
      console.log(tagContainer)
      if (multipletags) {
        multipleTags = multipletags.map((tag) => tag)
        // Including both edit and save template for seamless switching
        updateFormField("edittemplate", "tags", [
          ...tagContainer,
          ...multipleTags,
        ])
        updateFormField("savetemplate", "tags", [
          ...tagContainer,
          ...multipleTags,
        ])
      } else {
        // Including both edit and save template for seamless switching
        updateFormField("edittemplate", "tags", [...tagContainer, tag.name])
        updateFormField("savetemplate", "tags", [...tagContainer, tag.name])
      }
    } else {
      tagContainer =
        forms?.savetemplate?.tags?.length > 0 ? forms.savetemplate.tags : []
      console.log("tagContainer - savetemplate")
      console.log(tagContainer)
      if (multipletags) {
        multipleTags = multipletags.map((tag) => tag)
        // Including both edit and save template for seamless switching
        updateFormField("edittemplate", "tags", [
          ...tagContainer,
          ...multipleTags,
        ])
        updateFormField("savetemplate", "tags", [
          ...tagContainer,
          ...multipleTags,
        ])
      } else {
        // Including both edit and save template for seamless switching
        updateFormField("edittemplate", "tags", [...tagContainer, tag.name])
        updateFormField("savetemplate", "tags", [...tagContainer, tag.name])
      }
    }

    // this.setState((state) => ({ tags: [...state.tags, tag.name] }))
    if (multipletags) {
      updateFormFieldSingle("workingtags", [
        ...forms.workingtags,
        [...tagContainer, ...multipleTags],
      ])
    } else {
      updateFormFieldSingle("workingtags", [...forms.workingtags, tag.name])
    }
  }

  // handleDrag(tag, currPos, newPos) {
  //   const tags = [...this.state.tags]
  //   const newTags = tags.slice()

  //   newTags.splice(currPos, 1)
  //   newTags.splice(newPos, 0, tag)

  //   // re-render
  //   this.setState({ tags: newTags })
  // }

  render() {
    const { tags } = this.state
    const {
      context,
      templatetags,
      imagetags,
      templateId,
      currentTemplateTags,
      // listTags,
      isLoadingTags,
      forms,
    } = this.props
    console.log("!!tags")
    console.log(tags)
    console.log("!!forms")
    console.log(forms)
    // console.log("!!templateTags")
    // console.log(templatetags)
    // console.log("!!imagetags")
    // console.log(imagetags)
    // console.log("!!context")
    // console.log(context)
    // console.log("!!templateId")
    // console.log(templateId)
    // console.log("!!currentTemplateTags")
    // console.log(currentTemplateTags)
    // let suggestions = []
    // if (context && context === "image") {
    //   suggestions =
    //     imagetags && imagetags.length > 0
    //       ? imagetags.map((tag) => ({ id: tag.content, name: tag.content }))
    //       : []
    // } else {
    //   suggestions =
    //     templatetags && templatetags.length > 0
    //       ? templatetags.map((tag) => ({ id: tag.content, name: tag.content }))
    //       : []
    // }

    const workingTagsArr =
      forms && forms.workingtags && forms.workingtags.length > 0
        ? forms.workingtags.map((tag) => ({ name: tag }))
        : []
    console.log("templateTage - workingTagsArr")
    console.log(workingTagsArr)
    return (
      <div class="w-100 d-flex flex-row align-items-center justify-content-center">
        <ReactTags
          tags={workingTagsArr}
          // suggestions={suggestions}
          allowNew={true}
          addOnBlur={true}
          onDelete={this.handleDelete}
          onAddition={this.handleAddition}
          // handleDrag={this.handleDrag}
          // delimiters={delimiters}
        />
        {/* <button
          class={`btn btn-sm ml-1`}
          style={{ margin: 1 }}
          onClick={() => {
            listTags()
          }}
        >
          {isLoadingTags === true ? (
            <FaSpinner size={16} className="icon-spin black-4" />
          ) : (
            <MdRefresh size={20} />
          )}
        </button> */}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
