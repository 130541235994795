import React from "react"
import { connect } from "react-redux"

//Components
import EditorTemplates from "../appPublisher/editorTemplates"

//Redux

//Icons
import { FaTimes } from "react-icons/fa"

const mapDispatchToProps = {
  // listTags: () => dispatch(listTags()),
  // loadNewTemplate: (id, context) => dispatch(loadNewTemplate(id, context)),
}
const Snippet = (props) => {
  const {
    context,
    // loadNewTemplate,
    userRole,
    editTemplate,
    addNewElement,
    setDrawerOpen,
    getViewport,
    uniqueIdentifier,
  } = props
  console.log("editorSidebar - context")
  console.log(context)
  return (
    <div class="editor-sidebar-tabs-container h-100">
      <ul
        class="nav nav-tabs d-flex flex-row"
        id={`editor-sidebar-tabs-${uniqueIdentifier}`}
        role="tablist"
      >
        {/* <li class="nav-item">
          <a
            class="nav-link publisher-tab-title active"
            id="notifications-tab"
            data-toggle="tab"
            href="#notifications"
            role="tab"
            aria-controls="notifications"
            aria-selected="true"
          >
            Feed
          </a>
        </li> */}
        <li class="nav-item">
          <a
            class="nav-link publisher-tab-title active"
            id={`socialdesign-tab-${uniqueIdentifier}`}
            data-toggle="tab"
            href={`#socialdesign-${uniqueIdentifier}`}
            role="tab"
            aria-controls={`socialdesign-${uniqueIdentifier}`}
            aria-selected="true"
          >
            Social <span class="d-none d-xl-inline">Design</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link publisher-tab-title"
            id={`template-tab-${uniqueIdentifier}`}
            data-toggle="tab"
            href={`#template-${uniqueIdentifier}`}
            role="tab"
            aria-controls={`template-${uniqueIdentifier}`}
            aria-selected="false"
          >
            Templates
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link publisher-tab-title"
            id={`elements-tab-${uniqueIdentifier}`}
            data-toggle="tab"
            href={`#elements-${uniqueIdentifier}`}
            role="tab"
            aria-controls={`elements-${uniqueIdentifier}`}
            aria-selected="false"
          >
            Elements
          </a>
        </li>
        {uniqueIdentifier === "1" ? (
          <li class="d-inline-block ml-auto">
            <button
              class="nav-button-close px-2 gray-1"
              onClick={() => {
                setDrawerOpen(false)
              }}
            >
              <FaTimes size={16} />
            </button>
          </li>
        ) : null}
      </ul>
      <div
        class="tab-pane fade show active"
        id={`socialdesign-${uniqueIdentifier}`}
        role="tabpanel"
        aria-labelledby={`socialdesign-tab-${uniqueIdentifier}`}
      >
        <EditorTemplates
          context={context}
          editTemplate={editTemplate}
          addNewElement={addNewElement}
          mode="socialdesign"
          // loadNewTemplate={loadNewTemplate}
          userRole={userRole}
          setDrawerOpen={setDrawerOpen}
          getViewport={getViewport}
          uniqueIdentifier={uniqueIdentifier}
        />
      </div>
      <div
        class="tab-pane fade"
        id={`template-${uniqueIdentifier}`}
        role="tabpanel"
        aria-labelledby={`template-tab-${uniqueIdentifier}`}
      >
        <EditorTemplates
          context={context}
          editTemplate={editTemplate}
          addNewElement={addNewElement}
          mode="template"
          // loadNewTemplate={loadNewTemplate}
          userRole={userRole}
          setDrawerOpen={setDrawerOpen}
          getViewport={getViewport}
          uniqueIdentifier={uniqueIdentifier}
        />
      </div>
      <div
        class="tab-pane fade"
        id={`elements-${uniqueIdentifier}`}
        role="tabpanel"
        aria-labelledby={`elements-tab-${uniqueIdentifier}`}
      >
        <EditorTemplates
          context={context}
          editTemplate={editTemplate}
          addNewElement={addNewElement}
          mode="elements"
          // loadNewTemplate={loadNewTemplate}
          userRole={userRole}
          setDrawerOpen={setDrawerOpen}
          getViewport={getViewport}
          uniqueIdentifier={uniqueIdentifier}
        />
      </div>
    </div>
  )
}
// export default Snippet
export default React.memo(connect(null, mapDispatchToProps)(Snippet))
