import React, { useState } from "react"
import { connect } from "react-redux"

//Actions
import { loadNewTemplate } from "../../actions/createPublisher"
import { createUserFollow } from "../../actions/createUserFollow"
import { socialModalClear } from "../../actions/updateForm"

//Components
import UserProfileModalForm from "./userProfileModalForm"

//Icons

const mapStateToProps = ({
  publisher,
  social,
  isLoadingTemplateCreate,
  userdetail,
}) => {
  return {
    publisher,
    social,
    isLoadingTemplateCreate,
    userdetail,
  }
}
const mapDispatchToProps = {
  socialModalClear,
  loadNewTemplate,
  createUserFollow,
}

const Snippet = (props) => {
  const {
    publisher,
    social,
    userRole,
    loadNewTemplate,
    isLoadingTemplateCreate,
    createUserFollow,
    userdetail,
    socialModalClear,
  } = props

  const publisherMode = publisher && publisher.mode ? publisher.mode : "image"

  return (
    <div
      class="modal modal-sharer modal-user-profile fade"
      id="userProfileModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="userProfileModalLabel"
      aria-hidden="true"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header" style={{ borderBottom: 0 }}>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                socialModalClear()
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="modal-subheader d-flex flex-row align-items-center justify-content-start py-3">
              <h4
                class="text-left pl-4 font-weight-bold white-1"
                style={{ paddingTop: 8, marginRight: 8 }}
              >
                {social?.userdata?.fname} {social?.userdata?.lname}
              </h4>
            </div>

            <div
              class="modal-content-container d-flex flex-column align-items-center justify-content-center"
              style={{ margin: "auto" }}
            >
              <UserProfileModalForm
                context={publisherMode}
                publisher={publisher}
                social={social}
                userRole={userRole}
                loadNewTemplate={loadNewTemplate}
                isLoadingTemplateCreate={isLoadingTemplateCreate}
                createUserFollow={createUserFollow}
                userdetail={userdetail}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// export default Snippet
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
// export default connect(mapStateToProps, null)(Snippet)
