import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"

import { navigate } from "@reach/router"
import { logoutUser } from "./userManagement"
import { unauthorizedMessage } from "../utils/constants"

import { getNotify } from "./getNotify"

export function createNotify(
  content,
  imageid,
  templateid,
  context,
  notifyemail,
  notifysms
) {
  console.log("actions - createNotify - start")

  let cid = Cache.getItem("cid")

  console.log("actions - createNotify - cid")
  console.log(cid)

  let apiName = "Storylavaweb"

  let path = "admin/notify/save"

  return (dispatch) => {
    dispatch({
      type: "LOADING_NOTIFY_CREATE",
    })
    Auth.currentAuthenticatedUser()
      .then((user) => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log(
          "actions - createNotify - Auth.currentAuthenticatedUser - user"
        )
        console.log(user)
        let jwtToken = user.signInUserSession.accessToken.jwtToken

        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            cid,
          },
          body: {
            uid: user.username,
            content,
            imageid,
            templateid,
            context,
            notifyemail,
            notifysms,
          },
        }

        console.log(
          "actions - createNotify - Auth.currentAuthenticatedUser - options"
        )
        console.log(options)

        //begin promise
        new Promise((resolve) => {
          API.post(apiName, path, options)
            .then((response) => {
              const data = response || {}

              let result = data

              console.log("actions - createNotify - API.post - result")
              console.log(result)

              // navigate("/app/sendgroup/")

              $(`#notificationSaveModal`).modal("hide")
              dispatch({
                type: "LOADING_NOTIFY_RESET",
                data: result,
              })
              return resolve(dispatch(getNotify()))
            })
            .catch((error) => {
              if (
                error &&
                error.response &&
                error.response.status &&
                (error.response.status === 401 || error.response.status === 403)
              ) {
                dispatch(logoutUser(unauthorizedMessage))
              }
              console.log("actions - createNotify - catch - error")
              console.log(error)
              console.log("actions - createNotify - catch - error.response")
              console.log(error.response)
            })
        }) //end promise
      })
      .catch((error) => {
        console.log(error)
      }) //end get Auth token
  }
}
export function deleteNotify(id) {
  console.log("actions - deleteNotify - start")

  let cid = Cache.getItem("cid")

  console.log("actions - deleteNotify - cid")
  console.log(cid)

  console.log("actions - deleteNotify - id")
  console.log(id)

  let apiName = "Storylavaweb"

  let path = "admin/notify/delete"

  return (dispatch) => {
    dispatch({
      type: "LOADING_NOTIFY_CREATE",
    })
    Auth.currentAuthenticatedUser()
      .then((user) => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log(
          "actions - createNotify - Auth.currentAuthenticatedUser - user"
        )
        console.log(user)
        let jwtToken = user.signInUserSession.accessToken.jwtToken

        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            cid,
          },
          body: {
            id,
          },
        }

        console.log(
          "actions - deleteNotify - Auth.currentAuthenticatedUser - options"
        )
        console.log(options)

        //begin promise
        new Promise((resolve) => {
          API.post(apiName, path, options)
            .then((response) => {
              const data = response || {}

              let result = data

              console.log("actions - deleteNotify - API.post - result")
              console.log(result)

              // navigate("/app/sendgroup/")

              $(`#notificationDeleteModal`).modal("hide")
              dispatch({
                type: "LOADING_NOTIFY_RESET",
                data: result,
              })
              return resolve(dispatch(getNotify()))
            })
            .catch((error) => {
              console.log(error.response)
              if (
                error &&
                error.response &&
                error.response.status &&
                (error.response.status === 401 || error.response.status === 403)
              ) {
                dispatch(logoutUser(unauthorizedMessage))
              }
            })
        }) //end promise
      })
      .catch((error) => {
        console.log(error)
      }) //end get Auth token
  }
}
