//React
import React from "react"

//Gatsby
import { StaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

//Icons
import { MdOpenInNew } from "react-icons/md"

const Snippet = (props) => {
  const { loadSocialKeywordTemplates, updateFormField } = props

  return (
    <StaticQuery
      query={graphql`
        query {
          homebg: file(
            relativePath: { eq: "stock/unsplash/arty-n_GGaQHqT4Q-unsplash.jpg" }
          ) {
            childImageSharp {
              fluid(
                quality: 100
                maxWidth: 400
                maxHeight: 600
                cropFocus: CENTER
              ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => (
        <>
          <div class="mt-3 notification-none white-1">
            <p class="lead line-height-3">
              Get started by searching for templates and following designers
            </p>
            <p class="font-weight-light">
              Try{" "}
              <a
                onClick={() => {
                  loadSocialKeywordTemplates("real estate")
                  updateFormField("social", "searchkeyword", "real estate")
                }}
              >
                real estate
                <MdOpenInNew size={15} className="search" />
              </a>{" "}
              <a
                onClick={() => {
                  loadSocialKeywordTemplates("gym")
                  updateFormField("social", "searchkeyword", "gym")
                }}
              >
                gyms
                <MdOpenInNew size={15} className="search" />
              </a>{" "}
              or{" "}
              <a
                onClick={() => {
                  loadSocialKeywordTemplates("direct selling")
                  updateFormField("social", "searchkeyword", "direct selling")
                }}
              >
                direct selling
                <MdOpenInNew size={15} className="search" />
              </a>
            </p>{" "}
            <hr size={1} />
            <p class="font-weight-light">
              <a
                href={`https://${process.env.HOSTNAME_SUPPORT}`}
                target="_blank"
              >
                Learn how to use Storylava
                <MdOpenInNew size={15} className="search" />
              </a>
            </p>
          </div>
          <div class="overlay callout-getting-started round-corners-top"></div>

          <Img
            css={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: "100%",
              height: "100%",
              zIndex: "0",
            }}
            className="round-corners-top"
            style={{ position: `absolute`, marginTop: 46 }}
            fluid={data.homebg.childImageSharp.fluid}
          />
        </>
      )}
    />
  )
}

export default Snippet
