import React, { useEffect, useState } from "react"

//Packages
import { AsYouType } from "libphonenumber-js/max"

//Components
import Loading from "../loading"

//Icons
import { FaRegCheckCircle } from "react-icons/fa"

const Snippet = (props) => {
  const {
    forms,
    userdetailemail,
    updateFormField,
    updateUserProfile,
    updateEmailVerifyField,
    emailVerifyMode,
    verifyUserEmail,
    isLoadingNotifyCreate,
    isProfileUpdateComplete,
    updateEmailVerifyErrors,
    context,
    deleteAccount,
  } = props

  console.log("****emailVerifyMode")
  console.log(emailVerifyMode)
  console.log("****context")
  console.log(context)
  // console.log("****userSub")
  // console.log(userSub)
  // console.log("****props.publisher")
  // console.log(props.publisher)

  const formatMobile = (val) => {
    const asYouType = new AsYouType("US")
    let mobileObj = {
      format: null,
      provided: null,
      valid: null,
    }
    const phonePartial = asYouType.input(val)
    console.log("formatMobile - phonePartial ")
    console.log(phonePartial)
    const phoneNumber = asYouType.getNumber()
    if (phoneNumber) {
      console.log("formatMobile - phoneNumber ")
      console.log(phoneNumber)
      mobileObj = {
        format: phoneNumber.format("E.164"),
        provided: phoneNumber.format("NATIONAL"),
        valid: phoneNumber.isValid(),
      }
    } else {
      mobileObj = {
        format: null,
        provided: phonePartial,
        valid: false,
      }
    }
    updateFormField("userdetailemail", "mobile", mobileObj)
  }
  let objectNoun
  switch (context) {
    case "verify":
      objectNoun = "email address"
      break
    case "verifymobile":
      objectNoun = "mobile number"
      break
    case "verifydisplay":
      objectNoun = "display URL"
      break
    case "closeaccount":
      objectNoun = "account"
      break
    default:
      objectNoun = ""
  }

  return (
    <div class="reporting-container w-100 p-4">
      {emailVerifyMode === "SUCCESS" ? (
        <div class="alert alert-success">
          Success, we've updated your {objectNoun}.
        </div>
      ) : null}
      {forms.userdetailemail.error ? (
        <div class="alert alert-danger">{forms.userdetailemail.error}</div>
      ) : null}

      {emailVerifyMode === true && emailVerifyMode !== "SUCCESS" ? (
        <>
          <div class="alert alert-info">
            We've sent a verification code to the {objectNoun} you entered.
            Please enter it here.
          </div>
          <div class="form-group row d-flex flex-row align-items-center justify-content-start">
            <label
              for="verification-code"
              class="col-sm-2 col-form-label font-weight-light line-height-1"
            >
              Verification code
            </label>
            <div class="col-sm-10">
              <div class="d-flex flex-row align-items-center justify-content-start">
                <input
                  type="number"
                  id="verification-code"
                  class="form-control font-weight-light"
                  style={{ fontSize: 16 }}
                  placeholder="Enter code"
                  onChange={(e) =>
                    updateFormField(
                      "userdetailemail",
                      "verifycode",
                      e.target.value
                    )
                  }
                  value={forms.userdetailemail.verifycode}
                />
              </div>
            </div>
          </div>
        </>
      ) : null}

      {emailVerifyMode === false ? (
        <div class="form-group row d-flex flex-row align-items-start justify-content-start">
          <label
            for="email-address"
            class="col-sm-2 col-form-label font-weight-light line-height-1"
          >
            {objectNoun.charAt(0).toUpperCase() + objectNoun.slice(1)}
          </label>
          <div class="col-sm-10">
            <div class="d-flex flex-column align-items-center justify-content-start">
              {context === "verify" ? (
                <input
                  class="form-control font-weight-light"
                  style={{ fontSize: 16 }}
                  placeholder="Enter email"
                  onChange={(e) => {
                    updateFormField("userdetailemail", "email", e.target.value)
                  }}
                  value={forms.userdetailemail.email}
                />
              ) : null}
              {context === "verifymobile" ? (
                <>
                  <input
                    class="form-control font-weight-light"
                    style={{ fontSize: 16 }}
                    placeholder="Enter mobile number"
                    onChange={(e) => {
                      formatMobile(e.target.value)
                      // updateFormField("userdetailemail", "mobile", e.target.value)
                    }}
                    value={
                      forms.userdetailemail.mobile &&
                      forms.userdetailemail.mobile.provided
                        ? forms.userdetailemail.mobile.provided
                        : ""
                    }
                  />
                  <div class="small p-1 mt-1 line-height-1 black-5">
                    By providing your number you consent to receive these
                    messages. Text STOP at any time to opt-out. Message &amp;
                    data rates may apply.
                  </div>
                </>
              ) : null}
              {context === "verifydisplay" ? (
                <div class="d-flex flex-row align-items-center justify-content-start font-weight-light">
                  <div class="mr-2 gray-1">
                    {process.env.HOSTNAME_MKT}/creative/
                  </div>
                  <input
                    class="form-control font-weight-light"
                    style={{ fontSize: 16 }}
                    placeholder="Enter display name"
                    onChange={(e) => {
                      updateFormField(
                        "userdetailemail",
                        "displayname",
                        e.target.value
                      )
                    }}
                    value={forms.userdetailemail.displayname}
                  />
                </div>
              ) : null}
              {context === "closeaccount" ? (
                <div class="p-1 mt-1 line-height-1 black-5">
                  Closing your account will delete all your data and sign you
                  out.
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}

      <div class="d-flex flex-row align-items-center justify-content-center">
        {emailVerifyMode !== "SUCCESS" ? (
          <button
            class="btn btn-link"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              updateEmailVerifyField(false)
            }}
          >
            Cancel
          </button>
        ) : null}

        {emailVerifyMode === true && emailVerifyMode !== "SUCCESS" ? (
          <button
            class="btn btn-primary"
            onClick={() => {
              if (context === "verify") {
                verifyUserEmail("email", forms.userdetailemail.verifycode)
              } else if (context === "verifymobile") {
                verifyUserEmail(
                  "phone_number",
                  forms.userdetailemail.verifycode
                )
              }
              // updateEmailVerifyField(false)
            }}
          >
            Verify {objectNoun}
          </button>
        ) : null}

        {emailVerifyMode === false && context === "verify" ? (
          <button
            class="btn btn-primary"
            onClick={() => {
              if (!forms.userdetailemail.email || !userdetailemail.email) {
                updateEmailVerifyErrors(`Please enter a ${objectNoun}`)
              } else if (
                forms.userdetailemail.email !== userdetailemail.email
              ) {
                updateUserProfile(forms.userdetailemail, userdetailemail)
                updateEmailVerifyField(true)
              } else {
                updateEmailVerifyErrors(
                  `Your ${objectNoun} was the same as before, so we didn't update anything`
                )
              }
            }}
          >
            Next &rsaquo;
          </button>
        ) : null}
        {emailVerifyMode === false && context === "verifymobile" ? (
          <button
            class="btn btn-primary"
            onClick={() => {
              if (!forms.userdetailemail.mobile) {
                updateEmailVerifyErrors(`Please enter a ${objectNoun}`)
              } else if (forms.userdetailemail.valid === false) {
                updateEmailVerifyErrors(
                  `The ${objectNoun} you provided is not valid`
                )
              } else if (
                forms.userdetailemail.mobile &&
                forms.userdetailemail.mobile.format &&
                (userdetailemail.hasOwnProperty("mobile") === false ||
                  forms.userdetailemail.mobile.format !==
                    userdetailemail.mobile.format)
              ) {
                updateUserProfile(forms.userdetailemail, userdetailemail)
                updateEmailVerifyField(true)
              } else {
                updateEmailVerifyErrors(
                  `Your ${objectNoun} was the same as before, so we didn't update anything`
                )
              }
            }}
          >
            Next &rsaquo;
          </button>
        ) : null}
        {emailVerifyMode === false && context === "verifydisplay" ? (
          <button
            class="btn btn-primary"
            onClick={() => {
              if (!forms.userdetailemail.displayname) {
                updateEmailVerifyErrors(`Please enter a ${objectNoun}`)
              } else if (
                forms.userdetailemail.displayname !==
                userdetailemail.displayname
              ) {
                updateUserProfile(forms.userdetailemail, userdetailemail)
                // updateEmailVerifyField(true)
              } else {
                updateEmailVerifyErrors(
                  `Your ${objectNoun} was the same as before, so we didn't update anything`
                )
              }
            }}
          >
            Save
          </button>
        ) : null}
        {emailVerifyMode === false && context === "closeaccount" ? (
          <div class="rowd-flex flex-row align-items-center justify-content-center">
            <button
              class="btn btn-danger"
              onClick={() => {
                deleteAccount()
              }}
            >
              Close account
            </button>
          </div>
        ) : null}
        {isLoadingNotifyCreate ? <Loading class="small ml-3" /> : null}
        {isProfileUpdateComplete ? (
          <div class="d-flex flex-row align-items-center ml-3">
            <FaRegCheckCircle class="dkgreen-1" size={22} />
            <div class="dkgreen-1 pl-1 font-weight-light">Profile updated</div>
          </div>
        ) : null}
      </div>
    </div>
  )
}
export default Snippet
