//React
import React from "react"

//Gatsby

//Actions

//Components
//Icons
import {
  MdAdd,
  MdLayers,
  MdPhotoSizeSelectLarge,
  MdTextFormat,
  MdCropFree,
  MdImage,
  MdPhotoLibrary,
  MdPortrait,
} from "react-icons/md"
import { IoShapes } from "react-icons/io5"
import { BiPointer } from "react-icons/bi"

//Exceptions

//Redux

const Snippet = (props) => {
  // const { saveImage, saveImageCloud, saveTemplate, publisherMode } = props
  const { publisher, updateActiveToolField, activeTool, deselectStage } = props

  const selectedElementShape =
    publisher && publisher.elementSelected && publisher.elementSelected.shape
      ? publisher.elementSelected.shape
      : null

  let activeToolLabel
  switch (activeTool) {
    case "canvas":
      activeToolLabel = "Add element"
      break
    case "layers":
      activeToolLabel = "Layers"
      break
    case "stage":
      activeToolLabel = "Canvas size"
      break
    case "shape":
      activeToolLabel = "Shape attributes"
      break
    case "font":
      activeToolLabel = "Text attributes"
      break
    case "imageprop":
      activeToolLabel = "Image attributes"
      break
    case "imagebrand":
      activeToolLabel = "Brand library"
      break
    case "imageuser":
      activeToolLabel = "User library"
      break
    default:
      activeToolLabel = "Add element"
  }

  return (
    <div class="d-flex flex-column  align-items-center justify-content-center">
      <div class="d-flex flex-row flex-wrap p-1 align-items-center justify-content-center">
        <button
          class={`btn btn-sm btn-primary-outline btn-tool`}
          style={{ margin: 1 }}
        >
          <BiPointer
            size={18}
            class="white-1"
            onClick={() => {
              deselectStage()
            }}
          />
        </button>
        {!selectedElementShape ? (
          <>
            <button
              class={`btn btn-sm btn-primary-outline ${
                activeTool === "canvas"
                  ? "btn-tool btn-tool-active"
                  : "btn-tool"
              }`}
              style={{ margin: 1 }}
            >
              <MdAdd
                size={18}
                class="white-1"
                onClick={() => updateActiveToolField("canvas")}
              />
            </button>

            <button
              class={`btn btn-sm btn-primary-outline ${
                activeTool === "layers"
                  ? "btn-tool btn-tool-active"
                  : "btn-tool"
              }`}
              style={{ margin: 1 }}
            >
              <MdLayers
                size={18}
                class="white-1"
                onClick={() => updateActiveToolField("layers")}
              />
            </button>

            <button
              class={`btn btn-sm btn-primary-outline ${
                activeTool === "stage" ? "btn-tool btn-tool-active" : "btn-tool"
              }`}
              style={{ margin: 1 }}
            >
              <MdPhotoSizeSelectLarge
                size={18}
                class="white-1"
                onClick={() => updateActiveToolField("stage")}
              />
            </button>
          </>
        ) : null}

        {selectedElementShape === "rect" ||
        selectedElementShape === "circle" ? (
          <button
            class={`btn btn-sm btn-primary-outline ${
              activeTool === "shape" ? "btn-tool btn-tool-active" : "btn-tool"
            }`}
            style={{ margin: 1 }}
          >
            <IoShapes
              size={18}
              class="white-1"
              onClick={() => updateActiveToolField("shape")}
            />
          </button>
        ) : null}
        {selectedElementShape === "text" ? (
          <button
            class={`btn btn-sm btn-primary-outline ${
              activeTool === "font" ? "btn-tool btn-tool-active" : "btn-tool"
            }`}
            style={{ margin: 1 }}
          >
            <MdTextFormat
              size={18}
              class="white-1"
              onClick={() => updateActiveToolField("font")}
            />
          </button>
        ) : null}

        {selectedElementShape === "image" ? (
          <>
            <button
              class={`btn btn-sm btn-primary-outline ${
                activeTool === "imageprop"
                  ? "btn-tool btn-tool-active"
                  : "btn-tool"
              }`}
              style={{ margin: 1 }}
            >
              <MdCropFree
                size={18}
                class="white-1"
                onClick={() => updateActiveToolField("imageprop")}
              />
            </button>
            <button
              class={`btn btn-sm btn-primary-outline ${
                activeTool === "imagebrand"
                  ? "btn-tool btn-tool-active"
                  : "btn-tool"
              }`}
              style={{ margin: 1 }}
            >
              <MdImage
                size={18}
                class="white-1"
                onClick={() => updateActiveToolField("imagebrand")}
              />
            </button>
            <button
              class={`btn btn-sm btn-primary-outline ${
                activeTool === "imageuser"
                  ? "btn-tool btn-tool-active"
                  : "btn-tool"
              }`}
              style={{ margin: 1 }}
            >
              <MdPortrait
                size={18}
                class="white-1"
                onClick={() => updateActiveToolField("imageuser")}
              />
            </button>
          </>
        ) : null}
      </div>
      <div class="text-center line-height-2 white-5 p-1 mb-2 small">
        {activeToolLabel}
      </div>
    </div>
  )
}

export default React.memo(Snippet)
