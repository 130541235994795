//React
import React from "react"
import { connect } from "react-redux"

//Gatsby
import { Link } from "gatsby"

//Packages
import parse from "url-parse"

//Actions
import { getCompanyDetail } from "../../actions/userManagement"
import { createUserGroup } from "../../actions/createUserGroup"

//Components
import Loading from "../loading"
import AppUserGroupUserForm from "./appUserGroupUserForm"
import AppUserGroupBaseInfo from "./appUserGroupBaseInfo"

//Icons
import { FaSpinner, FaCheck } from "react-icons/fa"

//Redux
const mapStateToProps = ({
  company,
  sendgroup,
  sendgroupcreate,
  userGroupUpdateComplete,
  isLoadingUserGroup,
  isLoading,
}) => {
  return {
    company,
    sendgroup,
    sendgroupcreate,
    userGroupUpdateComplete,
    isLoadingUserGroup,
    isLoading,
  }
}

const mapDispatchToProps = {
  getCompanyDetail,
  createUserGroup,
}

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showErrorLink: false }
  }
  componentDidMount() {
    const { sendgroup } = this.props

    const url = parse(window.location.href, true)

    console.log("appSendGroupCreate - url")
    console.log(url)
    this.props.getCompanyDetail(null, "usersinitial")
  }

  render() {
    const {
      company,
      sendgroup,
      sendgroupcreate,
      isLoading,
      userGroupUpdateComplete,
      isLoadingUserGroup,
      createUserGroup,
    } = this.props
    const { templateid } = this.state

    return (
      <div class="app-usergroup-container">
        {this.state.showErrorLink ? (
          <div class="d-flex flex-column">
            <div class="p-4">
              We weren't able to load the list of send groups. Tap the link
              below to reload active send groups.
            </div>
            <Link class="btn btn-grayblue btn-cta" to="/app/sendgroup/">
              Reload
            </Link>
          </div>
        ) : (
          <>
            {userGroupUpdateComplete ? (
              <div class="alert alert-success fade-in" role="alert">
                <div class="d-flex flex-row align-items-center justify-content-start ">
                  <FaCheck size={18} class="dkgreen-1 mr-2" /> Update successful
                </div>
              </div>
            ) : null}
            <form class="form-sendgroup-group p-2 needs-validation" novalidate>
              <AppUserGroupBaseInfo />
              <AppUserGroupUserForm />
              <div class="mt-5 py-3 border-top-1-gray-6 d-flex flex-row align-items-center justify-content-end">
                <button
                  type="button"
                  class="btn btn-dkblue mx-2 d-flex flex-row align-items-center justify-content-end"
                  onClick={(e) => {
                    createUserGroup(company.users)
                  }}
                >
                  Save
                  {isLoadingUserGroup ? (
                    <div className="pl-2">
                      <FaSpinner size={16} className="icon-spin" />
                    </div>
                  ) : null}
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    )
  }
}

// export default Snippet
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
