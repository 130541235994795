//React
import React from "react"

//Packages

//Icons
import { MdDeleteForever } from "react-icons/md"

const Snippet = ({ removeSelectedElement }) => {
  return (
    <>
      <div
        class="border-right-1-gray-5 ml-2 mr-2 d-none d-md-block"
        style={{ width: 1, height: 20, marginTop: 4 }}
      ></div>
      {/* <div class="flex-break"></div> */}

      <div class="delete-element-container">
        <button
          class="btn btn-slim btn-tool-add p-1"
          type="button"
          onClick={() => {
            removeSelectedElement()
          }}
        >
          <MdDeleteForever size={22} />
        </button>
      </div>
    </>
  )
}

export default Snippet
