//React
import React from "react"
import { connect } from "react-redux"

//Gatsby
import { Link } from "gatsby"

//Packages
import {
  AsYouType,
  parsePhoneNumber,
  parsePhoneNumberFromString,
  ParseError,
} from "libphonenumber-js/max"
import Auth from "@aws-amplify/auth"

//Actions
import { updateUserGroupField } from "../../actions/createUserGroup"
import { updateFormField } from "../../actions/updateForm"

//Components
import Loading from "../loading"

//Icons
import { FaPlus, FaTimes } from "react-icons/fa"
import { MdUndo } from "react-icons/md"

//Redux
const mapStateToProps = ({ company, forms, sendgroupcreate }) => {
  return { company, forms, sendgroupcreate }
}

const mapDispatchToProps = {
  updateFormField,
  updateUserGroupField,
}

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = { usersub: "" }
  }
  componentDidMount() {
    Auth.currentAuthenticatedUser().then((user) => {
      this.setState({ usersub: user.username })
    })
  }

  render() {
    const {
      company,
      sendgroupcreate,
      updateFormField,
      updateUserGroupField,
    } = this.props

    console.log("appUserGroupUserForm - company")
    console.log(company)

    let sendGroupUsersLengthError =
      sendgroupcreate &&
      sendgroupcreate.error &&
      sendgroupcreate.error.sendgroupuserslength
        ? true
        : false
    let sendGroupUsersError =
      sendgroupcreate &&
      sendgroupcreate.error &&
      sendgroupcreate.error.sendgroupuserserror
        ? true
        : false

    return (
      <>
        <table class="table table-striped table-sendgroup">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">First name</th>
              <th scope="col">Last name</th>
              <th scope="col">Email</th>
              {/* <th scope="col" class="header-day">
                Su
              </th>
              <th scope="col" class="header-day">
                M
              </th>
              <th scope="col" class="header-day">
                Tu
              </th>
              <th scope="col" class="header-day">
                W
              </th>
              <th scope="col" class="header-day">
                Th
              </th>
              <th scope="col" class="header-day">
                F
              </th>
              <th scope="col" class="header-day">
                Sa
              </th> */}
            </tr>
          </thead>
          <tbody>
            {company && company.users ? (
              company.users.map((user, i) => (
                <tr
                  key={`sendgroup-row-${i}`}
                  class={user.delete ? "bg-red-5" : ""}
                >
                  <td>
                    {user && user.id && user.id !== this.state.usersub ? (
                      user.delete ? (
                        <button
                          type="button"
                          class="btn btn-sm"
                          onClick={(event) => {
                            updateUserGroupField("usersdeleteundo", i)
                          }}
                        >
                          <MdUndo class="red-1" size={13} />
                        </button>
                      ) : (
                        <button
                          type="button"
                          class="btn btn-sm"
                          onClick={(event) => {
                            updateUserGroupField("usersdelete", i)
                          }}
                        >
                          <FaTimes class="dkorange-1" size={11} />
                        </button>
                      )
                    ) : null}
                  </td>
                  <td>
                    <input
                      class={`form-check-input-option ${
                        user.delete ? "bg-red-5" : ""
                      }`}
                      // class={`form-check-input-option ${
                      //   sendgroupcreate.error.sendgroupusersitems &&
                      //   sendgroupcreate.error.sendgroupusersitems[i] &&
                      //   sendgroupcreate.error.sendgroupusersitems[i].choices &&
                      //   sendgroupcreate.error.sendgroupusersitems[i].choices[j] &&
                      //   sendgroupcreate.error.sendgroupusersitems[i].choices[j].title
                      //     ? "is-invalid"
                      //     : ""
                      // }`}
                      disabled={
                        user.delete
                          ? true
                          : user && user.id && user.id === this.state.usersub
                          ? true
                          : false
                      }
                      type="text"
                      name="gridRadiosOption"
                      placeholder="First name"
                      id={`form-sendgroup-input-fname-${i}`}
                      value={company.users[i].fname}
                      // mode, formoption, marker, pos, val, prop
                      // mode, formoption, marker, pos, val, prop
                      onChange={(event) => {
                        updateUserGroupField(
                          "usersmodify",
                          i,
                          event.target.value
                        )
                      }}
                    />
                    {user.delete ? (
                      <div class="red-1 small line-height-1">
                        Marked for deletion
                      </div>
                    ) : null}

                    {user && user.id && user.id === this.state.usersub ? (
                      <div class="black-4 small line-height-1">
                        To edit your information, go to{" "}
                        <Link to="/app/profile/">your profile</Link>
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      class={`form-check-input-option ${
                        user.delete ? "bg-red-5" : ""
                      }`}
                      // class={`form-check-input-option ${
                      //   sendgroupcreate.error.sendgroupusersitems &&
                      //   sendgroupcreate.error.sendgroupusersitems[i] &&
                      //   sendgroupcreate.error.sendgroupusersitems[i].choices &&
                      //   sendgroupcreate.error.sendgroupusersitems[i].choices[j] &&
                      //   sendgroupcreate.error.sendgroupusersitems[i].choices[j].title
                      //     ? "is-invalid"
                      //     : ""
                      // }`}
                      disabled={
                        user.delete
                          ? true
                          : user && user.id && user.id === this.state.usersub
                          ? true
                          : false
                      }
                      type="text"
                      name="gridRadiosOption"
                      placeholder="Last name"
                      id={`form-sendgroup-input-lname-${i}`}
                      value={company.users[i].lname}
                      onChange={(event) => {
                        updateUserGroupField(
                          "usersmodify",
                          i,
                          null,
                          event.target.value
                        )
                      }}
                    />
                  </td>
                  <td>
                    <input
                      class={`form-check-input-option form-sendgroup-input-mobile ${
                        user.delete ? "bg-red-5" : ""
                      } ${
                        sendgroupcreate.error.sendgroupusersitems &&
                        sendgroupcreate.error.sendgroupusersitems[i] &&
                        sendgroupcreate.error.sendgroupusersitems[i].mobile &&
                        (sendgroupcreate.error.sendgroupusersitems[i].mobile
                          .format ||
                          sendgroupcreate.error.sendgroupusersitems[i].mobile
                            .valid ||
                          sendgroupcreate.error.sendgroupusersitems[i].mobile
                            .duplicate)
                          ? "is-invalid no-bg"
                          : ""
                      }`}
                      disabled={
                        user.delete
                          ? true
                          : user && user.id && user.id === this.state.usersub
                          ? true
                          : false
                      }
                      type="text"
                      name="gridRadiosOption"
                      placeholder="Email"
                      id={`form-sendgroup-input-mobile-${i}`}
                      value={company.users[i].email}
                      onChange={(event) => {
                        updateUserGroupField(
                          "usersmodifyemail",
                          i,
                          null,
                          null,
                          event.target.value
                        )
                      }}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <td colspan="4">
                <div class="d-flex align-items-center justify-content-center w-100 p-4">
                  <Loading class="small" />
                </div>
              </td>
            )}
          </tbody>
        </table>
        {sendGroupUsersLengthError ? (
          <div class="invalid-feedback p-2">
            {sendgroupcreate.error.sendgroupuserslength}
          </div>
        ) : null}
        {sendGroupUsersError ? (
          <div class="invalid-feedback p-2">
            {sendgroupcreate.error.sendgroupuserserror}
          </div>
        ) : null}
        <button
          type="button"
          class="btn btn-sm btn-outline-success  mt-1"
          // mode, formoption, marker, pos, val, prop
          onClick={(event) => {
            updateUserGroupField("usersadd")
          }}
        >
          <FaPlus class="dkgreen-1 mr-1" size={12} />
          Add user
        </button>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
