import React, { useEffect, useState } from "react"

//Components
import MediaSharer from "./mediaSharer"
import MediaInstructions from "./mediaInstructions"
import MediaVideo from "./mediaVideo"
import MediaImage from "./mediaImage"
import Loading from "../loading"

//Packages
const agentParser = require("ua-parser-js")

const Snippet = (props) => {
  const {
    publisher,
    share,
    updateShareField,
    webHostedImage,
    directImageSrc,
    directImageRatio,
    directImagePreview,
    viewportSize,
    imageexport,
    imageuploadprogress,
    userPlan,
  } = props
  // const [userSub, setUserSub] = useState(null)
  const [currentOS, setCurrentOS] = React.useState("")

  useEffect(() => {
    // Auth.currentAuthenticatedUser().then((user) => {
    //   setUserSub(user.username)
    // })
    const agentObj = agentParser(window.navigator.userAgent)
    console.log("** agentObj")
    console.log(agentObj)
    setCurrentOS(agentObj.os.name)
  }, [])

  // console.log("****share")
  // console.log(share)

  const updateShareIntent = (platform) => {
    updateShareField("intent", platform)
  }

  // const imageSrc = `https://${process.env.S3BUCKETMEDIA}.s3.amazonaws.com/public/${userSub}/${props.socialmodal}.png`
  // const twitterImageSrc = `https://${process.env.HOSTNAME_SLVA}/media/${userSub}/${props.socialmodal}.png`

  const publisherMode = publisher && publisher.mode ? publisher.mode : "image"

  // console.log("++++++publisherMode")
  // console.log(publisherMode)
  // console.log("mediaContainer - imageuploadprogress")
  // console.log(imageuploadprogress)
  return (
    <div class="reporting-container">
      <div class="row">
        <div class="col-sm-12 d-flex flex-column align-items-center justify-content-center">
          {props.isLoadingMediaPreview ? (
            <div class="py-4 w-100">
              <div class="w-100 d-flex flex-column align-items-center justify-content-center">
                {imageuploadprogress &&
                imageuploadprogress[0] &&
                imageuploadprogress[0] > 0 &&
                imageuploadprogress[0] !== 100 ? (
                  <>
                    <div class="progress w-100">
                      <div
                        class="progress-bar progress-bar-striped"
                        role="progressbar"
                        style={{
                          width: `${imageuploadprogress[0]}%`,
                        }}
                        aria-valuenow={`${imageuploadprogress[0]}`}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <div class="media-waiting small mt-2">
                      Preparing media...
                    </div>
                  </>
                ) : (
                  <>
                    <Loading class="small" />
                    <div class="media-waiting small mt-2">
                      Preparing media...
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : publisherMode === "video" ? (
            <MediaVideo socialmodal={props.socialmodal} />
          ) : (
            <MediaImage
              imageSrc={directImageSrc}
              directImageRatio={directImageRatio}
              directImagePreview={directImagePreview}
              viewportSize={viewportSize}
            />
          )}

          {!props.isLoadingMediaPreview ? (
            <>
              <MediaSharer
                imageSrc={directImageSrc}
                webHostedImage={webHostedImage}
                imageexport={imageexport}
                updateShareIntent={updateShareIntent}
                userPlan={userPlan}
              />
              <MediaInstructions
                imageSrc={directImageSrc}
                currentOS={currentOS}
                share={share}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}
export default Snippet
