import React, { useRef, useEffect, useState } from "react"

//Packages

//Components
import TemplateTags from "./templateTags"
import Loading from "../loading"

//Icons
import { FaRegCheckCircle, FaTimes } from "react-icons/fa"

const Snippet = (props) => {
  const {
    context,
    changeAttr,
    forms,
    imageuploadprogress,
    isLoadingImageUpload,
    sendUpload,
    resetImageUpload,
    setActiveTab,
    updateFormsField,
    loadImageLibrary,
    imagelibrary,
  } = props
  const [previewImage, setPreviewImage] = useState([])
  const [localLoading, setLocalLoading] = useState(false)
  const [toolsReadyUploadHiding, setToolsReadyUploadHiding] = useState(false)
  const [imageLocalError, setImageLocalError] = useState(false)

  const fileRef = React.useRef()

  const imageTags =
    forms &&
    forms.saveimage &&
    forms.saveimage.tags &&
    forms.saveimage.tags.length > 0
      ? forms.saveimage.tags
      : []

  const imageProgressFinished = Object.keys(imageuploadprogress).map(
    (key) => imageuploadprogress[key]
  )
  // console.log("((((((((((imageuploadprogress")
  // console.log(imageuploadprogress)
  // console.log("((((((((((imageProgressFinished")
  // console.log(imageProgressFinished)
  // console.log("((((((((((isLoadingImageUpload")
  // console.log(isLoadingImageUpload)

  const isUploadFinished =
    isLoadingImageUpload === false &&
    imageProgressFinished.length > 0 &&
    imageProgressFinished.length === previewImage.length &&
    imageProgressFinished.every(
      (completeValue) =>
        completeValue.progress && completeValue.progress === 101
    )
  // console.log("((((((((((isUploadFinished")
  // console.log(isUploadFinished)

  useEffect(() => {
    //like componentDidMount
    // console.log("** componentDidMount check")
    if (Object.keys(imageuploadprogress).length === 0) {
      setPreviewImage([])
    }
  }, [imageuploadprogress])

  useEffect(() => {
    //like componentDidMount
    // console.log("** isUploadFinished CHECKING")

    if (isUploadFinished === true) {
      // console.log("** isUploadFinished TRUE ** loading images again")
      loadImageLibrary(imagelibrary)
    }
  }, [isUploadFinished])

  // console.log("/// check isReadyToUpload")
  // console.log("previewImage")
  // console.log(previewImage)
  // console.log("------isUploadFinished------")
  // console.log(isUploadFinished)
  // console.log("imageuploadprogress")
  // console.log(imageuploadprogress)

  const resetLocalImage = (tabLocation) => {
    if (tabLocation) {
      setActiveTab(tabLocation)
    } else {
      setActiveTab("upload")
    }
    setPreviewImage([])
    updateFormsField("previewImageLoaded", false)
    resetImageUpload()
  }
  const isReadyToUpload =
    previewImage &&
    previewImage.length > 0 &&
    !isUploadFinished &&
    Object.keys(imageuploadprogress).length === 0

  return (
    <>
      <div class="d-flex flex-row align-items-start justify-content-start">
        <div class="d-flex flex-column align-items-start justify-content-start w-100">
          {imageLocalError ? (
            <div class="red-1 bg-red-6 round-corners small py-2 w-100 text-center mb-2">
              {imageLocalError}
            </div>
          ) : null}
          <div
            class="d-flex flex-row align-items-center justify-content-center w-100"
            style={{ minHeight: 40 }}
          >
            <div>
              <input
                type="file"
                id="file"
                name="file"
                ref={fileRef}
                multiple={true}
                onChange={(e) => {
                  // sendUpload(e, "user") //or 'brand'
                  console.log("----e.target.files----")
                  console.log(e.target.files)
                  console.log("----e.target.files.length----")
                  console.log(e.target.files.length)
                  const fileList = [...e.target.files]
                  console.log("fileList")
                  console.log(fileList)

                  const fileTypeCheck = fileList.every(
                    (file) =>
                      file.type === "image/png" ||
                      file.type === "image/jpeg" ||
                      file.type === "image/jpg" ||
                      file.type === "image/gif"
                  )
                  console.log("[[[[[fileTypeCheck]]]]]")
                  console.log(fileTypeCheck)

                  if (e.target.files.length > 10) {
                    setImageLocalError("Please upload 10 images or fewer")
                    resetLocalImage()
                  } else if (fileTypeCheck === false) {
                    setImageLocalError(
                      "Please make sure your images are PNG, JPG or GIF file types"
                    )
                    resetLocalImage()
                  } else if (fileList.some((file) => file.size > 6000000)) {
                    setImageLocalError(
                      "One of your images was greater than 6MB, please try again"
                    )
                    resetLocalImage()
                  } else {
                    setImageLocalError(null)

                    setLocalLoading(true)

                    let itemsProcessed = 0
                    let newPreviewImage = [...previewImage]

                    fileList.forEach((file, i, fileArray) => {
                      let reader = new FileReader()

                      reader.readAsDataURL(file) // converts the file to base64 and calls onload

                      reader.onload = function () {
                        itemsProcessed++
                        newPreviewImage.push({ preview: reader.result })
                        if (itemsProcessed === fileArray.length) {
                          updateFormsField("previewImageLoaded", true)
                          setPreviewImage(newPreviewImage)
                          setLocalLoading(false)
                          // let fileArray = [...fileRef.current.files]
                          // fileArray.forEach((file, i) => {
                          //   sendUpload(file, i + 1, "user", imageTags) //or 'brand'
                          // })
                        }
                      }
                    })
                  }

                  // reader.readAsDataURL(file) // converts the file to base64 and calls onload

                  // reader.onload = function () {
                  //   const img = new Image()
                  //   img.addEventListener("load", () => {
                  //     console.log("*****img")
                  //     console.log(img.width)
                  //     // that.props.updateScreenerFieldDirect(
                  //     //   "screener",
                  //     //   "logowidth",
                  //     //   img.width
                  //     // )
                  //     // if (img.width > 5000) {
                  //     //   that.updateScreenerFieldDirect(
                  //     //     "error",
                  //     //     "imagewidth",
                  //     //     "Please upload an image less than 5000 pixels in width"
                  //     //   )
                  //     // } else {
                  //     //   that.updateScreenerFieldDirect("error", "imagewidth", false)
                  //     // }
                  //   })
                  //   img.src = reader.result

                  //   console.log("**img")
                  //   console.log(img)

                  //   setPreviewImage(reader.result)
                  //   // that.props.updateScreenerFieldDirect(
                  //   //   "screener",
                  //   //   "logo",
                  //   //   reader.result
                  //   // )
                  //   // that.props.updateScreenerFieldDirect("screener", "logoexist", null)
                  // } //end reader.onload
                }}
              />

              {!isUploadFinished &&
              previewImage &&
              previewImage.length === 0 ? (
                <label for="file">Select files</label>
              ) : null}
            </div>

            {isReadyToUpload ? (
              <div class={`${toolsReadyUploadHiding ? "fade-out" : ""}`}>
                <a
                  class="btn btn-link mx-1 blue-1"
                  // type="button"
                  // data-toggle="pill"
                  // href="#pills-library"
                  // role="tab"
                  // aria-controls="pills-library"
                  // aria-selected="true"
                  onClick={() => {
                    // setToolsReadyUploadHiding(true)
                    // setTimeout(() => {
                    setImageLocalError(null)

                    resetLocalImage()
                    // setToolsReadyUploadHiding(false)
                    // }, 1000)
                  }}
                >
                  Cancel
                </a>
                <button
                  class="btn btn-success mx-1"
                  type="button"
                  onClick={(e) => {
                    console.log("---fileRef---")
                    console.log(fileRef)
                    console.log("---fileRef.current.files---")
                    console.log(fileRef.current.files)
                    let fileArray = [...fileRef.current.files]

                    // setToolsReadyUploadHiding(true)
                    fileArray.forEach((file, i) => {
                      sendUpload(file, i + 1, imagelibrary, imageTags) //imagelibrary can be 'user' or 'brand'
                    })
                    // setTimeout(() => {
                    //   setToolsReadyUploadHiding(false)
                    // }, 1000)

                    // let fileArray = Array.from(e.target.files)
                  }}
                >
                  Start upload
                </button>
              </div>
            ) : null}
            {Object.keys(imageuploadprogress).length > 0 &&
            !isUploadFinished ? (
              <div
                class="fade-in black-3 font-weight-light text-center d-flex flex-row align-items-center justify-content-center"
                // style={{ minWidth: 200 }}
              >
                Uploading
                {/* <Loading class="small" /> */}
              </div>
            ) : null}
            {isUploadFinished ? (
              <>
                <a
                  class="btn btn-outline-blue mx-1"
                  // type="button"
                  // data-toggle="pill"
                  // href="#pills-library"
                  // role="tab"
                  // aria-controls="pills-library"
                  // aria-selected="true"
                  onClick={() => {
                    setImageLocalError(null)
                    resetLocalImage()
                  }}
                >
                  Upload more
                </a>
                <a
                  class="btn btn-primary mx-1"
                  // type="button"
                  // data-toggle="pill"
                  // href="#pills-library"
                  // role="tab"
                  // aria-controls="pills-library"
                  // aria-selected="true"
                  onClick={() => {
                    setImageLocalError(null)

                    resetLocalImage("library")
                  }}
                >
                  View in library
                </a>
              </>
            ) : null}
            {/* {previewImage && previewImage.length > 0 ? (
              <div class="ml-auto tags-small">
                <TemplateTags context="image" />
              </div>
            ) : null} */}
          </div>

          <div class="photo-upload-item-bg rounded-corners my-2 px-2 w-100">
            {forms &&
            forms.previewImageLoaded &&
            previewImage &&
            previewImage.length > 0
              ? previewImage.map((img, i) => {
                  let filePos = i + 1
                  console.log("------- imageuploadprogress -------")
                  console.log(imageuploadprogress)
                  console.log("------- img -------")
                  console.log(img)
                  const imageSrc = `https://${process.env.S3BUCKETMEDIA}.s3.amazonaws.com/public/`

                  return (
                    <div
                      class={`d-flex flex-row align-items-center my-1 ${
                        i + 1 === previewImage.length
                          ? ""
                          : "border-bottom-1-gray-6"
                      }`}
                    >
                      <div
                        class="d-flex flex-column align-items-start justify-content-center"
                        style={{ width: 120 }}
                      >
                        {isUploadFinished ? (
                          <button
                            class="btn btn-slim d-flex align-items-center justify-content-center"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={(e) => {
                              // resetLocalImage()
                              changeAttr(
                                context,
                                "src",
                                `${imageSrc}${
                                  imageuploadprogress[filePos].loc
                                }?t=${Math.random()
                                  .toString(36)
                                  .substring(2, 15)}`
                              )
                              updateFormsField("previewImageLoaded", false)
                              resetImageUpload()
                            }}
                          >
                            <img
                              src={img.preview ? img.preview : ""}
                              class="border-1-gray-6 fade-in image-upload-preview"
                            />
                          </button>
                        ) : (
                          <img
                            src={img.preview ? img.preview : ""}
                            class="border-1-gray-6 fade-in image-upload-preview"
                          />
                        )}
                      </div>
                      <div class="flex-grow-1">
                        {/* <div class="progress">
                        <div
                          class="progress-bar progress-bar-striped"
                          role="progressbar"
                          style={{
                            width: `100%`,
                          }}
                          aria-valuenow={`100`}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div> */}

                        {Object.keys(imageuploadprogress).length === 0 ? (
                          <div class="d-flex flex-row align-items-end justify-content-end fade-in ml-auto">
                            <button
                              class="btn btn-link"
                              onClick={() => {
                                const revisedImageArray = previewImage.filter(
                                  (item, k) => k !== i
                                )
                                console.log("revisedImageArray")
                                console.log(revisedImageArray)
                                setPreviewImage(revisedImageArray)
                              }}
                            >
                              <FaTimes class="red-1" size={18} />
                            </button>
                          </div>
                        ) : null}
                        {imageuploadprogress &&
                        imageuploadprogress[filePos] &&
                        imageuploadprogress[filePos].progress &&
                        imageuploadprogress[filePos].progress > 0 &&
                        imageuploadprogress[filePos].progress !== 100 &&
                        imageuploadprogress[filePos].progress !== 101 ? (
                          <div class="progress fade-in">
                            <div
                              class="progress-bar progress-bar-striped"
                              role="progressbar"
                              style={{
                                width: `${imageuploadprogress[filePos].progress}%`,
                              }}
                              aria-valuenow={`${imageuploadprogress[filePos].progress}`}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        ) : null}

                        {imageuploadprogress &&
                        imageuploadprogress[filePos] &&
                        imageuploadprogress[filePos].progress &&
                        imageuploadprogress[filePos].progress === 100 ? (
                          <div class="d-flex flex-row align-items-center fade-in">
                            <div class="black-3 pl-1 font-weight-light">
                              Processing
                            </div>
                            <Loading class="small ml-3" />
                          </div>
                        ) : null}

                        {imageuploadprogress &&
                        imageuploadprogress[filePos] &&
                        imageuploadprogress[filePos].progress &&
                        imageuploadprogress[filePos].progress === 101 ? (
                          <div class="d-flex flex-row align-items-center fade-in">
                            <FaRegCheckCircle class="dkgreen-1" size={22} />
                            <div class="dkgreen-1 pl-1 font-weight-light">
                              Uploaded
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )
                })
              : null}
            {localLoading ? (
              <div class="m-5 p-5">
                <Loading class="small" />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}
export default Snippet
