import React, { useRef, useState, useEffect } from "react"
import { Text, Transformer } from "react-konva"
import FontManager from "font-picker-react"

const FontFaceObserver = require("fontfaceobserver")
const fontList = require("../../../utils/fontlist/fontListRevised")
const TextBox = ({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  content,
  stageRef,
  changeAttr,
  context,
  stageDimensions,
  pos,
  staticResetTextarea,
  setStaticResetTextarea,
}) => {
  const shapeRef1 = React.useRef()
  const trRef1 = React.useRef()

  useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef1.current.nodes([shapeRef1.current])
      trRef1.current.getLayer().batchDraw()
    }
  }, [isSelected])
  useEffect(() => {
    if (staticResetTextarea) {
      editTextArea(null, true)
      setStaticResetTextarea(false)
    }
  }, [staticResetTextarea])

  const [fontLoaded, setFontLoaded] = useState("")

  const shapePropsUpdate = { ...shapeProps }
  delete shapePropsUpdate.fontFamily
  // console.log("[[[shapeProps]]]")
  // console.log(shapeProps)
  // console.log("[[[FontManager]]]")
  // console.log(FontManager)
  // console.log("[[[FontManager.addFont]]]")
  // console.log(FontManager.addFont)
  if (shapeProps.fontFamily) {
    // console.log("[[[[[fontList]]]]]")
    // console.log(fontList)
    const font = fontList.items.find(
      (font) => font.family === shapeProps.fontFamily
    )

    // console.log("[[[font]]]")
    // console.log(font)
    if (font && font.variants) {
      font.variants.forEach((variant) => {
        let fontId = shapeProps.fontFamily.replace(/\s+/g, "-").toLowerCase()
        fontId = `initial-${fontId}`
        let activeFontStylesheet = document.getElementById(fontId)
        if (!activeFontStylesheet) {
          activeFontStylesheet = document.createElement("style")
          activeFontStylesheet.id = fontId

          let fontUrl =
            process.env.stage === "DEV"
              ? font.files[variant]
              : font.files[variant].replace("http", "https")

          let style =
            '\n\t\t\t@font-face {\n\t\t\t\tfont-family: "' + font.family + '";'
          style += "\n\t\t\t\tfont-style: " + "normal" + ";\n\t\t"

          if (variant === "regular") {
            style += "\n\t\t\t\tfont-weight: " + "400" + ";\n\t\t"
          } else {
            style += "\n\t\t\t\tfont-weight: " + variant + ";\n\t\t"
          }
          style += "\n\t\t\t\tsrc: url(" + fontUrl + ");"

          style += "\n\t\t\t}\n\t\t"

          activeFontStylesheet.innerHTML = style
          document.head.appendChild(activeFontStylesheet)
        }
      }) //end foreach
    }
    const fontLoad = new FontFaceObserver(shapeProps.fontFamily)
    fontLoad.load().then(
      () => {
        // let nodeRef = shapeRef1.current
        // console.log("nodeRef")
        // console.log(nodeRef)
        // shapeRef1.current.fontFamily(shapeProps.fontFamily)
        // nodeRef.getLayer().draw()
        // trRef1.current.getLayer().batchDraw()
        // trRef1.current.forceUpdate()

        // trRef1.current.nodes([shapeRef1.current])
        // trRef1.current.getLayer().batchDraw()
        if (fontLoaded !== shapeProps.fontFamily) {
          // console.log("not included array")
          setFontLoaded(shapeProps.fontFamily)
        } else {
          // console.log("** included array")
          // console.log(fontLoaded)
        }

        // console.log("font loaded---")
        // console.log(fontLoaded)
      },
      () => {
        console.log(`font not available`)
      }
    )
  }

  const editTextArea = (e, staticReset) => {
    console.log("** editTextArea")
    console.log("e")
    console.log(e)

    // shapeRef1.hide()
    let nodeRef = shapeRef1.current
    nodeRef.hide()
    let transformer = trRef1.current
    if (transformer) {
      transformer.hide()
    }
    console.log("editTextArea - nodeRef")
    console.log(nodeRef)
    console.log("editTextArea - stageRef.current.container()")
    console.log(stageRef.current.container())
    console.log("editTextArea - transformer")
    console.log(transformer)
    if (staticReset) {
      let textareaVal = document?.getElementById("tempTextarea")?.value
      console.log("editTextArea - textareaVal")
      console.log(textareaVal)
      if (!textareaVal) {
        textareaVal = nodeRef.text()
      }
      handleOutsideClick(null, textareaVal)
      return
    }
    // create textarea over canvas with absolute position
    // first we need to find position for textarea
    // how to find it?

    // at first lets find position of text node relative to the stage:
    var textPosition = nodeRef.absolutePosition()

    // then lets find position of stage container on the page:
    var stageBox = stageRef.current.container().getBoundingClientRect()
    // var stageBox = stageRef.current.absolutePosition()

    nodeRef.getLayer().draw()

    console.log("textPosition")
    console.log(textPosition)
    console.log("stageBox")
    console.log(stageBox)

    var scrollTop =
      window.pageYOffset !== undefined
        ? window.pageYOffset
        : (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop

    console.log("scrollTop")
    console.log(scrollTop)

    // so position of textarea will be the sum of positions above:
    var areaPosition = {
      x: stageBox.left + textPosition.x,
      y: stageBox.top + textPosition.y + scrollTop, // need to add 92.84 = 67.84px (headers height) + 40px margin-top of the .reporting-container, minus 15 for display looks
    }

    // create textarea and style it
    let rotation
    var textarea = document.createElement("textarea")
    textarea.id = "tempTextarea"
    document.body.appendChild(textarea)

    // apply many styles to match text on canvas as close as possible
    // remember that text rendering on canvas and on the textarea can be different
    // and sometimes it is hard to make it 100% the same. But we will try...
    textarea.value = nodeRef.text()
    textarea.style.position = "absolute"
    textarea.style.top = areaPosition.y + "px"
    textarea.style.left = areaPosition.x + "px"
    textarea.style.width =
      nodeRef.width() * stageDimensions.stageScale -
      nodeRef.padding() * 2 +
      "px"
    textarea.style.height =
      nodeRef.height() * stageDimensions.stageScale -
      nodeRef.padding() * 2 +
      5 +
      "px"
    textarea.style.fontSize =
      nodeRef.fontSize() * stageDimensions.stageScale + "px"
    textarea.style.border = "none"
    textarea.style.padding = "0px"
    textarea.style.margin = "0px"
    textarea.style.overflow = "hidden"
    textarea.style.background = "none"
    textarea.style.outline = "none"
    textarea.style.resize = "none"
    textarea.style.lineHeight = nodeRef.lineHeight()
    textarea.style.fontFamily = nodeRef.fontFamily()
    textarea.style.transformOrigin = "left top"
    textarea.style.textAlign = nodeRef.align()
    textarea.style.color = nodeRef.fill()
    rotation = nodeRef.rotation()
    var transform = ""
    if (rotation) {
      transform += "rotateZ(" + rotation + "deg)"
    }

    var px = 0
    // also we need to slightly move textarea on firefox
    // because it jumps a bit
    var isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1
    if (isFirefox) {
      px += 2 + Math.round(nodeRef.fontSize() / 20)
    }
    transform += "translateY(-" + px + "px)"

    textarea.style.transform = transform

    // reset height
    textarea.style.height = "auto"
    // after browsers resized it we can set actual value
    textarea.style.height = textarea.scrollHeight + 3 + "px"

    textarea.focus()

    function removeTextarea() {
      console.log("**removeTextarea - textarea")
      console.log(textarea)
      // textarea.parentNode.removeChild(textarea)

      if (document && document.getElementById("tempTextarea")) {
        document.getElementById("tempTextarea").remove()
      }
      window.removeEventListener("click", handleOutsideClick)
      window.removeEventListener("touchstart", handleOutsideClick)
      nodeRef.show()
      if (transformer) {
        transformer.show()
      }
      nodeRef.getLayer().draw()
      console.log("remove - nodeRef")
      console.log(nodeRef)
      console.log("remove - transformer")
      console.log(transformer)

      // transformer.forceUpdate()

      // trRef1.current.show()
      // trRef1.current.forceUpdate()
      // layer.draw()
    }

    function setTextareaWidth(newWidth) {
      if (!newWidth) {
        // set width for placeholder
        newWidth = nodeRef.placeholder.length * nodeRef.fontSize()
      }
      // some extra fixes on different browsers
      var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      var isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1
      if (isSafari || isFirefox) {
        newWidth = Math.ceil(newWidth)
      }

      var isEdge = document.documentMode || /Edge/.test(navigator.userAgent)
      if (isEdge) {
        newWidth += 1
      }
      textarea.style.width = newWidth + "px"
    }

    textarea.addEventListener("keydown", function (e) {
      // hide on enter
      // but don't hide on shift + enter
      console.log("*** keydown >>>")
      console.log(textarea.value)

      if (e.keyCode === 13 && !e.shiftKey) {
        nodeRef.text(textarea.value)
        changeAttr("image", "content", textarea.value, pos)
        removeTextarea()
      }
      // on esc do not set value back to node
      if (e.keyCode === 27) {
        removeTextarea()
      }

      //Prevent jumping on keydown. See: https://stackoverflow.com/a/18262927
      const scrollLeft =
        window.pageXOffset ||
        (document.documentElement || document.body.parentNode || document.body)
          .scrollLeft

      const scrollTop =
        window.pageYOffset ||
        (document.documentElement || document.body.parentNode || document.body)
          .scrollTop

      // console.log("TEXTAREA -- stageRef.scaleX()")
      // console.log(stageRef.scaleX())
      console.log("TEXTAREA -- nodeRef.getAbsoluteScale().x ")
      console.log(nodeRef.getAbsoluteScale().x)
      console.log("TEXTAREA -- stageRef ")
      console.log(stageRef)
      let scale = nodeRef.getAbsoluteScale().x
      setTextareaWidth(nodeRef.width() * scale)
      textarea.style.height = "auto"
      textarea.style.height =
        textarea.scrollHeight + nodeRef.fontSize() * scale + "px"

      window.scrollTo(scrollLeft, scrollTop)
    })

    function handleOutsideClick(e, staticResetVal) {
      if ((e && e.target !== textarea) || staticResetVal) {
        console.log("**handleOutsideClick")
        console.log(staticResetVal)
        let textVal = staticResetVal ? staticResetVal : textarea.value
        nodeRef.text(textVal)
        changeAttr("image", "content", textVal, pos)
        removeTextarea()
      }
    }
    setTimeout(() => {
      console.log("**setTimeout")
      window.addEventListener("click", handleOutsideClick)
      window.addEventListener("touchstart", handleOutsideClick)
    })
  } //end editTextArea
  return (
    <React.Fragment>
      <Text
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef1}
        fontFamily={
          fontLoaded === shapeProps.fontFamily
            ? shapeProps.fontFamily
            : "Open Sans"
        }
        {...shapePropsUpdate}
        text={content}
        draggable
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          })
        }}
        // onTransform={(e) => {
        //   const node = shapeRef1.current
        //   const absScale = node.getAbsoluteScale()
        //   node.scaleX(node.scaleX() / absScale.x)
        //   node.scaleY(node.scaleY() / absScale.y)
        // }}
        onTransform={(e) => {
          console.log("**onTransformEnd")
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef1.current
          const scaleX = node.scaleX()
          const scaleY = node.scaleY()

          // we will reset it back
          node.scaleX(1)
          node.scaleY(1)
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
            rotation: node.rotation(),
          })
        }}
        onDblClick={(e) => {
          editTextArea(e)
        }} //end onDblClick
        onDblTap={(e) => {
          console.log("onDblTap - e")
          console.log(e)
          editTextArea(e)
        }} //end onDblClick
      />
      {isSelected && (
        <Transformer
          ref={trRef1}
          // enabledAnchors={["middle-left", "middle-right"]}
          boundBoxFunc={(oldBox, newBox) => {
            // // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox
            }
            return newBox
          }}
        />
      )}
    </React.Fragment>
  )
}

export default TextBox
