//React
import React, { useState } from "react"

//Packages
import { SketchPicker } from "react-color"

//Components
import SidebarTitle from "../sidebarTitle"
import ImagePreviewTray from "../imagePreviewTray"
import DeleteElement from "./deleteElement"

//Icons
import {
  MdCrop,
  MdZoomOutMap,
  MdPhotoLibrary,
  MdLineWeight,
  MdBorderColor,
} from "react-icons/md"

//Constants
import { defaultSwatches } from "../../../utils/constants"

const Snippet = ({
  title,
  mediaArray,
  changeAttr,
  elements,
  elementSelected,
  sendUpload,
  context,
  isLoadingImageUpload,
  imageuploadprogress,
  publisher,
  updatePublisherField,
  editorContext,
  activeTool,
  removeSelectedElement,
  setImageLibraryContext,
}) => {
  const [strokeWidthCollapse, setStrokeWidthCollapse] = useState(true)
  const [strokeColorCollapse, setStrokeColorCollapse] = useState(true)

  console.log("imageAttributes - elements")
  console.log(elements)

  const currentElement = elements.find(
    (element) => element.id === elementSelected.id
  )

  const strokeSizes = []

  for (let i = 0.5; i <= 10; i += 0.5) {
    strokeSizes.push(
      <button
        key={i}
        class={`btn-strokewidth btn btn-slim px-3 ${
          i === 0.5 ? "round-corners-top" : ""
        } ${i === 5 ? "round-corners-bottom" : ""}`}
        onClick={() => {
          const xClipOffset = currentElement.hasOwnProperty("xClipOffset")
            ? currentElement.xClipOffset + i
            : i
          const yClipOffset = currentElement.hasOwnProperty("yClipOffset")
            ? currentElement.yClipOffset + i
            : i

          let strokeOffset = -i
          // let strokeOffset =
          //   (currentElement.strokeWidth ? currentElement.strokeWidth : 0) - i

          let newClip = currentElement.clip
            ? {
                height: currentElement.clip.height + i * 2,
                width: currentElement.clip.width + i * 2,
                x: currentElement.clip.x + strokeOffset,
                y: currentElement.clip.y + strokeOffset,
              }
            : {
                height: currentElement.height + i * 2,
                width: currentElement.width + i * 2,
                x: (currentElement.x ? currentElement.x : 0) + strokeOffset,
                y: (currentElement.y ? currentElement.y : 0) + strokeOffset,
              }
          console.log("|||imageAttributes - newClip|||")
          console.log(newClip)
          // changeAttr("image", "clip", newClip)
          // changeAttr("image", "strokeWidth", i)
          changeAttr("image", null, null, null, {
            strokeWidth: i,
            clip: newClip,
            xClipOffset,
            yClipOffset,
          })

          // if (!currentElement?.stroke) {

          //   changeAttr("image", "stroke", "#000000")
          // }
          setStrokeWidthCollapse(true)
        }}
      >
        <span
          class={`small ${
            currentElement?.strokeWidth === i
              ? "font-weight-bold"
              : "font-weight-normal"
          }`}
        >
          {i}
        </span>
      </button>
    )
  }

  return (
    <>
      {editorContext === "side" ||
      (editorContext === "top" && activeTool === "imageprop") ? (
        <>
          <div class="d-none d-md-block w-100 my-2">
            <SidebarTitle text="Image attributes" />
          </div>
          <div
            class="d-flex flex-row flex-wrap align-items-center justify-content-center py-2 px-2"
            style={{ borderRadius: 6, padding: 6 }}
          >
            <button
              class={`btn btn-slim btn-tool-add p-1 mr-1 ${
                publisher.imagemode === "resize" ? "btn-dkblue white-1" : ""
              }`}
              type="button"
              onClick={() => {
                updatePublisherField("imagemode", "resize")
              }}
            >
              <MdZoomOutMap size={22} />
            </button>
            <button
              class={`btn btn-slim btn-tool-add p-1 mr-1 ${
                publisher.imagemode === "crop" ? "btn-dkblue white-1" : ""
              }`}
              type="button"
              onClick={() => {
                updatePublisherField("imagemode", "crop")
              }}
            >
              <MdCrop size={22} />
            </button>
            <div
              class="border-right-1-gray-5 ml-1 mr-2"
              style={{ width: 1, height: 20, marginTop: 4 }}
            ></div>
            <button
              class="btn btn-slim btn-tool-add p-1 mr-1"
              type="button"
              // data-toggle="collapse"
              // data-target="#shapeColor"
              // aria-expanded="false"
              aria-controls="shapeColor"
              onClick={() => {
                // setCollapse(true)
                setStrokeWidthCollapse(!strokeWidthCollapse)
                setStrokeColorCollapse(true)
              }}
            >
              <MdLineWeight size={22} />
            </button>
            <button
              class="btn btn-slim btn-tool-add p-1"
              type="button"
              // data-toggle="collapse"
              // data-target="#shapeColor"
              // aria-expanded="false"
              aria-controls="shapeColor"
              onClick={() => {
                // setCollapse(true)
                setStrokeWidthCollapse(true)
                setStrokeColorCollapse(!strokeColorCollapse)
              }}
            >
              <MdBorderColor size={22} />
            </button>
            <DeleteElement removeSelectedElement={removeSelectedElement} />
          </div>
          <div>
            <div
              class={strokeColorCollapse ? "d-none" : "d-block"}
              id="strokeColor"
            >
              <SketchPicker
                color={currentElement?.stroke}
                presetColors={defaultSwatches}
                onChangeComplete={(e) => {
                  console.log("onChangeComplete - e")
                  console.log(e)
                  // changeAttr("image", "stroke", e.hex)
                  const colorValue = `rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`
                  changeAttr(context, "stroke", colorValue)
                  // if (!currentElement?.strokeWidth) {
                  //   changeAttr("image", "strokeWidth", 1)
                  // }
                  // setStrokeColorCollapse(false)
                }}
              />
            </div>
            <div
              class={`${
                strokeWidthCollapse ? "d-none" : "d-flex"
              } fade-in-now flex-column border-1-gray-5 round-corners`}
              id="strokeWidth"
            >
              {strokeSizes}
            </div>
          </div>
        </>
      ) : null}

      {editorContext === "side" ||
      (editorContext === "top" && activeTool === "imagebrand") ? (
        <>
          <div class="d-none d-md-block w-100 my-2">
            <SidebarTitle text="Brand library" />
          </div>
          <div class="d-flex flex-row">
            <ImagePreviewTray
              mode="brandmedia"
              display="mini"
              publisher={publisher}
              changeAttr={changeAttr}
              context={context}
            />
            <button
              class="btn btn-slim p-1 px-2 btn-dkblue ml-auto"
              data-toggle="modal"
              data-target="#imageLibraryModal"
              style={{ borderRadius: 2 }}
              onClick={() => setImageLibraryContext("brand")}
            >
              <MdPhotoLibrary class="white-1" />
            </button>
          </div>
        </>
      ) : null}

      {editorContext === "side" ||
      (editorContext === "top" && activeTool === "imageuser") ? (
        <>
          <div class="d-none d-md-block w-100 my-2">
            <SidebarTitle text="User media" />
          </div>
          <div class="d-flex flex-row">
            <ImagePreviewTray
              mode="usermedia"
              display="mini"
              publisher={publisher}
              changeAttr={changeAttr}
              context={context}
            />
            <button
              class="btn btn-slim p-1 px-2 btn-dkblue ml-auto"
              data-toggle="modal"
              data-target="#imageLibraryModal"
              style={{ borderRadius: 2 }}
              onClick={() => setImageLibraryContext("user")}
            >
              <MdPhotoLibrary class="white-1" />
            </button>
          </div>
        </>
      ) : null}
    </>
  )
}

export default Snippet
