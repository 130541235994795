import React, { useState } from "react"
import { connect } from "react-redux"

//Actions
import { updateFormField } from "../../actions/updateForm"
import {
  removeTemplate,
  updateTemplate,
  resetTemplateDelete,
} from "../../actions/createPublisher"

//Components
import TemplateSaveForm from "./templateSaveForm"
import TemplateEditForm from "./templateEditForm"
import TemplateDeleteForm from "./templateDeleteForm"

// import MediaSharer from "./mediaSharer"

//Icons
import { FaRegCheckCircle } from "react-icons/fa"
import { MdFileDownload } from "react-icons/md"

const mapStateToProps = ({
  forms,
  socialmodal,
  isLoadingMediaPreview,
  isLoadingTemplateCreate,
  isLoadingTemplateDeleteCompleted,
  isLoading,
  publisher,
  usersub,
}) => {
  return {
    forms,
    socialmodal,
    isLoadingMediaPreview,
    isLoadingTemplateCreate,
    isLoadingTemplateDeleteCompleted,
    isLoading,
    publisher,
    usersub,
  }
}
const mapDispatchToProps = {
  updateFormField,
  removeTemplate,
  resetTemplateDelete,
  updateTemplate,
}

const Snippet = (props) => {
  const {
    forms,
    socialmodal,
    saveTemplateComplete,
    isLoadingMediaPreview,
    isLoadingTemplateCreate,
    isLoadingTemplateDeleteCompleted,
    updateFormField,
    removeTemplate,
    resetTemplateDelete,
    updateTemplate,
    clearForm,
    publisher,
    userRole,
    userPlan,
    editTemplate,
    usersub,
  } = props

  const [imageThumbElements, setImageThumbElements] = useState(null)
  const [imageThumb, setImageThumb] = useState(null)

  let currentSelectedTemplate = publisher?.templates.find(
    (template) => template.id === publisher?.templateSelected
  )
  //Replace template elements with canvas elements

  let currentStage = publisher?.stage
  if (currentSelectedTemplate?.elements?.[0]?.data) {
    currentSelectedTemplate.elements[0].data = publisher.elements
  }
  console.log("*** templateSaveModal ** currentSelectedTemplate")
  console.log(currentSelectedTemplate)
  console.log("*** templateSaveModal ** currentStage")
  console.log(currentStage)
  // const currentSelectedTemplateElements = currentSelectedTemplate?.elements?.[0]
  //   ?.data
  //   ? currentSelectedTemplate.elements[0].data
  //   : []

  return (
    <div
      class="modal modal-sharer fade"
      id="templateModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="templateModalLabel"
      aria-hidden="true"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header" style={{ borderBottom: 0 }}>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                clearForm("savetemplate")
                clearForm("edittemplate")
                clearForm("workingtags")
                setImageThumb(null)
                setImageThumbElements(null)
                resetTemplateDelete()
                $("#pills-new-tab").tab("show") // Select tab by name
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {isLoadingTemplateDeleteCompleted ? (
              <div
                class="modal-subheader d-flex flex-row align-items-center justify-content-center my-4"
                style={{ minHeight: "20%" }}
              >
                <div class="d-flex flex-row align-items-center fade-in">
                  <FaRegCheckCircle class="dkgreen-1" size={22} />
                  <div class="dkgreen-1 pl-1 font-weight-light">
                    Template deleted
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div class="modal-subheader d-flex flex-row align-items-center justify-content-start">
                  <h4
                    class="text-left pl-4 font-weight-bold"
                    style={{ paddingTop: 8, marginRight: 8 }}
                  >
                    Save template
                  </h4>
                </div>

                <ul
                  class="nav nav-pills mb-3 ml-4 mt-1"
                  id="pills-tab"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="pills-new-tab"
                      data-toggle="pill"
                      href="#pills-new"
                      role="tab"
                      aria-controls="pills-new"
                      aria-selected="true"
                    >
                      Save as new
                    </a>
                  </li>
                  {usersub === currentSelectedTemplate?.uid ? (
                    <>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          id="pills-replace-tab"
                          data-toggle="pill"
                          href="#pills-replace"
                          role="tab"
                          aria-controls="pills-replace"
                          aria-selected="false"
                          onClick={(e) =>
                            editTemplate(
                              currentSelectedTemplate.id,
                              currentSelectedTemplate.thumb,
                              currentSelectedTemplate.title,
                              forms?.edittemplate?.tags?.length > 0 &&
                                forms?.edittemplate?.tags
                                ? forms.edittemplate.tags
                                : currentSelectedTemplate.tags,
                              currentSelectedTemplate.division,
                              currentSelectedTemplate.elements,
                              currentSelectedTemplate.elementsmode,
                              currentSelectedTemplate.fname,
                              currentSelectedTemplate.lname,
                              currentStage
                            )
                          }
                        >
                          Replace current
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          id="pills-delete-tab"
                          data-toggle="pill"
                          href="#pills-delete"
                          role="tab"
                          aria-controls="pills-delete"
                          aria-selected="false"
                        >
                          Delete
                        </a>
                      </li>
                    </>
                  ) : null}
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-new"
                    role="tabpanel"
                    aria-labelledby="pills-new-tab"
                  >
                    <div
                      class="modal-content-container d-flex flex-column align-items-center justify-content-center"
                      style={{ margin: "auto" }}
                    >
                      <TemplateSaveForm
                        forms={forms}
                        saveTemplateComplete={saveTemplateComplete}
                        updateFormField={updateFormField}
                        isLoadingTemplateCreate={isLoadingTemplateCreate}
                        clearForm={clearForm}
                        userRole={userRole}
                        userPlan={userPlan}
                        resetTemplateDelete={resetTemplateDelete}
                      />
                    </div>
                  </div>
                  {usersub === currentSelectedTemplate?.uid ? (
                    <>
                      <div
                        class="tab-pane fade"
                        id="pills-replace"
                        role="tabpanel"
                        aria-labelledby="pills-replace-tab"
                      >
                        <div
                          class="modal-content-container d-flex flex-column align-items-center justify-content-center"
                          style={{ margin: "auto" }}
                        >
                          <TemplateEditForm
                            deleteButton={false}
                            showNewElements={true}
                            forms={forms}
                            removeTemplate={removeTemplate}
                            updateTemplate={updateTemplate}
                            saveTemplateComplete={saveTemplateComplete}
                            updateFormField={updateFormField}
                            isLoadingTemplateCreate={isLoadingTemplateCreate}
                            clearForm={clearForm}
                            publisher={publisher}
                            userRole={userRole}
                            userPlan={userPlan}
                            imageThumbElements={imageThumbElements}
                            setImageThumbElements={setImageThumbElements}
                            imageThumb={imageThumb}
                            setImageThumb={setImageThumb}
                            resetTemplateDelete={resetTemplateDelete}
                          />
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="pills-delete"
                        role="tabpanel"
                        aria-labelledby="pills-delete-tab"
                      >
                        <div
                          class="modal-content-container d-flex flex-column align-items-center justify-content-center"
                          style={{ margin: "auto" }}
                        >
                          <TemplateDeleteForm
                            forms={forms}
                            currentSelectedTemplate={currentSelectedTemplate}
                            removeTemplate={removeTemplate}
                            isLoadingTemplateCreate={isLoadingTemplateCreate}
                            clearForm={clearForm}
                            userRole={userRole}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </>
            )}
            {/* End isLoadingTemplateDeleteCompleted */}
          </div>
        </div>
      </div>
    </div>
  )
}

// export default Snippet
export default React.memo(connect(mapStateToProps, mapDispatchToProps)(Snippet))
// export default connect(mapStateToProps, null)(Snippet)
