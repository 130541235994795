//React
import React from "react"

//Actions
import { checkPasswordStrength } from "../../actions/userManagement"

//Components
import PasswordResetRules from "../appLogin/passwordResetRules"

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formStep: 1,
      email: "",
      password: "",
      password2: "",
      confirmationcode: "",
      codeScreen: false,
      isFinished: false,
      inApp: false,
      loading: false,
      error: [],
    }
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  handleFormStep = step => {
    this.setState({
      formStep: step,
    })
  }

  render() {
    return (
      <form class="form-signup">
        {this.state.formStep === 1 ? (
          <div className="form-step-1">
            <h4 class="pt-2 pb-3">Sign up</h4>
            <input
              type="text"
              id="input-firstname"
              name="firstname"
              class="form-control mb-2"
              placeholder="First name"
              onChange={this.handleUpdate}
              required=""
              autofocus=""
            />
            <input
              type="text"
              id="input-lastname"
              name="lastname"
              class="form-control mb-2"
              placeholder="Last name"
              onChange={this.handleUpdate}
              required=""
              autofocus=""
            />
            <input
              type="company"
              id="input-company"
              name="company"
              class="form-control mb-2"
              placeholder="Company"
              onChange={this.handleUpdate}
              required=""
              autofocus=""
            />
            <input
              type="text"
              id="input-email"
              name="email"
              class="form-control mb-2"
              placeholder="Your email"
              onChange={this.handleUpdate}
              required=""
              autofocus=""
            />
            <hr size={1} />

            <input
              type="password"
              id="input-password"
              name="password"
              class="form-control mb-2"
              placeholder="Enter new password"
              onChange={this.handleUpdate}
              required=""
              autofocus=""
            />
            <input
              type="password"
              id="input-password2"
              name="password2"
              class="form-control mb-2"
              placeholder="Confirm password"
              onChange={this.handleUpdate}
              required=""
              autofocus=""
            />

            <PasswordResetRules
              password={this.state.password}
              password2={this.state.password2}
            />
            {checkPasswordStrength(
              this.state.password,
              this.state.password2
            ) ? (
              <button
                type="button"
                class="btn btn-primary btn-block white-1 mt-2 app-pw-reset-submit"
                onClick={() => this.handleFormStep(2)}
              >
                Next
              </button>
            ) : (
              <button
                type="button"
                class="btn btn-primary btn-block mt-2 white-1 app-pw-reset-submit app-pw-reset-disabled"
              >
                Next
              </button>
            )}
          </div>
        ) : null}

        {this.state.formStep === 2 ? (
          <div className="form-step-2">
            <div className="row d-flex flex-row align-items-center justify-content-center">
              <div className="col-md-2">image</div>
              <div className="col-md-8">
                <input
                  class="form-check-input"
                  type="radio"
                  name="planname"
                  id="plan-2"
                  value="plan-2"
                  checked
                />
                <label class="form-check-label" for="plan-1">
                  plan name
                </label>
              </div>
            </div>
            <div className="row d-flex flex-row align-items-center justify-content-center">
              <div className="col-md-2">image</div>
              <div className="col-md-8">
                <input
                  class="form-check-input"
                  type="radio"
                  name="planname"
                  id="plan-2"
                  value="plan-2"
                />
                <label class="form-check-label" for="plan-2">
                  plan name
                </label>
              </div>
            </div>
            <div className="row">
              <button
                type="button"
                class="btn btn-primary btn-block white-1 mt-2 app-pw-reset-submit"
                onClick={() => this.handleFormStep(3)}
              >
                Next
              </button>
            </div>
          </div>
        ) : null}

        {this.state.formStep === 3 ? (
          <div className="form-step-3">
            <input
              type="text"
              id="input-creditcard"
              name="firstname"
              class="form-control mb-2"
              placeholder="Credit card number"
              onChange={this.handleUpdate}
              required=""
              autofocus=""
            />
            <input
              type="text"
              id="input-cvv"
              name="lastname"
              class="form-control mb-2"
              placeholder="Security code"
              onChange={this.handleUpdate}
              required=""
              autofocus=""
            />
            <input
              type="company"
              id="input-company"
              name="company"
              class="form-control mb-2"
              placeholder="Company"
              onChange={this.handleUpdate}
              required=""
              autofocus=""
            />
            <input
              type="text"
              id="input-email"
              name="email"
              class="form-control mb-2"
              placeholder="Your email"
              onChange={this.handleUpdate}
              required=""
              autofocus=""
            />

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="input-agreement-terms"
                onChange={this.handleUpdate}
              />
              <label class="form-check-label" for="defaultCheck1">
                I agree to the Picohealth services agreement, terms of use, and
                privacy policy
              </label>
            </div>
          </div>
        ) : null}
      </form>
    )
  }
}
export default Snippet
// export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
