//React
import React, { useRef } from "react"
import { useDrag, useDrop } from "react-dnd"

//Packages

//Components
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa"
import { IoIosSquare, IoIosSquareOutline } from "react-icons/io"

const Snippet = ({
  layer,
  id,
  index,
  layerName,
  publisherMode,
  sceneSelected,
  changeAttr,
  updatePublisherField,
  ItemTypes,
  moveCard,
}) => {
  // console.log("-----publisherMode")
  // console.log(publisherMode)
  // console.log("-----sceneLayers")
  // console.log(sceneLayers)
  const ref = useRef(null)
  const [, drop] = useDrop({
    accept: ItemTypes.LAYER,
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.LAYER, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <div
      ref={ref}
      class="border-1-gray-6 d-flex flex-row rounded-corners"
      style={{ marginTop: 2, marginBottom: 2, opacity }}
    >
      <button
        class="btn btn-slim border-right-1-gray-6 d-flex flex-row align-items-center justify-content-center"
        id="layer-visibility" //important to handle deselect clicks
        style={{ width: 25 }}
        onClick={() => {
          changeAttr(
            publisherMode,
            "hide",
            !layer.hide || layer.hide === false ? true : false,
            index
          )
        }}
      >
        {layer.hide ? (
          <FaRegEyeSlash size={16} className="black-5" />
        ) : (
          <FaRegEye size={16} className="black-5" />
        )}
      </button>
      <div class="black-5 px-2 border-right-1-gray-6 flex-grow-1 d-flex flex-row align-items-center justify-content-start">
        {layerName}
      </div>
      <button
        class="btn btn-slim align-self-end d-flex flex-row align-items-center justify-content-center"
        style={{ width: 25 }}
        id="layer-select" //important to handle deselect clicks
        onClick={() => {
          if (publisherMode === "image") {
            updatePublisherField("elementSelected", {
              ...sceneSelected,
              id: layer.id,
              shape: layer.shape,
              pos: index,
            })
          } else if (publisherMode === "video") {
            updatePublisherField("sceneSelected", {
              ...sceneSelected,
              layerid: layer.id,
            })
          }
        }}
      >
        {publisherMode === "video" && layer.id === sceneSelected.layerid ? (
          <IoIosSquare size={16} className="dkblue-1" />
        ) : publisherMode === "image" && layer.id === sceneSelected.id ? (
          <IoIosSquare size={16} className="dkblue-1" />
        ) : (
          <IoIosSquareOutline size={16} className="dkblue-1" />
        )}
      </button>
    </div>
  )
}

export default Snippet
