import React from "react"

class Snippet extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div class="w-100 sidebar-title">
        {this.props.caret ? (
          <span class="caret d-inline-block mr-1"></span>
        ) : null}
        {this.props.text}
      </div>
    )
  }
}
export default Snippet
