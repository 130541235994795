//React
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import { Auth } from "aws-amplify"

//Actions
// import { createTag, listTags, removeTag } from "../../actions/manageTags"
import { updateFormField } from "../../actions/updateForm"

//Components
import Loading from "../loading"

//Icons
import { MdDateRange, MdAccessTime } from "react-icons/md"

//Redux
const mapStateToProps = ({ forms, templatetags, isLoading }) => {
  return { forms, templatetags, isLoading }
}

const mapDispatchToProps = {
  // removeTag: (id) => dispatch(removeTag(id)),
  // listTags: () => dispatch(listTags()),
  // createTag: (content) => dispatch(createTag(content)),
  updateFormField,
}

const Snippet = (props) => {
  const {
    forms,
    templatetags,
    // createTag,
    // listTags,
    // removeTag,
    updateFormField,
    isLoading,
  } = props

  console.log("((tagsContainer - templatetags))")
  console.log(templatetags)
  console.log("((tagsContainer - forms))")
  console.log(forms)
  // const [userSub, setUserSub] = useState(null)

  const [loadNewCalendarEntry, setLoadNewCalendarEntry] = useState(false)

  useEffect(() => {
    // listTags()
    setTimeout(function () {
      setLoadNewCalendarEntry(true)
    }, 6000)
  }, [])
  // console.log("****userSub")
  // console.log(userSub)

  return (
    <div class="reporting-container">
      <div class="row">
        <div class="col-sm-12 ">
          <h3>Calendar</h3>
        </div>
      </div>

      {/* <div class="row">
        <div class="col-sm-12 d-flex flex-row align-items-start justify-content-start"></div>
      </div> */}

      <ul class="nav nav-tabs" id="editor-template-tabs" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link publisher-tab-title active"
            id="template-tab"
            data-toggle="tab"
            href="#template"
            role="tab"
            aria-controls="template"
            aria-selected="true"
          >
            Ideas
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link publisher-tab-title "
            id="elements-tab"
            data-toggle="tab"
            href="#elements"
            role="tab"
            aria-controls="elements"
            aria-selected="false"
          >
            Schedule
          </a>
        </li>
      </ul>

      <div class="tab-content" id="editor-template-content">
        <div
          class="tab-pane fade show active"
          id="template"
          role="tabpanel"
          aria-labelledby="template-tab"
        >
          <div>
            <div
              class="collapse show border-bottom-1-gray-7 border-left-1-gray-7 border-right-1-gray-7 round-corners-bottom"
              id="templateCollapse"
            >
              <div class="d-flex flex-row bg-gray-7  template-tags-container">
                <button class="btn btn-slim">
                  <span class="badge m-2 p-2 badge-filter badge-filter-active">
                    All
                  </span>
                </button>
                <button class="btn btn-slim">
                  <span class="badge m-2 p-2 badge-filter ">Seasonal</span>
                </button>
                <button class="btn btn-slim">
                  <span class="badge m-2 p-2 badge-filter ">Inspirational</span>
                </button>
                <button class="btn btn-slim">
                  <span class="badge m-2 p-2 badge-filter ">Lifestyle</span>
                </button>
                <button class="btn btn-slim">
                  <span class="badge m-2 p-2 badge-filter ">
                    Tips &amp; Tricks
                  </span>
                </button>
              </div>
              <div
                class="row bg-gray-9 template-select-container round-corners-bottom"
                style={{ marginLeft: 0, marginRight: 0 }}
              >
                <div class="col-sm-12 my-2 d-flex flex-row align-items-start justify-content-start">
                  <button class="btn btn-slim ">
                    <img
                      src="https://storylavamedia-prod.s3.amazonaws.com/template/72df4a81-5497-4a9e-a251-6eaddfbfb54c/757c34d5-2d81-4d16-b78e-2e987a2810c9.png"
                      class="border-1-gray-1 m-1"
                      height="90"
                    />
                  </button>
                  <button class="btn btn-slim ">
                    <img
                      src="https://storylavamedia-prod.s3.amazonaws.com/template/0f2ef0e8-8482-4e4c-ada3-e84dd8f6bbd8/2fc55ec1-3eaa-48bf-98ec-63c9780c7cd5.png"
                      class="border-1-gray-1 m-1"
                      height="90"
                    />
                  </button>
                  <button class="btn btn-slim ">
                    <img
                      src="https://storylavamedia-prod.s3.amazonaws.com/template/9c47392f-03aa-4921-b8b3-9f22e44b12c5/d5e4a835-64d0-4280-b2c8-4604c17ae711.png"
                      class="border-1-gray-1 m-1"
                      height="90"
                    />
                  </button>
                  <button class="btn btn-slim ">
                    <img
                      src="https://storylavamedia-prod.s3.amazonaws.com/template/4e5fd5ef-419c-4981-917c-b28813616fb7/3403c3f6-a882-4500-a951-1814831a459f.png"
                      class="border-1-gray-1 m-1"
                      height="90"
                    />
                  </button>
                  <button class="btn btn-slim ">
                    <img
                      src="https://storylavamedia-prod.s3.amazonaws.com/template/faf762f3-3e64-44b6-b8cc-3af30dad2160/af7edc07-a8a1-46d2-93ac-a78bad101bf8.png"
                      class="border-1-gray-1 m-1"
                      height="90"
                    />
                  </button>
                  {/* <button class="btn btn-slim ">
                    <img
                      src="https://storylavamedia-prod.s3.amazonaws.com/template/41ad1029-1b1f-4a62-8df7-8a690f167435/74d2adf5-eee1-4cdb-ae9b-b8b879bf8387.png"
                      class="border-1-gray-1 m-1"
                      height="90"
                    />
                  </button>
                  <button class="btn btn-slim ">
                    <img
                      src="https://storylavamedia-prod.s3.amazonaws.com/template/0953c37b-df64-4851-8f4f-15e715c60c4c/d69642e3-9487-413d-8537-25b21396df62.png"
                      class="border-1-gray-1 m-1"
                      height="90"
                    />
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="elements"
          role="tabpanel"
          aria-labelledby="elements-tab"
        >
          <div>
            <div
              class="collapse show border-bottom-1-gray-7 border-left-1-gray-7 border-right-1-gray-7 round-corners-bottom"
              id="templateCollapse"
            >
              <div
                class="row bg-gray-9 template-select-container round-corners-bottom"
                style={{ marginLeft: 0, marginRight: 0 }}
              >
                <div class="col-sm-12 my-2 d-flex flex-row align-items-start justify-content-start">
                  <div class="py-3 px-2 gray-3">No templates</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class={`row bg-gray-7 mt-3 p-2 py-4 fade-in-now ${
          loadNewCalendarEntry ? "" : "d-none"
        }`}
      >
        <div class="col-2 text-right">
          <h1 class="display-3">
            <span class="badge badge-secondary">4</span>
          </h1>
          <h5>JULY</h5>
        </div>
        <div class="col-10">
          <h5 class="">
            <strong>July 4 seasonal post</strong>
          </h5>
          <ul class="list-inline">
            <li class="list-inline-item black-5 small">
              <MdDateRange size={18} class="mr-1" />
              Sunday
            </li>
            <li class="list-inline-item black-5 small ">
              <MdAccessTime size={18} class="mr-1" /> 12:30 PM
            </li>
            {/* <li class="list-inline-item">
              <i class="fa fa-location-arrow" aria-hidden="true"></i> Cafe
            </li> */}
          </ul>
          <img
            src="https://storylavaprimemedia-archive.s3.amazonaws.com/static/media/july4-landing-1400w.jpg"
            width="100"
          />
        </div>
      </div>

      <div class="row p-2 py-4">
        <div class="col-2 text-right">
          <h1 class="display-3">
            <span class="badge badge-secondary">7</span>
          </h1>
          <h5>JULY</h5>
        </div>
        <div class="col-10">
          <h5 class="">
            <strong>New listing - Riverside Dr.</strong>
          </h5>
          <ul class="list-inline">
            <li class="list-inline-item black-5 small">
              <MdDateRange size={18} class="mr-1" />
              Wednesday
            </li>
            <li class="list-inline-item black-5 small ">
              <MdAccessTime size={18} class="mr-1" /> 9:00 AM
            </li>
            {/* <li class="list-inline-item">
              <i class="fa fa-location-arrow" aria-hidden="true"></i> Cafe
            </li> */}
          </ul>
          <img
            src="https://storylavamedia-prod.s3.amazonaws.com/template/0953c37b-df64-4851-8f4f-15e715c60c4c/d69642e3-9487-413d-8537-25b21396df62.png"
            width="100"
          />
        </div>
      </div>
      <div class="row bg-gray-7 mt-3 p-2 py-4">
        <div class="col-2 text-right">
          <h1 class="display-3">
            <span class="badge badge-secondary">9</span>
          </h1>
          <h5>JULY</h5>
        </div>
        <div class="col-10">
          <h5 class="">
            <strong>Free market analysis promo</strong>
          </h5>
          <ul class="list-inline">
            <li class="list-inline-item black-5 small">
              <MdDateRange size={18} class="mr-1" />
              Friday
            </li>
            <li class="list-inline-item black-5 small ">
              <MdAccessTime size={18} class="mr-1" /> 1:30 PM
            </li>
            {/* <li class="list-inline-item">
              <i class="fa fa-location-arrow" aria-hidden="true"></i> Cafe
            </li> */}
          </ul>
          <img
            src="https://storylavamedia-prod.s3.amazonaws.com/template/41ad1029-1b1f-4a62-8df7-8a690f167435/74d2adf5-eee1-4cdb-ae9b-b8b879bf8387.png"
            width="100"
          />
        </div>
      </div>
    </div>
  )
}
// export default Snippet
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
