//React
import React from "react"

//Gatsby
import { Link } from "gatsby"

export default class Nav extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <nav className="navbar bg-gray-1" style={{ height: 2, padding: 0 }}>
        <div className="container">
          <div
            className="d-flex flex-row align-items-center justify-content-center flex-grow-1"
            id="navbarHome"
            // style={{ minHeight: 30 }}
          >
            {/* <ul className="d-flex flex-row align-items-center justify-content-center navbar-nav mr-auto">
              <li className="nav-item pr-3">
                <Link
                  className="nav-link"
                  activeClassName="nav-link active"
                  to="/app/home/"
                >
                  Home
                </Link>
              </li>

              <li className="nav-item pr-3">
                <Link
                  className="nav-link"
                  activeClassName="nav-link active"
                  to="/app/video/"
                >
                  Video
                </Link>
              </li>
              <li className="nav-item pr-3">
                <Link
                  className="nav-link"
                  activeClassName="nav-link active"
                  to="/app/publisher/"
                >
                  Image
                </Link>
              </li>
            </ul> */}
          </div>
        </div>
      </nav>
    )
  }
}
