import React, { useState } from "react"
import { connect } from "react-redux"

//Actions
import { updateFormField } from "../../actions/updateForm"
import { removeTemplate, updateTemplate } from "../../actions/createPublisher"

//Components
import TemplateEditForm from "./templateEditForm"

//Icons

const mapStateToProps = ({
  forms,
  socialmodal,
  isLoadingMediaPreview,
  isLoadingTemplateCreate,
  isLoading,
  publisher,
}) => {
  return {
    forms,
    socialmodal,
    isLoadingMediaPreview,
    isLoadingTemplateCreate,
    isLoading,
    publisher,
  }
}
const mapDispatchToProps = {
  updateFormField,
  removeTemplate,
  updateTemplate,
}

const Snippet = (props) => {
  const {
    forms,
    socialmodal,
    saveTemplateComplete,
    isLoadingMediaPreview,
    isLoadingTemplateCreate,
    updateFormField,
    removeTemplate,
    updateTemplate,
    clearForm,
    publisher,
    userRole,
    userPlan,
  } = props

  const [imageThumbElements, setImageThumbElements] = useState(null)
  const [imageThumb, setImageThumb] = useState(null)

  return (
    <div
      class="modal modal-sharer fade"
      id="templateEditModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="templateEditModalLabel"
      aria-hidden="true"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header" style={{ borderBottom: 0 }}>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                clearForm("savetemplate")
                clearForm("edittemplate")
                clearForm("workingtags")
                setImageThumb(null)
                setImageThumbElements(null)
                $("#pills-new-tab").tab("show") // Select tab by name
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="modal-subheader d-flex flex-row align-items-center justify-content-start">
              <h4
                class="text-left pl-4 font-weight-bold"
                style={{ paddingTop: 8, marginRight: 8 }}
              >
                Edit template
              </h4>
            </div>

            <div
              class="modal-content-container d-flex flex-column align-items-center justify-content-center"
              style={{ margin: "auto" }}
            >
              <TemplateEditForm
                deleteButton={false}
                forms={forms}
                removeTemplate={removeTemplate}
                updateTemplate={updateTemplate}
                saveTemplateComplete={saveTemplateComplete}
                updateFormField={updateFormField}
                isLoadingTemplateCreate={isLoadingTemplateCreate}
                clearForm={clearForm}
                publisher={publisher}
                userRole={userRole}
                userPlan={userPlan}
                imageThumbElements={imageThumbElements}
                setImageThumbElements={setImageThumbElements}
                imageThumb={imageThumb}
                setImageThumb={setImageThumb}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// export default Snippet
export default React.memo(connect(mapStateToProps, mapDispatchToProps)(Snippet))
// export default connect(mapStateToProps, null)(Snippet)
