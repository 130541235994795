import React, { useEffect, useState } from "react"

import { Auth } from "aws-amplify"

//Components
import TemplateTags from "./templateTags"
import Loading from "../loading"
import { format } from "libphonenumber-js"

//Icons
import { MdInfoOutline } from "react-icons/md"

const Snippet = (props) => {
  const {
    saveTemplateComplete,
    forms,
    updateFormField,
    isLoadingTemplateCreate,
    clearForm,
    userRole,
    userPlan,
    resetTemplateDelete,
  } = props

  const [userSub, setUserSub] = useState(null)
  const [imageThumb, setImageThumb] = useState(null)
  const [templatePlaceholderId, setTemplatePlaceholderId] = useState(null)

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      setUserSub(user.username)
    })
  }, [])

  useEffect(() => {
    if (forms?.savetemplate?.thumb) {
      setImageThumb(forms.savetemplate.thumb)
    }
    if (forms?.savetemplate?.id) {
      setTemplatePlaceholderId(forms.savetemplate.id)
    }
    // setTemplatePlaceholderId(uuidv4())
  })

  // useEffect(() => {
  //   console.log("**** CHANGING forms.savetemplate.id")
  //   console.log("forms")
  //   console.log(forms)
  //   let templateName
  //   if (forms.edittemplate && forms.edittemplate.title) {
  //     templateName = `${forms.edittemplate.title} (copy)`
  //   } else if (forms.savetemplate && forms.savetemplate.title) {
  //     templateName = forms.savetemplate.title
  //   } else {
  //     templateName = ""
  //   }
  //   updateFormField("edittemplate", "title", templateName)
  //   updateFormField("savetemplate", "title", templateName)
  // }, [forms.savetemplate.id])

  // console.log("****userSub")
  // console.log(userSub)
  // console.log("templateSaveForm - forms")
  // console.log(forms)
  // console.log("templateSaveForm - imageThumb")
  // console.log(imageThumb)

  const currentTemplateTags = []

  // const currentTemplateTags =
  //   publisher &&
  //   publisher.templates &&
  //   publisher.templates.find((template) => template.id === templateId) &&
  //   publisher.templates.find((template) => template.id === templateId).tags
  //     ? publisher.templates.find((template) => template.id === templateId).tags
  //     : []

  console.log("TEMPLATESAVEFORM ----- forms.savetemplate.globaltemplate")
  console.log(forms.savetemplate.globaltemplate)

  return (
    <div class="reporting-container w-100 p-4">
      <div class="form-group row d-flex flex-row align-items-center justify-content-start">
        <div class="offset-sm-2 col-sm-10">
          <img src={imageThumb} width={150} class="border-1-gray-5" />
        </div>
      </div>
      <div class="form-group row d-flex flex-row align-items-center justify-content-start">
        <label
          for="template-name-save"
          class="col-sm-2 col-form-label font-weight-light line-height-1"
        >
          Template name
        </label>
        <div class="col-sm-10">
          <input
            type="text"
            class="form-control"
            id="template-name-save"
            placeholder="Enter the template name"
            value={
              forms && forms.savetemplate && forms.savetemplate.title
                ? forms.savetemplate.title
                : ""
            }
            onChange={(e) => {
              // Including both edit and save template for seamless switching
              updateFormField("edittemplate", "title", e.target.value)
              updateFormField("savetemplate", "title", e.target.value)
            }}
          />
        </div>
      </div>
      <div class="form-group row d-flex flex-row align-items-center justify-content-start">
        <label
          for="template-tags"
          class="col-sm-2 col-form-label font-weight-light line-height-1"
        >
          Tags
        </label>
        <div class="col-sm-10">
          <div class="d-flex flex-row align-items-center justify-content-start">
            {/* <TemplateTags updateFormField={updateFormField} /> */}
            <TemplateTags
              context="savetemplate"
              updateFormField={updateFormField}
              templateId={templatePlaceholderId}
              currentTemplateTags={currentTemplateTags}
              currentTags={
                forms.savetemplate && forms.savetemplate.tags
                  ? forms.savetemplate.tags
                  : []
              }
            />
          </div>
        </div>
      </div>

      <div class="form-group row d-flex flex-row align-items-center justify-content-start">
        <label
          for="accessControl"
          class="col-sm-2 col-form-label font-weight-light line-height-1"
        >
          Access
        </label>
        <div class="col-sm-10">
          <div class="d-flex flex-row align-items-center justify-content-start">
            <select
              class="form-control"
              id="accessControl"
              onChange={(e) => {
                console.log("templateSaveForm - accessControl - e.target")
                console.log(e.target.value)
                // Including both edit and save template for seamless switching
                updateFormField(
                  "edittemplate",
                  "globaltemplate",
                  e.target.value
                )
                updateFormField(
                  "savetemplate",
                  "globaltemplate",
                  e.target.value
                )
              }}
              value={forms.savetemplate.globaltemplate}
            >
              <option value="public">Public</option>
              {userRole &&
              userRole === "admin" &&
              userPlan &&
              userPlan === "proplus" ? (
                <option value="global">My company</option>
              ) : null}

              {userPlan && userPlan === "proplus" ? (
                <option value="private">Only me</option>
              ) : null}
            </select>
            <a
              href={`https://${process.env.HOSTNAME_SUPPORT}/article/template-access/`}
              target="_blank"
              class="d-flex flex-row p-2"
            >
              <MdInfoOutline size={20} className="gray-2" />{" "}
              {/* <span class="gray-2 small">Access levels</span> */}
            </a>
          </div>
        </div>
      </div>

      {userRole && userRole === "admin" ? (
        <>
          <div class="form-group row d-flex flex-row align-items-center justify-content-start">
            <label
              for="globaltemplate"
              class="col-sm-2 col-form-label font-weight-light line-height-1"
            >
              Save as template elements
            </label>
            <div class="col-sm-10">
              <div class="d-flex flex-row align-items-center justify-content-start ml-2 p-3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  checked={forms.savetemplate.elementsmode}
                  id="elementsmode"
                  onChange={(e) => {
                    console.log("templateEditForm - elementsmode - e.target")
                    console.log(e.target)
                    let checkedVal = event.target.checked ? 1 : 0
                    updateFormField("savetemplate", "elementsmode", checkedVal)
                  }}
                />
              </div>
            </div>
          </div>
          {/* <div class="form-group row d-flex flex-row align-items-center justify-content-start">
            <label
              for="globaltemplate"
              class="col-sm-2 col-form-label font-weight-light line-height-1"
            >
              Save as global company template
            </label>
            <div class="col-sm-10">
              <div class="d-flex flex-row align-items-center justify-content-start ml-2 p-3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  // value={
                  //   forms?.savetemplate?.globaltemplate
                  //     ? forms.savetemplate.globaltemplate
                  //     : 0
                  // }
                  checked={forms.savetemplate.globaltemplate}
                  // defaultValue={1}
                  id="globaltemplate"
                  onChange={(e) => {
                    console.log("templateEditForm - globaltemplate - e.target")
                    console.log(e.target)
                    let checkedVal = event.target.checked ? 1 : 0
                    updateFormField(
                      "savetemplate",
                      "globaltemplate",
                      checkedVal
                    )
                  }}
                />
              </div>
            </div>
          </div> */}
          {/* <div class="form-group row d-flex flex-row align-items-center justify-content-start">
            <label
              for="publictemplate"
              class="col-sm-2 col-form-label font-weight-light line-height-1"
            >
              Save as public template
            </label>
            <div class="col-sm-10">
              <div class="d-flex flex-row align-items-center justify-content-start ml-2 p-3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  checked={forms.savetemplate.publictemplate}
                  // defaultValue={1}
                  id="publictemplate"
                  onChange={(e) => {
                    console.log("templateEditForm - publictemplate - e.target")
                    console.log(e.target)
                    let checkedVal = event.target.checked ? 1 : 0
                    updateFormField(
                      "savetemplate",
                      "publictemplate",
                      checkedVal
                    )
                  }}
                />
              </div>
            </div>
          </div> */}
        </>
      ) : null}

      <div class="d-flex flex-row align-items-center justify-content-center">
        <button
          class="btn btn-link"
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            clearForm("savetemplate")
            clearForm("edittemplate")
            clearForm("workingtags")
            $("#pills-new-tab").tab("show") // Select tab by name
            resetTemplateDelete()
          }}
        >
          Cancel
        </button>
        <button class="btn btn-primary" onClick={saveTemplateComplete}>
          Save
        </button>
        {isLoadingTemplateCreate ? <Loading class="small ml-3" /> : null}
      </div>
    </div>
  )
}
export default Snippet
