import React from "react"
import { connect } from "react-redux"

import AlertNewCanvasForm from "./alertNewCanvasForm"

//Actions
import { createNotify, deleteNotify } from "../../actions/createNotify"
import { updateFormField } from "../../actions/updateForm"

//Icons

// const mapStateToProps = ({
//   forms,
//   publisher,
//   socialmodal,
//   isLoadingNotifyCreate,
//   isLoading,
//   company,
// }) => {
//   return {
//     forms,
//     publisher,
//     socialmodal,
//     isLoadingNotifyCreate,
//     isLoading,
//     company,
//   }
// }

// const mapDispatchToProps = (dispatch) => ({
//   createNotify: (
//     content,
//     imageid,
//     templateid,
//     context,
//     notifyemail,
//     notifysms
//   ) =>
//     dispatch(
//       createNotify(
//         content,
//         imageid,
//         templateid,
//         context,
//         notifyemail,
//         notifysms
//       )
//     ),
//   deleteNotify: (id) => dispatch(deleteNotify(id)),
//   updateFormField: (group, prop, val) =>
//     dispatch(updateFormField(group, prop, val)),
// })

const Snippet = (props) => {
  const { context, clearCanvas } = props

  let modalId
  let modalTitle
  switch (context) {
    case "newcanvas":
      modalId = "alertNewCanvasModal"
      modalTitle = "Clear canvas"
      break
    default:
      modalId = "alertModal"
      modalTitle = "Confirmation"
  }
  return (
    <div
      class="modal modal-sharer fade"
      id={modalId}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="alertLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header" style={{ borderBottom: 0 }}>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="modal-subheader d-flex flex-row align-items-center justify-content-start">
              <h4
                class="text-left pl-4 font-weight-bold"
                style={{ paddingTop: 8, marginRight: 8 }}
              >
                {modalTitle}
              </h4>
            </div>

            <div
              class="modal-content-container d-flex flex-column align-items-center justify-content-center"
              style={{ margin: "auto" }}
            >
              {context === "newcanvas" ? (
                <AlertNewCanvasForm clearCanvas={clearCanvas} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Snippet
// export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
