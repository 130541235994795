import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"

import { navigate } from "@reach/router"
import { getCompanyDetail, logoutUser } from "./userManagement"
import { unauthorizedMessage } from "../utils/constants"

export function resetUpdateUserGroup() {
  console.log("###actions - resetUpdateUserGroup - pre dispatch###")
  return (dispatch) => {
    dispatch({
      type: "USERGROUP_CREATE_UPDATE_RESET",
    })
  }
}

export function updateUserGroupField(group, index, fname, lname, email) {
  console.log("###actions - updateUserGroupField - pre dispatch###")
  return (dispatch) => {
    dispatch({
      type: "UPDATE_FORM_FIELD_USERGROUP",
      data: { group, index, fname, lname, email },
    })
  }
}
export function createUserGroup(users) {
  console.log("actions - createUserGroup - start")

  let cid = Cache.getItem("cid")

  console.log("actions - createUserGroup - cid")
  console.log(cid)

  console.log("actions - createUserGroup - users")
  console.log(users)

  let usersToUpload = users.map((user) => {
    if (user.delete && user.id) {
      return {
        id: user.id,
        fname: user.fname,
        lname: user.lname,
        email: user.email,
        mode: "delete",
      }
    } else if (user.add && user.modified) {
      return {
        fname: user.fname,
        lname: user.lname,
        email: user.email,
        mode: "add",
      }
    } else if (user.modified) {
      return {
        id: user.id,
        fname: user.fname,
        lname: user.lname,
        email: user.email,
        mode: "modify",
        emailmodified: user.modifiedemail ? true : false,
      }
    }
  })
  console.log("actions - createUserGroup - usersToUpload")
  console.log(usersToUpload)

  usersToUpload = usersToUpload.filter((user) => user) //filter out null values

  console.log("actions - createUserGroup - usersToUpload - POST")
  console.log(usersToUpload)

  let apiName = "Storylavaweb"

  let path = "admin/usergroup/update"

  return (dispatch) => {
    dispatch({
      type: "USERGROUP_CREATE_UPDATE_START",
    })
    Auth.currentAuthenticatedUser()
      .then((user) => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log(
          "actions - createUserGroup - Auth.currentAuthenticatedUser - user"
        )
        console.log(user)
        let jwtToken = user.signInUserSession.accessToken.jwtToken

        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            cid,
          },
          body: {
            users: usersToUpload,
            cid,
          },
        }

        console.log(
          "actions - createUserGroup - Auth.currentAuthenticatedUser - options"
        )
        console.log(options)

        //begin promise
        new Promise((resolve) => {
          API.post(apiName, path, options)
            .then((response) => {
              const data = response || {}

              let result = data

              console.log("actions - createUserGroup - API.post - result")
              console.log(result)

              // navigate("/app/sendgroup/")

              dispatch(getCompanyDetail(null, "users"))
              return resolve()
            })
            .catch((error) => {
              console.log(error.response)
              if (
                error &&
                error.response &&
                error.response.status &&
                (error.response.status === 401 || error.response.status === 403)
              ) {
                dispatch(logoutUser(unauthorizedMessage))
              }
            })
        }) //end promise
      })
      .catch((error) => {
        console.log(error)
      }) //end get Auth token
  }
}
