import React from "react"

const Snippet = (props) => {
  return (
    <div class="w-100 h-100 gray-4 text-center line-height-3 font-weight-light d-flex flex-row align-items-center justify-content-center">
      <div
        class="d-flex flex-row align-items-center justify-content-center"
        style={{ padding: 50 }}
      >
        Select a template
        <br class="mx-2" />
        or add a new canvas element
      </div>
    </div>
  )
}
export default Snippet
