//React
import React from "react"

if (process.env.NODE_ENV === "development") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render")
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  })
}

import { Router } from "@reach/router"

//Packages
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { TouchBackend } from "react-dnd-touch-backend"
import MultiBackend, { TouchTransition } from "react-dnd-multi-backend"

// import HTML5toTouch from "react-dnd-multi-backend/dist/cjs/HTML5toTouch"

const HTML5toTouch = {
  backends: [
    {
      backend: HTML5Backend,
    },
    {
      backend: TouchBackend,
      options: { enableMouseEvents: true },
      preview: true,
      transition: TouchTransition,
    },
  ],
}

//Components
import AppCampaignDetail from "../components/appCampaignDetail"
import SignUp from "../components/appSignup/signUp"
import PasswordReset from "../components/appLogin/passwordReset"
import PrivateRoute from "../components/PrivateRoute"

const App = () => (
  <DndProvider backend={MultiBackend} options={HTML5toTouch}>
    <Router>
      {/* <PrivateRoute path="/app/home/" component={AppHome} /> */}
      {/* <PrivateRoute path="/app/video/" component={AppCampaignDetail} /> */}
      <PrivateRoute path="/app/publisher/" component={AppCampaignDetail} />
      {/* <PrivateRoute path="/app/tags/" component={AppCampaignDetail} /> */}
      <PrivateRoute path="/app/profile/" component={AppCampaignDetail} />
      <PrivateRoute path="/app/users/" component={AppCampaignDetail} />
      {/* <PrivateRoute path="/app/calendar/" component={AppCampaignDetail} /> */}

      <SignUp path="/app/signup" />
      <PasswordReset path="/app/password" />
    </Router>
  </DndProvider>
)

export default App
