//React
import React from "react"

//Gatsby
import { Link } from "gatsby"

//Amplify
import Amplify from "@aws-amplify/core"
import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import { amplifyconfig } from "../../utils/config"

//Packages
import parse from "url-parse"

//Components
import PasswordResetRules from "./passwordResetRules"

//Icons
import { FaCheck, FaTimes, FaArrowRight } from "react-icons/fa"

//Configure
Amplify.configure(amplifyconfig)

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      password2: "",
      confirmationcode: "",
      codeScreen: false,
      isFinished: false,
      inApp: false,
      loading: false,
      error: [],
    }
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  resetForm = () => {
    this.setState({
      email: "",
      password: "",
      password2: "",
      confirmationcode: "",
      codeScreen: false,
      loading: false,
      error: [],
    })
  }

  initiateForgotPassword = () => {
    const { email } = this.state

    Auth.forgotPassword(email)
      .then((data) => {
        console.log("INITIATE FORGOT PASSWORD")
        this.setState({
          error: [],
          codeScreen: true,
        })
        console.log(data)
      })
      .catch((err) => {
        console.log("INITIATE FORGOT PASSWORD - catch")
        console.log(err)

        if (err && err.message) {
          this.setState({ error: err })
        } else {
          this.setState({ error: { message: err } })
        }
      })
  }

  resetPassword = () => {
    const { email, password, confirmationcode } = this.state

    Auth.forgotPasswordSubmit(email, confirmationcode, password)
      .then((data) => {
        console.log("resetting password ======")
        console.log(data)
        this.setState({
          error: [],
          isFinished: true,
        })
      })
      .catch((err) => {
        console.log(err)
        if (err && err.message) {
          this.setState({ error: err })
        } else {
          this.setState({ error: { message: err } })
        }
      })
  }

  componentDidMount() {
    const url = parse(window.location.href, true)

    if (url.query && url.query.p && url.query.p === "app") {
      this.setState({ inApp: true })
    }
  }

  render() {
    return (
      <>
        <div class="row align-items-center justify-content-center login-loading d-none">
          <div class="col-md-2 text-center">
            <i class="fa fa-spinner fa-spin fa-3x"></i>
          </div>
        </div>

        {this.state.error && this.state.error.message ? (
          <div class="row align-items-center justify-content-center">
            <div class="">
              <div class="alert alert-danger text-center" role="alert">
                {this.state.error.message}
              </div>
            </div>
          </div>
        ) : null}

        {this.state.codeScreen === false ? (
          <div class="row justify-content-center">
            <div class="">
              <form class="form-signin">
                <h2 class="form-signin-heading text-center pb-2">
                  Reset Password
                </h2>
                <p class="password-instructions text-center">
                  Please enter your email below to begin resetting your
                  password.
                </p>

                {/* <label for="input-email" class="sr-only">
                  Email
                </label> */}
                <input
                  type="email"
                  id="input-email"
                  name="email"
                  class="form-control mt-4 mb-4"
                  placeholder="Email"
                  required=""
                  autofocus=""
                  onChange={this.handleUpdate}
                  value={this.state.email}
                />
                {/* <input
                  type="hidden"
                  value="{{#if_eq layout 'minimal'}}1{{else}}0{{/if_eq}}"
                  name="isapp"
                /> */}
                <button
                  type="button"
                  class="btn btn-primary btn-block app-password-initiate mt-4"
                  onClick={this.initiateForgotPassword}
                >
                  Next
                </button>
              </form>
            </div>
          </div>
        ) : (
          <div>
            {this.state.isFinished === true ? (
              <div class="row justify-content-center">
                <div class="">
                  <h2 class="form-signin-heading text-center pb-2">Success</h2>
                  <p class="password-instructions text-center">
                    You've successfully reset your password
                  </p>
                  <p class="password-instructions text-center p-3">
                    <Link
                      class="btn btn-primary d-flex flex-row align-items-center justify-content-center"
                      to="/"
                    >
                      <div class="mr-2">Sign in</div>
                      <FaArrowRight
                        style={{ marginTop: 1 }}
                        class="btn-arrow"
                      />
                    </Link>
                  </p>
                </div>
              </div>
            ) : (
              <div class="row justify-content-center">
                <div class="">
                  <form class="form-signin">
                    <h2 class="form-signin-heading text-center pb-2">
                      Reset Password
                    </h2>

                    {this.state.email ? (
                      <div>
                        <p class="password-instructions line-height-2 text-center">
                          We've sent a confirmation code to your email address.
                          Please enter the code and your new password below.{" "}
                          <a
                            href={`http${
                              process.env.STAGE === "dev" ? "" : "s"
                            }://${
                              process.env.HOSTNAME_SUPPORT
                            }/article/reset-password`}
                            class="gray-3"
                          >
                            Need help?
                          </a>
                        </p>

                        {/* <label for="input-email" class="sr-only">
                          Confirmation code
                        </label> */}

                        <input
                          type="text"
                          id="input-confirmationcode"
                          name="confirmationcode"
                          class="form-control mb-4"
                          placeholder="Enter confirmation code"
                          onChange={this.handleUpdate}
                          required=""
                          autofocus=""
                        />

                        <hr size={1} />
                        <input
                          type="password"
                          id="input-password"
                          name="password"
                          class="form-control mb-2"
                          placeholder="Enter new password"
                          onChange={this.handleUpdate}
                          required=""
                          autofocus=""
                        />
                        <input
                          type="password"
                          id="input-password2"
                          name="password2"
                          class="form-control mb-2"
                          placeholder="Confirm password"
                          onChange={this.handleUpdate}
                          required=""
                          autofocus=""
                        />

                        <PasswordResetRules
                          password={this.state.password}
                          password2={this.state.password2}
                        />

                        <input
                          type="hidden"
                          value="{{#if_eq layout 'minimal'}}1{{else}}0{{/if_eq}}"
                          name="isapp"
                        />

                        <div class="password-attributes-message text-right">
                          {this.state.password === this.state.password2 &&
                          this.state.password.length >= 6 ? (
                            <button
                              type="button"
                              class="btn btn-primary btn-block mt-2 app-pw-reset-submit"
                              onClick={this.resetPassword}
                            >
                              Reset password
                            </button>
                          ) : (
                            <button
                              type="button"
                              class="btn btn-primary btn-block mt-2 app-pw-reset-submit app-pw-reset-disabled"
                            >
                              Reset password
                            </button>
                          )}
                        </div>
                      </div>
                    ) : (
                      <p class="password-instructions text-center">
                        Oops, we weren't able to get your email address. Please{" "}
                        <Link to="/app/password" onClick={this.resetForm}>
                          begin again.
                        </Link>
                      </p>
                    )}
                  </form>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    )
  }
}
