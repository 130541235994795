import React from "react"
import { connect } from "react-redux"

import NotificationSaveForm from "./notificationSaveForm"
import NotificationDeleteForm from "./notificationDeleteForm"

//Actions
import { createNotify, deleteNotify } from "../../actions/createNotify"
import { updateFormField } from "../../actions/updateForm"

//Icons

const mapStateToProps = ({
  forms,
  publisher,
  socialmodal,
  isLoadingNotifyCreate,
  isLoading,
  company,
}) => {
  return {
    forms,
    publisher,
    socialmodal,
    isLoadingNotifyCreate,
    isLoading,
    company,
  }
}

const mapDispatchToProps = {
  createNotify,
  deleteNotify,
  updateFormField,
}

const Snippet = (props) => {
  const {
    forms,
    publisher,
    socialmodal,
    saveTemplate,
    createNotify,
    deleteNotify,
    updateFormField,
    isLoadingNotifyCreate,
    context,
    company,
  } = props

  let modalId
  let modalTitle
  switch (context) {
    case "save":
      modalId = "notificationSaveModal"
      modalTitle = "New post"
      break
    case "delete":
      modalId = "notificationDeleteModal"
      modalTitle = "Delete notification"
      break
    default:
      modalId = "notificationSaveModal"
      modalTitle = "New post"
  }
  return (
    <div
      class="modal modal-sharer fade"
      id={modalId}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="notificationSaveLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header" style={{ borderBottom: 0 }}>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="modal-subheader d-flex flex-row align-items-center justify-content-start">
              <h4
                class="text-left pl-4 font-weight-bold"
                style={{ paddingTop: 8, marginRight: 8 }}
              >
                {modalTitle}
              </h4>
            </div>

            <div
              class="modal-content-container d-flex flex-column align-items-center justify-content-center"
              style={{ margin: "auto" }}
            >
              {context === "save" ? (
                <NotificationSaveForm
                  publisher={publisher}
                  forms={forms}
                  saveTemplate={saveTemplate}
                  createNotify={createNotify}
                  updateFormField={updateFormField}
                  isLoadingNotifyCreate={isLoadingNotifyCreate}
                  company={company}
                />
              ) : null}
              {context === "delete" ? (
                <NotificationDeleteForm
                  forms={forms}
                  deleteNotify={deleteNotify}
                  isLoadingNotifyCreate={isLoadingNotifyCreate}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// export default Snippet
// export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
