import React, { useState } from "react"

//Packages
import { Image, Transformer, Group, Rect } from "react-konva"

const ImageSnippet = ({
  addNewElement,
  stageRef,
  imageRef,
  isSelected,
  shapeProps,
  onSelect,
  onChange,
  clipped,
  clipX,
  clipY,
  clipWidth,
  clipHeight,
  image,
}) => {
  const shapeRef2 = React.useRef()
  const trRef2 = React.useRef()
  const rectRef2 = React.useRef()
  const groupRef2 = React.useRef()
  const cloneContainer = React.useRef()
  const cloneImage = React.useRef()

  const [scaleXProp, setScaleX] = useState(1)
  const [scaleYProp, setScaleY] = useState(1)

  React.useEffect(() => {
    if (isSelected) {
      createClone()
      // we need to attach transformer manually
      trRef2.current.nodes([shapeRef2.current])
      trRef2.current.getLayer().batchDraw()
    }
  }, [isSelected])

  let shapePropsRevised = { ...shapeProps }
  // shapePropsRevised["x"] = 0
  // shapePropsRevised["y"] = 0
  // delete shapePropsRevised["x"]
  // delete shapePropsRevised["y"]

  let shapePropsNoClip = { ...shapeProps }
  // shapePropsRevised["x"] = 0
  // shapePropsRevised["y"] = 0
  delete shapePropsNoClip["clip"]
  // delete shapePropsRevised["y"]

  let clipProps = { x: 0, y: 0 }
  // let clipProps = { x: 407.9125582571673, y: 185.84008854607674, width:500, height:500 }
  // let clipProps = { x: 0, y: 0, width: 500, height: 500 }

  // if (clipX) clipProps["x"] = clipX * scaleXProp
  // if (clipY) clipProps["y"] = clipY * scaleYProp

  if (clipX) clipProps["x"] = clipX
  if (clipY) clipProps["y"] = clipY
  if (clipWidth) clipProps["width"] = clipWidth
  if (clipHeight) clipProps["height"] = clipHeight

  // if (typeof clipX !== "undefined") clipProps["x"] = clipX
  // if (typeof clipY !== "undefined") clipProps["y"] = clipY
  // if (typeof clipWidth !== "undefined") clipProps["width"] = clipWidth
  // if (typeof clipHeight !== "undefined") clipProps["height"] = clipHeight

  // if (clipWidth) clipProps["width"] = clipWidth * scaleXProp
  // if (clipHeight) clipProps["height"] = clipHeight * scaleYProp

  // const clipXOffset = ((groupContainer.x - clipX) * scaleXProp)
  // const clipYOffset = groupContainer.y - clipY
  // const clipScaleWidth = clipY
  // console.log("****shapeProps")
  // console.log(shapeProps)
  console.log("****shapePropsRevised")
  console.log(shapePropsRevised)
  // console.log("****clipProps")
  // console.log(clipProps)
  // console.log("****clipX")
  // console.log(clipX)
  // console.log("****clipY")
  // console.log(clipY)
  // console.log("****clipWidth")
  // console.log(clipWidth)
  // console.log("****clipHeight")
  // console.log(clipHeight)
  // console.log("****scaleXProp")
  // console.log(scaleXProp)
  // console.log("****scaleYProp")
  // console.log(scaleYProp)

  const createClone = () => {
    const node = shapeRef2.current
    const clonecontainer = cloneContainer.current
    const layer = shapeRef2.current.getLayer()

    console.log("\\........CREATECLONE ........///")
    console.log(shapeProps)
    // clone it
    const clone = node.clone({
      // ...shapeProps,
      x: shapeProps.x,
      // y: shapeProps.y - 4.418825,
      // y: shapeProps.y - 5,
      y: shapeProps.y,
      id: `${shapeProps.id}-clone`,
      name: "imageclone",
      width: 0,
      height: 0,
      stroke: shapeProps?.stroke,
      strokeWidth: shapeProps?.strokeWidth,
    })
    // addNewElement(null, clone)
    // events will also be cloned
    // so we need to disable dragstart
    clone.off("dragstart")

    // clone.opacity(0.5)
    // clone.zIndex(1)
    // then add to layer and start dragging new shape
    layer.add(clone)
    clone.moveTo(clonecontainer)
    clone.hide()
  }

  return (
    <React.Fragment>
      <>
        <Group
          clip={clipProps}
          ref={groupRef2}
          // x={shapeProps.x}
          // y={shapeProps.y}
          // width={shapeProps.width}
          // height={shapeProps.height}
          // draggable
          // clipFunc={(ctx) => {
          //   ctx.rect(
          //     clipProps.x,
          //     clipProps.y,
          //     clipProps.width,
          //     clipProps.height
          //   )
          // }}

          onDragEnd={(e) => {
            console.log(":::::: onDragEnd - e GROUP::::::")
            console.log(e)
            console.log("incoming shapeProps - GROUP")
            console.log(shapeProps)
            console.log(":::::: e.target.x() ::::::")
            console.log(e.target.x())
            console.log(":::::: e.target.y() ::::::")
            console.log(e.target.y())

            const group = groupRef2.current

            // const node = shapeRef2.current
            // const scaleX = node.scaleX()
            // const scaleY = node.scaleY()

            // 1. get relative offset (old x minus old x clip)
            // 2. set relative offset

            // const xClipOffset = clipX + e.target.x()
            // const yClipOffset = clipY + e.target.y()
            // const xClipOffset = 500 - 87
            // const yClipOffset = 0
            // const xClipOffset = clipX
            // const yClipOffset = clipY

            console.log('shapeProps.hasOwnProperty("xClipOffset")')
            console.log(shapeProps.hasOwnProperty("xClipOffset"))

            console.log('shapeProps.hasOwnProperty("yClipOffset")')
            console.log(shapeProps.hasOwnProperty("yClipOffset"))

            const xClipOffset = shapeProps.hasOwnProperty("xClipOffset")
              ? e.target.x() - shapeProps.xClipOffset
              : e.target.x()
            const yClipOffset = shapeProps.hasOwnProperty("yClipOffset")
              ? e.target.y() - shapeProps.yClipOffset
              : e.target.y()

            console.log("shapeProps - dragEnd - GROUP")
            console.log(shapeProps)
            console.log("group - dragEnd - GROUP")
            console.log(group)
            console.log("**...shapeProps.clip - GROUP")
            console.log(shapeProps.clip)
            console.log("**xClipOffset - GROUP")
            console.log(xClipOffset)
            console.log("**yClipOffset - GROUP")
            console.log(yClipOffset)

            const clip = {
              // x: e.target.x(),
              // y: e.target.y(),
              // ...shapeProps.clip,
              x: xClipOffset,
              y: yClipOffset,
              // x: clipX,
              // y: clipY,
              width: clipWidth,
              height: clipHeight,
            }

            // console.log("**clip")
            // console.log(clip)

            // const newX = shapeProps.x + e.target.x()
            // const newY = shapeProps.y + e.target.y()
            // // const newX = shapeProps.x + e.target.x()
            // // const newY = shapeProps.y + e.target.y()

            // console.log("**newX")
            // console.log(newX)
            // console.log("**newY")
            // console.log(newY)
            onChange({
              ...shapeProps,
              // x: newX,
              // y: newY,
              // x: shapeProps.x,
              // y: shapeProps.y,
              clip,
            })
            group.getLayer().draw()
          }}
        >
          <Image
            onClick={onSelect}
            onTap={onSelect}
            ref={shapeRef2}
            {...shapePropsRevised}
            // stroke="green"
            // strokeWidth={2}
            // {...shapeProps}
            // cropX={clipX}
            // cropY={clipY}
            // cropWidth={clipWidth}
            // cropHeight={clipHeight}
            image={image}
            draggable
            onDragStart={(e) => {
              const node = shapeRef2.current
              // const nodeX = node.x()
              // const nodeY = node.y()
              const group = groupRef2.current
              const imageclone = stageRef.current.find(".imageclone")

              // stop dragging original rect
              // node.stopDrag()

              node.hide()
              imageclone.width(node.width())
              imageclone.height(node.height())
              // imageclone.x(nodeX)
              // imageclone.y(nodeY)

              imageclone.opacity(0.5)
              imageclone.show()
              imageclone.startDrag()
            }}
            onDragMove={(e) => {
              const imageclone = stageRef.current.find(".imageclone")
              imageclone.x(e.target.x())
              imageclone.y(e.target.y())
            }}
            onDragEnd={(e) => {
              const node = shapeRef2.current

              const imageclone = stageRef.current.find(".imageclone")

              imageclone.height(0)
              imageclone.width(0)
              // imageclone.hide()
              node.show()

              onChange({
                ...shapeProps,
                x: e.target.x(),
                y: e.target.y(),
              })
            }}
            onTransformEnd={(e) => {
              console.log("**onTransformEnd")
              console.log(e)
              // transformer is changing scale of the node
              // and NOT its width or height
              // but in the store we have only width and height
              // to match the data better we will reset scale on transform end
              const node = shapeRef2.current
              const group = groupRef2.current
              const img = imageRef.current
              const tr = trRef2.current
              const scaleX = node.scaleX()
              const scaleY = node.scaleY()
              const absPos = node.getAbsolutePosition(group)

              // at first lets find position of text node relative to the stage:
              const nodePosition = node.absolutePosition()
              const groupPosition = group.absolutePosition()

              // then lets find position of stage container on the page:
              const stageBox = stageRef.current
                .container()
                .getBoundingClientRect()

              const scrollTop =
                window.pageYOffset !== undefined
                  ? window.pageYOffset
                  : (
                      document.documentElement ||
                      document.body.parentNode ||
                      document.body
                    ).scrollTop

              // so position of textarea will be the sum of positions above:
              const areaPosition = {
                x: stageBox.left + nodePosition.x,
                y: stageBox.top + nodePosition.y + scrollTop,
              }

              console.log("**groupPosition")
              console.log(groupPosition)
              console.log("**nodePosition")
              console.log(nodePosition)
              console.log("**stageBox")
              console.log(stageBox)
              console.log("**scrollTop")
              console.log(scrollTop)
              console.log("**areaPosition")
              console.log(areaPosition)

              console.log("**absPos")
              console.log(absPos)
              setScaleX(scaleX)
              setScaleY(scaleY)

              console.log("**scaleX")
              console.log(scaleX)
              console.log("**scaleY")
              console.log(scaleY)
              console.log("**node")
              console.log(node)
              console.log("**group")
              console.log(group)
              node.scaleX(1)
              node.scaleY(1)

              // 1. determine offset from box
              // 2. multiply by scale
              // 3. subtract from new

              const xClipOffset = e.target.x() - (shapeProps.x - clipX) * scaleX
              const yClipOffset = e.target.y() - (shapeProps.y - clipY) * scaleY

              console.log("**xClipOffset")
              console.log(xClipOffset)
              console.log("**yClipOffset")
              console.log(yClipOffset)

              const clip = {
                // x: e.target.x(),
                // y: e.target.y(),
                x: xClipOffset,
                y: yClipOffset,
                width: e.target.width() * scaleX,
                height: e.target.height() * scaleY,
              }

              onChange({
                ...shapeProps,
                x: groupPosition.x + node.x(),
                y: groupPosition.y + node.y(),
                // set minimal value
                width: Math.max(5, node.width() * scaleX),
                height: Math.max(node.height() * scaleY),
                clip,
              })
            }}
          />
        </Group>
        <Group ref={cloneContainer} opacity={0.5}></Group>
        {isSelected && (
          <Transformer
            ref={trRef2}
            // keepRatio={false}
            boundBoxFunc={(oldBox, newBox) => {
              // limit resize
              if (newBox.width < 5 || newBox.height < 5) {
                return oldBox
              }
              return newBox
            }}
          />
        )}
      </>
    </React.Fragment>
  )
}

export default ImageSnippet
