//React
import React, { useRef, useState, useEffect } from "react"

//Packages
import { Image } from "react-konva"

//Components
import ImageSubCrop from "./imageSubCrop"
import ImageSubResize from "./imageSubResize"
import ImageSubStatic from "./imageSubStatic"

const URLImage = ({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  stageRef,
  // layerRef,
  src,
  x,
  y,
  updateCanvasImageLoading,
  imageMode,
  addNewElement,
  changeAttr,
  pos,
  webpSupport,
}) => {
  // console.log("URLIMAGE = pos")
  // console.log(pos)

  const clipped = shapeProps && shapeProps.clip ? true : false
  // const clipX =
  //   shapeProps && shapeProps.clip && shapeProps.clip.x
  //     ? shapeProps.clip.x
  //     : shapeProps.x
  //     ? shapeProps.x
  //     : 0

  // const clipY =
  //   shapeProps && shapeProps.clip && shapeProps.clip.y
  //     ? shapeProps.clip.y
  //     : shapeProps.y
  //     ? shapeProps.y
  //     : 0

  // const clipWidth =
  //   shapeProps && shapeProps.clip && shapeProps.clip.width
  //     ? shapeProps.clip.width
  //     : shapeProps.width
  //     ? shapeProps.width
  //     : 300

  // const clipHeight =
  //   shapeProps && shapeProps.clip && shapeProps.clip.height
  //     ? shapeProps.clip.height
  //     : shapeProps.height
  //     ? shapeProps.height
  //     : 300

  const clipX =
    shapeProps &&
    shapeProps.hasOwnProperty("clip") &&
    shapeProps.clip.hasOwnProperty("x")
      ? shapeProps.clip.x
      : shapeProps.x

  const clipY =
    shapeProps &&
    shapeProps.hasOwnProperty("clip") &&
    shapeProps.clip.hasOwnProperty("y")
      ? shapeProps.clip.y
      : shapeProps.y

  // const clipX = 0
  // const clipY = 0
  const clipWidth =
    shapeProps && shapeProps.clip && shapeProps.clip.hasOwnProperty("width")
      ? shapeProps.clip.width
      : shapeProps.width

  const clipHeight =
    shapeProps && shapeProps.clip && shapeProps.clip.hasOwnProperty("height")
      ? shapeProps.clip.height
      : shapeProps.height

  // console.log("shapeProps.id")
  // console.log(shapeProps.id)
  // console.log("shapeProps.src")
  // console.log(shapeProps.src)
  // console.log("=====clipX")
  // console.log(clipX)
  // console.log("=====clipY")
  // console.log(clipY)
  // console.log("=====clipWidth")
  // console.log(clipWidth)
  // console.log("=====clipHeight")
  // console.log(clipHeight)

  const imageRef = useRef(null)
  const [image, setImage] = useState(null)
  const [imageIsLoading, setImageLoading] = useState(false)
  // let nativeCtx

  useEffect(() => {
    console.log("useEffect - initial - pos")
    console.log(pos)
    loadImage()
    // return () => {
    //   if (imageRef.current) {
    //     imageRef.current.removeEventListener("load", handleLoad)
    //   }
    // }
  }, [])

  useEffect(() => {
    loadImage()
    // console.log("considered - imageRef SRC - batchDraw")
  }, [src])

  // useEffect(() => {
  //   console.log("considered - layerRef SRC - layerRef")
  //   console.log(layerRef)
  //   // important do that AFTER you added layer to a stage

  //   if (layerRef && layerRef.current) {
  //     console.log("considered - layerRef - SETTING SMOOTHING")

  //     nativeCtx = layerRef.current.getContext()._context
  //     nativeCtx.webkitImageSmoothingEnabled = true
  //     nativeCtx.mozImageSmoothingEnabled = true
  //     nativeCtx.imageSmoothingEnabled = true
  //   }
  // }, [layerRef])

  function getCrop(image, size, clipPosition = "center-middle") {
    const width = size.width
    const height = size.height
    const aspectRatio = width / height

    let newWidth
    let newHeight

    const imageRatio = image.width / image.height

    if (aspectRatio >= imageRatio) {
      newWidth = image.width
      newHeight = image.width / aspectRatio
    } else {
      newWidth = image.height * aspectRatio
      newHeight = image.height
    }

    let x = 0
    let y = 0
    if (clipPosition === "left-top") {
      x = 0
      y = 0
    } else if (clipPosition === "left-middle") {
      x = 0
      y = (image.height - newHeight) / 2
    } else if (clipPosition === "left-bottom") {
      x = 0
      y = image.height - newHeight
    } else if (clipPosition === "center-top") {
      x = (image.width - newWidth) / 2
      y = 0
    } else if (clipPosition === "center-middle") {
      x = (image.width - newWidth) / 2
      y = (image.height - newHeight) / 2
    } else if (clipPosition === "center-bottom") {
      x = (image.width - newWidth) / 2
      y = image.height - newHeight
    } else if (clipPosition === "right-top") {
      x = image.width - newWidth
      y = 0
    } else if (clipPosition === "right-middle") {
      x = image.width - newWidth
      y = (image.height - newHeight) / 2
    } else if (clipPosition === "right-bottom") {
      x = image.width - newWidth
      y = image.height - newHeight
    } else if (clipPosition === "scale") {
      x = 0
      y = 0
      newWidth = width
      newHeight = height
    } else {
      console.error(
        new Error("Unknown clip position property - " + clipPosition)
      )
    }

    return {
      x,
      y,
      width: newWidth,
      height: newHeight,
    }
  }
  function handleLoad() {
    setImage(imageRef.current)
  }

  function loadImage() {
    updateCanvasImageLoading(true)
    const img = new window.Image()
    img.crossOrigin = "anonymous"
    // console.log("loadImage - outside - pos")
    // console.log(pos)

    img.onload = () => {
      updateCanvasImageLoading(false)
      const height = img.height
      const width = img.width
      const crop = getCrop(
        img,
        { width: shapeProps.width, height: shapeProps.height },
        "center-middle"
      )
      // console.log("img.onload --- crop")
      // console.log(crop)
      // console.log("img.onload --- pos")

      // console.log(pos)
      changeAttr("image", "crop", crop, pos)
      setImage(img)
    }
    console.log("+++ IMAGE +++ SRC +++")
    console.log(src)
    img.onerror = () => {
      console.log(`ONERROR IMG - ${src}`)
    }
    //Need to convert https to http per https://zyst.io/how-to-fix-aws-s3-chrome-and-safari-cors-on-images
    const imgSrcWithFileExt = webpSupport ? src : src.replace(".webp", ".jpg")

    if (process.env.STAGE === "dev") {
      img.src = imgSrcWithFileExt.replace(/^https:\/\//i, "http://")
    } else {
      img.src = imgSrcWithFileExt
    }
    // imageRef.current = img

    // imageRef.current.addEventListener("load", handleLoad)
  }

  return (
    <React.Fragment>
      {imageIsLoading ? (
        <Image imageRef={imageRef} image={image} />
      ) : isSelected ? (
        imageMode === "crop" ? (
          <ImageSubCrop
            stageRef={stageRef}
            imageRef={imageRef}
            isSelected={isSelected}
            shapeProps={shapeProps}
            onSelect={onSelect}
            onChange={onChange}
            clipped={clipped}
            clipX={clipX}
            clipY={clipY}
            clipWidth={clipWidth}
            clipHeight={clipHeight}
            image={image}
          />
        ) : imageMode === "resize" ? (
          <ImageSubResize
            addNewElement={addNewElement}
            stageRef={stageRef}
            imageRef={imageRef}
            isSelected={isSelected}
            shapeProps={shapeProps}
            onSelect={onSelect}
            onChange={onChange}
            clipped={clipped}
            clipX={clipX}
            clipY={clipY}
            clipWidth={clipWidth}
            clipHeight={clipHeight}
            image={image}
          />
        ) : null
      ) : (
        <ImageSubStatic
          stageRef={stageRef}
          imageRef={imageRef}
          isSelected={isSelected}
          shapeProps={shapeProps}
          onSelect={onSelect}
          onChange={onChange}
          clipped={clipped}
          clipX={clipX}
          clipY={clipY}
          clipWidth={clipWidth}
          clipHeight={clipHeight}
          image={image}
        />
      )}
    </React.Fragment>
  )
}

export default URLImage
