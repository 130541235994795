//React
import React, { useState } from "react"

//Packages
import { SketchPicker } from "react-color"

//Components
import SidebarTitle from "../sidebarTitle"
import DeleteElement from "./deleteElement"

//Icons
import {
  MdFormatColorFill,
  MdLineWeight,
  MdBorderColor,
  MdRoundedCorner,
} from "react-icons/md"
import { GrTopCorner, GrBottomCorner } from "react-icons/gr"

//Constants
import { defaultSwatches } from "../../../utils/constants"

const Snippet = ({
  changeAttr,
  elements,
  elementSelected,
  context,
  removeSelectedElement,
}) => {
  const currentElement = elements.find(
    (element) => element.id === elementSelected.id
  )
  const [collapse, setCollapse] = useState(true)
  const [strokeWidthCollapse, setStrokeWidthCollapse] = useState(true)
  const [strokeColorCollapse, setStrokeColorCollapse] = useState(true)
  const [cornerRadiusCollapse, setCornerRadiusCollapse] = useState(true)

  const strokeSizes = []

  for (let i = 0.5; i <= 10; i += 0.5) {
    strokeSizes.push(
      <button
        key={i}
        class={`btn-strokewidth btn btn-slim px-3 ${
          i === 0.5 ? "round-corners-top" : ""
        } ${i === 5 ? "round-corners-bottom" : ""}`}
        onClick={() => {
          changeAttr("image", "strokeWidth", i)
          // if (!currentElement?.stroke) {
          //   changeAttr("image", "stroke", "#000000")
          // }
          setStrokeWidthCollapse(true)
        }}
      >
        <span
          class={`small ${
            currentElement?.strokeWidth === i
              ? "font-weight-bold"
              : "font-weight-normal"
          }`}
        >
          {i}
        </span>
      </button>
    )
  }

  return (
    <>
      <div class="d-none d-md-block w-100 my-2">
        <SidebarTitle text="Shape attributes" />
      </div>
      <div class="d-flex flex-row flex-wrap align-items-start justify-content-start p-1">
        <button
          class="btn btn-slim btn-tool-add p-1 mr-1"
          type="button"
          // data-toggle="collapse"
          // data-target="#shapeColor"
          // aria-expanded="false"
          aria-controls="shapeColor"
          onClick={() => {
            setCollapse(!collapse)
            setStrokeWidthCollapse(true)
            setStrokeColorCollapse(true)
            setCornerRadiusCollapse(true)
          }}
        >
          <MdFormatColorFill
            size={22}
            color={
              currentElement &&
              currentElement.fill &&
              currentElement?.fill === "#ffffff"
                ? "#cccccc"
                : currentElement.fill
                ? currentElement.fill
                : "#000000"
            }
          />
        </button>
        <button
          class="btn btn-slim btn-tool-add p-1 mr-1"
          type="button"
          // data-toggle="collapse"
          // data-target="#shapeColor"
          // aria-expanded="false"
          aria-controls="cornerRadius "
          onClick={() => {
            setCollapse(true)
            setStrokeWidthCollapse(true)
            setStrokeColorCollapse(true)
            setCornerRadiusCollapse(false)
          }}
        >
          <MdRoundedCorner size={22} />
        </button>
        <button
          class="btn btn-slim btn-tool-add p-1 mr-1"
          type="button"
          // data-toggle="collapse"
          // data-target="#shapeColor"
          // aria-expanded="false"
          aria-controls="shapeColor"
          onClick={() => {
            setCollapse(true)
            setStrokeWidthCollapse(!strokeWidthCollapse)
            setStrokeColorCollapse(true)
            setCornerRadiusCollapse(true)
          }}
        >
          <MdLineWeight size={22} />
        </button>
        <button
          class="btn btn-slim btn-tool-add p-1"
          type="button"
          // data-toggle="collapse"
          // data-target="#shapeColor"
          // aria-expanded="false"
          aria-controls="shapeColor"
          onClick={() => {
            setCollapse(true)
            setStrokeWidthCollapse(true)
            setStrokeColorCollapse(!strokeColorCollapse)
            setCornerRadiusCollapse(true)
          }}
        >
          <MdBorderColor size={22} />
        </button>
        <DeleteElement removeSelectedElement={removeSelectedElement} />
      </div>
      <div>
        <div class={collapse ? "d-none" : "d-block"} id="shapeColor">
          <SketchPicker
            color={currentElement.fill}
            presetColors={defaultSwatches}
            onChangeComplete={(e) => {
              console.log("onChangeComplete - e")
              console.log(e)
              // changeAttr("image", "fill", e.hex)
              const colorValue = `rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`
              changeAttr(context, "fill", colorValue)
            }}
          />
        </div>
        <div
          class={cornerRadiusCollapse ? "d-none" : "d-block"}
          id="cornerRadius"
        >
          <div class="cornerRadius-container bg-gray-7 rounded-corners p-1 mt-2 w-100">
            <div class="row d-flex flex-row align-items-center justify-content-center">
              {/* Left hand side */}
              <div class="col-2 d-flex flex-column align-items-center justify-content-end">
                <div class="cornerRadius-item d-flex flex-column">
                  <input
                    type="text"
                    // class="form-control"
                    onChange={(e) => {
                      let defaultCornerVal = [0, 0, 0, 0]
                      let cornerRadiusVal =
                        currentElement && currentElement.cornerRadius
                          ? currentElement.cornerRadius
                          : defaultCornerVal

                      cornerRadiusVal[0] = parseInt(e.target.value)
                      changeAttr(context, "cornerRadius", cornerRadiusVal)
                    }}
                    value={currentElement?.cornerRadius?.[0] || 0}
                  />
                </div>
                <div class="cornerRadius-item d-flex flex-column">
                  <input
                    type="text"
                    // class="form-control"
                    onChange={(e) => {
                      let cornerRadiusVal = currentElement?.cornerRadius
                      cornerRadiusVal[3] = parseInt(e.target.value)
                      changeAttr(context, "cornerRadius", cornerRadiusVal)
                    }}
                    value={currentElement?.cornerRadius?.[3] || 0}
                  />
                </div>
              </div>
              <div class="col-4 d-flex flex-column align-items-center justify-content-center">
                <div
                  class="m-2 p-2 border-1-gray-2 cornerRadius-demo-rect"
                  style={{
                    borderTopLeftRadius: currentElement?.cornerRadius?.[0] || 0,
                    borderTopRightRadius:
                      currentElement?.cornerRadius?.[1] || 0,
                    borderBottomRightRadius:
                      currentElement?.cornerRadius?.[2] || 0,
                    borderBottomLeftRadius:
                      currentElement?.cornerRadius?.[3] || 0,
                  }}
                ></div>
              </div>
              {/* Right hand side */}
              <div class="col-2 d-flex flex-column align-items-center justify-content-start">
                <div class="cornerRadius-item d-flex flex-column">
                  <input
                    type="text"
                    // class="form-control"
                    onChange={(e) => {
                      let cornerRadiusVal = currentElement?.cornerRadius
                      cornerRadiusVal[1] = parseInt(e.target.value)
                      changeAttr(context, "cornerRadius", cornerRadiusVal)
                    }}
                    value={currentElement?.cornerRadius?.[1] || 0}
                  />
                </div>
                <div class="cornerRadius-item d-flex flex-column">
                  <input
                    type="text"
                    // class="form-control"
                    onChange={(e) => {
                      let cornerRadiusVal = currentElement?.cornerRadius
                      cornerRadiusVal[2] = parseInt(e.target.value)
                      changeAttr(context, "cornerRadius", cornerRadiusVal)
                    }}
                    value={currentElement?.cornerRadius?.[2] || 0}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class={strokeColorCollapse ? "d-none" : "d-block"}
          id="strokeColor"
        >
          <SketchPicker
            color={currentElement.stroke}
            presetColors={defaultSwatches}
            onChangeComplete={(e) => {
              console.log("onChangeComplete - e")
              console.log(e)
              // changeAttr("image", "stroke", e.hex)
              const colorValue = `rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`
              changeAttr(context, "stroke", colorValue)

              // if (!currentElement?.strokeWidth) {
              //   changeAttr("image", "strokeWidth", 1)
              // }
              // setStrokeColorCollapse(false)
            }}
          />
        </div>
        <div
          class={`${
            strokeWidthCollapse ? "d-none" : "d-flex"
          } fade-in-now flex-column border-1-gray-5 round-corners`}
          id="strokeWidth"
        >
          {strokeSizes}
        </div>
      </div>
    </>
  )
}

export default Snippet
