import React from "react"

import { FaInstagram, FaSnapchatGhost, FaArrowRight } from "react-icons/fa"
import { MdFileDownload } from "react-icons/md"

import PlatformDownload from "./platformDownload"

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      toggleChecked: true,
    }
  }
  render() {
    const { imageSrc, currentOS, share } = this.props

    // const imageSrc =
    //   this.props.landingdetail && this.props.landingdetail.id
    //     ? `https://storylavamedia.s3.amazonaws.com/session/${this.props.landingdetail.id}/video/video.mp4`
    //     : ""

    let platformName
    let platformAbbr
    let platformShow

    const targetPlatform = share && share.intent ? share.intent : ""

    if (targetPlatform === "instagram") {
      platformName = "Instagram"
      platformAbbr = "ig"
      platformShow = true
    } else if (targetPlatform === "snapchat") {
      platformName = "Snapchat"
      platformAbbr = "sn"
      platformShow = true
    } else if (targetPlatform === "tiktok") {
      platformName = "TikTok"
      platformAbbr = "tk"
      platformShow = true
    } else if (targetPlatform === "download") {
      platformName = "Download"
      platformAbbr = "dl"
      platformShow = true
    } else {
      platformShow = false
    }
    return (
      <>
        {targetPlatform && platformShow ? (
          <div class="modal-social bg-gray-7 rounded-corners w-75 mt-3 px-2">
            <div class="d-flex flex-row align-items-center justify-content-center">
              <h6
                class="text-center font-weight-light"
                style={{ paddingTop: 8, marginRight: 8 }}
              >
                {platformName !== "Download" ? "Share on" : null} {platformName}
              </h6>
              {/* {targetPlatform === "instagram" ? (
                <FaInstagram size={30} color="#444444" />
              ) : null}
              {targetPlatform === "snapchat" ? (
                <FaSnapchatGhost size={24} color="#444444" />
              ) : null}
              {targetPlatform === "tiktok" ? (
                <img
                  width="26"
                  height="26"
                  src="https://storylavamedia.s3.amazonaws.com/static/icons/tiktok.svg"
                />
              ) : null} */}
            </div>
            <div
              class="d-flex flex-column align-items-center justify-content-center"
              style={{ margin: "auto" }}
            >
              {/* ::::::: STEP 1 :: DOWNLOAD THE VIDEO :::::::*/}
              <div class="row row-share d-flex flex-row align-items-center justify-content-start">
                <div class="share-number">
                  {currentOS === "iOS" ? (
                    "1"
                  ) : platformName === "Download" ? (
                    <FaArrowRight
                      color="#f2f2f2"
                      size={16}
                      style={{ marginTop: -4 }}
                    />
                  ) : (
                    "1"
                  )}
                </div>
                <div class="share-instruction">Tap below to download </div>
                {/* <div class="share-instruction-sub">
        Tap below to download video
      </div> */}
                <div class="share-instruction-sub mt-2">
                  <a
                    class="btn btn-primary btn-sm"
                    href={imageSrc}
                    target="_blank"
                    rel="noopener"
                  >
                    Download <MdFileDownload size={16} color="#ffffff" />
                  </a>
                </div>
              </div>
              {/* ::::::: STEP 2 :: OPEN THE DOWNLOAD FOLDER :::::::*/}
              {currentOS === "iOS" ? (
                <div class="row row-share d-flex flex-row align-items-center justify-content-start">
                  <div class="share-number">
                    {currentOS === "iOS" ? "2" : "2"}
                  </div>
                  <div class="share-instruction">
                    Select the asset in the Downloads folder
                  </div>
                  <div class="share-instruction-sub">
                    Tap the arrow in the upper right-hand corner and select the
                    asset.
                    <div class="w-100">
                      <img
                        class="mt-2"
                        style={{ borderRadius: 8, maxWidth: 200 }}
                        srcSet="https://storylavamedia.s3.amazonaws.com/static/help/downloads%403x.jpg 3x, https://storylavamedia.s3.amazonaws.com/static/help/downloads%402x.jpg 2x, https://storylavamedia.s3.amazonaws.com/static/help/downloads%401x.jpg 1x"
                        src="https://storylavamedia.s3.amazonaws.com/static/help/downloads%401x.jpg"
                      />
                    </div>
                  </div>
                  {/* <div class="share-instruction-sub mt-2">
          <a class="btn btn-primary btn-sm" href={imageSrc} download>
            Download <MdFileDownload size={16} color="#ffffff" />
          </a>
        </div> */}
                </div>
              ) : null}

              {/* ::::::: STEP 3 :: SAVE TO CAMERA ROLL :::::::*/}

              {/* {currentOS === "iOS" ? (
      <div class="row-share">
        <div class="row d-flex flex-row align-items-center justify-content-start">
          <div class="share-number">1</div>
          <div class="share-instruction">
            Is {platformName} installed on this device?
          </div>
        </div>
        <div class="share-instruction-sub">
          <div class="row d-flex flex-row align-items-center justify-content-start">
            {this.state.toggleChecked ? (
              <div class="toggle-label">Yes</div>
            ) : (
              <div class="toggle-label">No</div>
            )}
            <div class="pl-3">
              <Switch
                checked={this.state.toggleChecked}
                onColor="rgb(48, 122, 219)"
                onChange={checked => {
                  this.setState({
                    toggleChecked: !this.state.toggleChecked,
                  })
                }}
              />
            </div>
          </div>
        </div>
      </div>
    ) : null} */}
              {/* End is iOS */}
              {currentOS !== "Android" &&
              currentOS !== "iOS" &&
              platformName !== "Download" ? (
                <div class="row row-share d-flex flex-row align-items-center justify-content-start">
                  <div class="share-number">2</div>
                  <div class="share-instruction">
                    Open the {platformName} app on your phone
                  </div>
                </div>
              ) : null}
              {/* End is iOS */}

              {currentOS === "iOS" ? (
                <div class="row row-share d-flex flex-row align-items-center justify-content-start">
                  <div class="share-number">
                    {currentOS === "iOS" ? "3" : "3"}
                  </div>
                  <div class="share-instruction">
                    Tap the share button &amp; save to Camera Roll
                  </div>
                  <div class="share-instruction-sub">
                    Find the share button in the lower left-hand corner . Then
                    press Save Video.
                    <div class="d-flex align-items-center justify-content-start mt-2">
                      <img
                        style={{ borderRadius: 8 }}
                        srcSet={`https://storylavamedia.s3.amazonaws.com/static/help/share-button%403x.jpg 3x, https://storylavamedia.s3.amazonaws.com/static/help/share-button%402x.jpg 2x, https://storylavamedia.s3.amazonaws.com/static/help/share-button%401x.jpg 1x`}
                        src={`https://storylavamedia.s3.amazonaws.com/static/help/share-button%401x.jpg`}
                      />
                      <div class="p-1">
                        <FaArrowRight size={26} />
                      </div>

                      <img
                        style={{ borderRadius: 8 }}
                        srcSet={`https://storylavamedia.s3.amazonaws.com/static/help/share-savevideo%403x.jpg 3x, https://storylavamedia.s3.amazonaws.com/static/help/share-savevideo%402x.jpg 2x, https://storylavamedia.s3.amazonaws.com/static/help/share-savevideo%401x.jpg 1x`}
                        src={`https://storylavamedia.s3.amazonaws.com/static/help/share-savevideo%401x.jpg`}
                      />
                    </div>
                  </div>
                  {/* <div class="share-instruction-sub mt-2">
        <a class="btn btn-primary btn-sm" href={imageSrc} download>
          Download <MdFileDownload size={16} color="#ffffff" />
        </a>
      </div> */}
                </div>
              ) : null}
              {currentOS === "iOS" || currentOS === "Android" ? (
                platformName !== "Download" ? (
                  <div class="row row-share d-flex flex-row align-items-center justify-content-start">
                    <div class="share-number">
                      {currentOS === "iOS" ? "4" : "2"}
                    </div>
                    {this.state.toggleChecked ? (
                      <div class="share-instruction">
                        Open {platformName} &amp; share
                      </div>
                    ) : (
                      <div class="share-instruction">
                        Download {platformName} &amp; share
                      </div>
                    )}
                    <PlatformDownload
                      currentOS={currentOS}
                      platformName={platformName}
                      toggleChecked={this.state.toggleChecked}
                    />
                  </div> // End row-share
                ) : null
              ) : platformName !== "Download" ? (
                <div class="row row-share d-flex flex-row align-items-center justify-content-start">
                  <div class="share-number">3</div>
                  <div class="share-instruction">Upload and share</div>
                </div>
              ) : null}
              {/* End Step 3 if iOS/Android */}
            </div>
          </div>
        ) : null}
      </>
    )
  }
}
export default Snippet
