import React from "react"

//Packages
import { Image, Transformer, Group, Rect } from "react-konva"

const ImageSnippet = ({
  stageRef,
  imageRef,
  isSelected,
  shapeProps,
  onSelect,
  onChange,
  clipped,
  clipX,
  clipY,
  clipWidth,
  clipHeight,
  image,
}) => {
  const shapeRef2 = React.useRef()
  const trRef2 = React.useRef()
  const rectRef2 = React.useRef()
  const groupRef2 = React.useRef()

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef2.current.nodes([rectRef2.current]) //different from resize
      trRef2.current.getLayer().batchDraw()
    }
  }, [isSelected])

  // console.log("****shapeProps")
  // console.log(shapeProps)
  // console.log("****clipX")
  // console.log(clipX)
  // console.log("****clipY")
  // console.log(clipY)
  // console.log("****clipWidth")
  // console.log(clipWidth)
  // console.log("****clipHeight")
  // console.log(clipHeight)

  return (
    <React.Fragment>
      <>
        <Group
          ref={groupRef2}
          name={`group${shapeProps.id}`}
          clipFunc={(ctx) => {
            const tr = rectRef2.current

            if (tr) {
              ctx.rect(
                tr.x(),
                tr.y(),
                tr.width() * tr.scaleX(),
                tr.height() * tr.scaleY()
              )
            }
          }}
        >
          <Image ref={shapeRef2} {...shapeProps} image={image} />
        </Group>
        {isSelected && <Transformer ref={trRef2} />}
        <Rect
          onClick={onSelect}
          onTap={onSelect}
          ref={rectRef2}
          width={clipWidth}
          height={clipHeight}
          x={clipX}
          y={clipY}
          fill="rgba(0,0,0,0)"
          draggable={true}
          onDragEnd={(e) => {
            onChange({
              ...shapeProps,
              clip: {
                ...shapeProps.clip,
                x: e.target.x(),
                y: e.target.y(),
              },
            })
          }}
          onTransformEnd={(e) => {
            // transformer is changing scale of the node
            // and NOT its width or height
            // but in the store we have only width and height
            // to match the data better we will reset scale on transform end
            const group = groupRef2.current
            const rect = rectRef2.current
            const scaleX = rect.scaleX()
            const scaleY = rect.scaleY()

            rect.scaleX(1)
            rect.scaleY(1)

            console.log("crop - transform - shapeProps")
            console.log(shapeProps)
            console.log("crop - transform - e")
            console.log(e)
            console.log("crop - transform - rect")
            console.log(rect)
            console.log("crop - transform - clipX")
            console.log(clipX)
            console.log("crop - transform - clipY")
            console.log(clipY)
            console.log("crop - transform - scaleX")
            console.log(scaleX)
            console.log("crop - transform - scaleY")
            console.log(scaleY)

            const xClipOffset = shapeProps.x - rect.x()
            const yClipOffset = shapeProps.y - rect.y()

            onChange({
              ...shapeProps,
              clip: {
                x: rect.x(),
                y: rect.y(),
                width: Math.max(5, rect.width() * scaleX),
                height: Math.max(rect.height() * scaleY),
              },
              xClipOffset,
              yClipOffset,
            })
            group.getLayer().draw()
          }}
        ></Rect>
      </>
    </React.Fragment>
  )
}

export default ImageSnippet
