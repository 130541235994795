import React from "react"

const Snippet = (props) => {
  const { pos, title, color, width, active, onSelect } = props

  return (
    <button
      class={`btn btn-slim btn-timeline-${color} ${active ? `active` : ""}`}
      // style={{
      //   width: 50,
      //   height: 50,
      // }}
      // onClick={() =>
      //   updatePublisherField("stage", {
      //     ...stage,
      //     id: template.id,
      //     w: template.width > 500 ? 500 : template.width,
      //     h:
      //       template.width > 500
      //         ? (template.height * 500) / template.width //solve the proporation equation
      //         : template.height,
      //     pixelRatio:
      //       template.width > 500 || template.height > 500
      //         ? template.width / 500
      //         : 1,
      //   })
      // }
      onClick={onSelect}
    >
      <div class="timeline-element-container">
        <div
          class={`timeline-element border-bottom-2-${color}-1 d-flex flex-row align-items-center justify-content-start`}
          style={{ width: width, height: 27 }}
        >
          <div
            style={{ width: 25, height: 25 }}
            class={`timeline-element-label bg-${color}-1 white-1 p-1 d-flex align-items-center justify-content-center text-center font-weight-normal`}
          >
            {pos}
          </div>
          <div class={`timeline-element-text text-left ml-2 pb-0`}>{title}</div>
        </div>
      </div>
    </button>
  )
}

export default Snippet
