//React
import React from "react"

//Actions

//Components

//Icons

//Exceptions

//Redux

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return <h4>Users</h4>
  }
}

export default Snippet
// export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
