import React, { useState } from "react"
import { connect } from "react-redux"

//Actions
import {
  sendUpload,
  resetImageUpload,
  updateFormsField,
  deleteImages,
} from "../../actions/createPublisher"
import { updateFormField } from "../../actions/updateForm"

//Components
import ImageLibraryForm from "./imageLibraryForm"

//Icons

const mapStateToProps = ({
  publisher,
  forms,
  socialmodal,
  isLoadingImageUpload,
  isLoading,
  imageuploadprogress,
  imagelibrary,
  isLoadingImageDelete,
}) => {
  return {
    publisher,
    forms,
    socialmodal,
    isLoadingImageUpload,
    isLoading,
    imageuploadprogress,
    imagelibrary,
    isLoadingImageDelete,
  }
}
const mapDispatchToProps = {
  updateFormField,
  sendUpload,
  resetImageUpload,
  updateFormsField,
  deleteImages,
}

const Snippet = (props) => {
  const {
    publisher,
    forms,
    socialmodal,
    saveTemplateComplete,
    isLoadingImageUpload,
    updateFormField,
    clearForm,
    imageuploadprogress,
    changeAttr,
    sendUpload,
    resetImageUpload,
    updateFormsField,
    loadImageLibrary,
    imagelibrary,
    userRole,
    isLoadingImageDelete,
    deleteImages,
    isLoading,
  } = props

  const publisherMode = publisher && publisher.mode ? publisher.mode : "image"

  const [selectMode, setSelectMode] = useState(false)

  // console.log("imageLibraryModal - userRole")
  // console.log(userRole)

  return (
    <div
      class="modal modal-sharer fade"
      id="imageLibraryModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="imageLibraryModalLabel"
      aria-hidden="true"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header" style={{ borderBottom: 0 }}>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                updateFormsField("previewImageLoaded", false)
                setSelectMode(false)
                resetImageUpload()
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="modal-subheader d-flex flex-row align-items-center justify-content-start">
              <h4
                class="text-left pl-4 font-weight-bold"
                style={{ paddingTop: 8, marginRight: 8 }}
              >
                {imagelibrary === "user" ? "Your images" : ""}
                {imagelibrary === "brand" ? "Brand images" : ""}
              </h4>
            </div>

            <div
              class="modal-content-container d-flex flex-column align-items-center justify-content-center"
              style={{ margin: "auto" }}
            >
              <ImageLibraryForm
                context={publisherMode}
                publisher={publisher}
                imagelibrary={imagelibrary}
                isLoadingImageUpload={isLoadingImageUpload}
                imageuploadprogress={imageuploadprogress}
                changeAttr={changeAttr}
                sendUpload={sendUpload}
                forms={forms}
                resetImageUpload={resetImageUpload}
                updateFormsField={updateFormsField}
                loadImageLibrary={loadImageLibrary}
                userRole={userRole}
                isLoadingImageDelete={isLoadingImageDelete}
                deleteImages={deleteImages}
                selectMode={selectMode}
                setSelectMode={setSelectMode}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// export default Snippet
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
// export default connect(mapStateToProps, null)(Snippet)
