import React, { useEffect, useState } from "react"

//Components
import Loading from "../loading"

//Icons

const Snippet = (props) => {
  const { clearCanvas } = props

  // console.log("****userSub")
  // console.log(userSub)
  // console.log("****props.publisher")
  // console.log(props.publisher)

  return (
    <div>
      <p class="px-5 py-4 alert-modal-description line-height-3 lead text-center">
        Do you want to clear the canvas?
      </p>
      <div class="d-flex flex-row align-items-center justify-content-center">
        <button
          class="btn btn-link"
          type="button"
          data-dismiss="modal"
          aria-label="Close"
        >
          Cancel
        </button>
        <button
          class="btn btn-pink"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            clearCanvas()
          }}
        >
          Clear canvas
        </button>
      </div>
    </div>
  )
}
export default Snippet
