//React
import React, { useCallback } from "react"

//Packages

//Components
import SidebarTitle from "../sidebarTitle"
import LayerItem from "./layerItem"

import { FaRegEye, FaRegEyeSlash } from "react-icons/fa"
import { IoIosSquare, IoIosSquareOutline } from "react-icons/io"

const Snippet = ({
  title,
  publisherMode,
  sceneSelected,
  sceneLayers,
  updatePublisherField,
  changeAttr,
  sendUpload,
  reorderLayers,
}) => {
  // console.log("-----publisherMode")
  // console.log(publisherMode)
  // console.log("-----sceneLayers")
  // console.log(sceneLayers)
  const ItemTypes = {
    LAYER: "layer",
  }

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      console.log("dragIndex")
      console.log(dragIndex)
      console.log("hoverIndex")
      console.log(hoverIndex)
      // const dragCard = sceneLayers[dragIndex];
      // setCards(update(cards, {
      //     $splice: [
      //         [dragIndex, 1],
      //         [hoverIndex, 0, dragCard],
      //     ],
      // }));
      reorderLayers(dragIndex, hoverIndex)
    },
    [sceneLayers]
  )

  return (
    <>
      <div class="d-none d-md-block w-100">
        {title ? <SidebarTitle text={title} /> : null}
      </div>

      <div class="layer-container py-2 px-1 mb-4 w-100 d-flex flex-column-reverse">
        {sceneLayers
          ? sceneLayers.map((layer, i) => {
              let layerName
              // console.log("-----layer")
              // console.log(layer)
              if (publisherMode === "image") {
                //Uppercase the first letter, see https://flaviocopes.com/how-to-uppercase-first-letter-javascript/
                layerName =
                  layer.shape.charAt(0).toUpperCase() + layer.shape.slice(1)

                if (layerName === "Rect") layerName = "Rectangle"
              } else if (publisherMode === "video") {
                layerName = layer.name
              }

              return (
                <LayerItem
                  key={`layer-${i}`}
                  index={i}
                  moveCard={moveCard}
                  id={layer.id ? layer.id : `layer${i}`}
                  layer={layer}
                  layerName={layerName}
                  sceneSelected={sceneSelected}
                  publisherMode={publisherMode}
                  changeAttr={changeAttr}
                  updatePublisherField={updatePublisherField}
                  ItemTypes={ItemTypes}
                />
              )
            })
          : null}
      </div>
    </>
  )
}

export default Snippet
