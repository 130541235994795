//React
import React, { useState } from "react"
import { connect } from "react-redux"

//Actions
import {
  updatePublisherField,
  removeTemplate,
  loadNewTemplate,
  loadSocialUserTemplates,
  loadSocialKeywordTemplates,
} from "../../actions/createPublisher"
import {
  updateFormField,
  updateTemplateCollapse,
} from "../../actions/updateForm"

//Components
import Loading from "../loading"
import SidebarTitle from "./sidebarTitle"
import ConsoleHomeFeed from "../appHome/consoleHomeFeed"
import FeatureIcon from "../elements/featureIcon"

//Icons
// import { MdClear } from "react-icons/md"
import { HiOutlinePencilAlt } from "react-icons/hi"
import { MdSearch, MdClose } from "react-icons/md"

//Redux
const mapStateToProps = ({
  publisher,
  templatetags,
  social,
  isLoading,
  isLoadingTemplateCreate,
  templateTrayIsCollapsed,
  forms,
}) => {
  return {
    publisher,
    templatetags,
    social,
    isLoading,
    isLoadingTemplateCreate,
    templateTrayIsCollapsed,
    forms,
  }
}

const mapDispatchToProps = {
  updateFormField,
  updatePublisherField,
  removeTemplate,
  loadSocialUserTemplates,
  loadSocialKeywordTemplates,
  loadNewTemplate,
  updateTemplateCollapse,
}

const loadTargetItem = (props, template) => {
  if (props.mode === "elements") {
    addNewElement(template.elements[0].data)
  } else {
    loadNewTemplate(template.id, template.context).then(() => {
      console.log("loadNewTemplate - THEN ***")
      // redrawStage()
    })
  }
}
const renderTemplate = (props, template, templateSelected) => {
  const {
    loadNewTemplate,
    loadSocialUserTemplates,
    addNewElement,
    setDrawerOpen,
  } = props

  return (
    <div class="d-flex flex-column mb-1">
      <button
        class={`btn btn-slim ${
          templateSelected === template.id ? "border-2-dkblue-1" : ""
        }`}
        onClick={(e) => {
          if (props.mode === "elements") {
            addNewElement(template.elements[0].data)
            setDrawerOpen(false)
          } else {
            loadNewTemplate(template.id, template.context).then(() => {
              console.log("loadNewTemplate - THEN ***")
              // redrawStage()
            })
            setDrawerOpen(false)
          }
        }}
      >
        <img
          src={template.thumb}
          class={`border-1-gray-1 template-thumb`}
          height="90"
          width={props.mode === "socialdesign" ? 90 : null}
        />
        {/* <button
    class="btn btn-slim"
    data-toggle="modal"
    data-target="#templateEditModal"
    // onClick={(e) => removeTemplate(template.id)}
    onClick={(e) =>
      editTemplate(
        template.id,
        template.thumb,
        template.title,
        template.tags,
        template.division
      )
    }
    style={{
      marginLeft: -25,
      position: "absolute",
      // left: 80,
      top: 5,
      // zIndex: -10,
    }}
  >
    <AiOutlineEdit size={16} color="#ffffff" />
  </button> */}
      </button>
      <a
        class="gray-2 template-editor-label-title"
        onClick={(e) => {
          if (props.mode === "elements") {
            addNewElement(template.elements[0].data)
            setDrawerOpen(false)
          } else {
            loadNewTemplate(template.id, template.context).then(() => {
              console.log("loadNewTemplate - THEN ***")
              // redrawStage()
            })
            setDrawerOpen(false)
          }
        }}
      >
        {template.title}
      </a>

      {props.mode === "socialdesign" ? (
        <div class="template-editor-label-author">
          <a
            class="font-weight-light"
            onClick={() => loadSocialUserTemplates(template.uid)}
            // onClick={() =>
            //   loadSocialUserTemplates("ebf82958-af76-4620-bf1d-6b125a68904d")
            // }
            data-toggle="modal"
            data-target="#userProfileModal"
          >
            @{template.fname} {template.lname}
          </a>
        </div>
      ) : null}
    </div>
  )
}
const Snippet = (props) => {
  const {
    publisher,
    templatetags,
    isLoadingTemplateCreate,
    removeTemplate,
    updatePublisherField,
    loadNewTemplate,
    editTemplate,
    updateTemplateCollapse,
    templateTrayIsCollapsed,
    addNewElement,
    social,
    updateFormField,
    forms,
    loadSocialKeywordTemplates,
    userRole,
    context,
    setDrawerOpen,
    getViewport,
    uniqueIdentifier,
  } = props

  const [filterValue, setFilterValue] = useState("select-all")

  console.log("editorTemplates - publisher")
  console.log(publisher)
  console.log("editorTemplates - social")
  console.log(social)
  console.log("editorTemplates - context")
  console.log(context)

  const TemplateLoading = () => {
    return (
      <div
        class="px-3 d-flex align-items-center justify-items-center"
        style={{ height: 90 }}
      >
        <Loading class="p-2 small" />
      </div>
    )
  }
  // const loadNewTemplate = (id) => {
  //   //TODO: confirm replacement via modal

  //   console.log("loadNewTemplate - publisher")
  //   console.log(publisher)
  //   console.log("loadNewTemplate - props.context")
  //   console.log(props.context)

  //   let sceneIndex
  //   let sceneSelectedId
  //   const templateIndex = publisher.templates.findIndex(
  //     (item) => item.id === id
  //   )

  //   if (publisher.sceneSelected && publisher.sceneSelected.id) {
  //     sceneIndex = publisher.scene.findIndex(
  //       (scene) => scene.id === publisher.sceneSelected.id
  //     )
  //     sceneSelectedId = publisher.sceneSelected.id
  //   } else {
  //     sceneIndex = 0
  //     sceneSelectedId = "scene1"
  //   }

  //   console.log("templateIndex")
  //   console.log(templateIndex)
  //   console.log("sceneIndex")
  //   console.log(sceneIndex)
  //   let templateId = publisher.templates[templateIndex].id
  //   let newElements = [...publisher.templates][templateIndex].elements.find(
  //     (scene) => scene.sceneid === sceneSelectedId
  //   ).data
  //   let newStage = [...publisher.templates][templateIndex].stage
  //   let newScene
  //   let loadedScenes
  //   if (props.context === "video") {
  //     loadedScenes = [...publisher.templates][templateIndex].scene
  //     newScene = [...publisher.templates][templateIndex].scene[sceneIndex]
  //   }
  //   console.log("newElements")
  //   console.log(newElements)
  //   updatePublisherField("elements", newElements)
  //   updatePublisherField("stage", newStage)
  //   updatePublisherField("templateSelected", templateId)
  //   updatePublisherField("elementSelected", {})
  //   if (props.context === "video")
  //     updatePublisherField("sceneSelected", {
  //       sceneid: newScene.sceneid,
  //       layerid: newScene.scenelayers[0].id,
  //     })
  //   updatePublisherField("loadedScenes", loadedScenes)
  // }

  console.log("[[[[[filteredTemplates - publisher.templates]]]]]")
  console.log(publisher.templates)

  let filteredTemplates =
    publisher && publisher.templates
      ? publisher.templates.filter((item) => item.context === props.context)
      : // .filter((item) => {
        //   // if (item.tags && filterValue === "select-all") {
        //   //   return item
        //   // }
        //   if (item.tags) {
        //     return item.tags.includes(filterValue)
        //   } else {
        //     return item
        //   }
        // })
        []

  console.log("[[[[[filteredTemplates - pre]]]]]")
  console.log(filteredTemplates)

  let filteredSocialKeywordTemplates =
    social && social.keywordtemplates
      ? social.keywordtemplates.filter((item) => item.context === props.context)
      : []

  let sidebarTitleText
  // console.log("[[[[[filteredTemplates - pre]]]]]")
  // console.log(filteredTemplates)

  if (props.mode === "elements") {
    filteredTemplates = filteredTemplates.filter(
      (item) => item.mode === "elements"
    )
    sidebarTitleText = "Elements"
  } else if (props.mode === "template") {
    filteredTemplates = filteredTemplates.filter(
      (item) => !item.mode || item.mode === "template"
    )
    sidebarTitleText = "Templates"
  } else if (props.mode === "socialdesign") {
    filteredTemplates = []
  }
  console.log("[[[[[filteredTemplates - post]]]]]")
  console.log(filteredTemplates)

  const viewportSize = getViewport()
  return (
    <div>
      {/* <div>
        <button
          class="btn btn-slim w-100 text-left btn-template-collapse border-none"
          type="button"
          data-toggle="collapse"
          data-target="#templateCollapse"
          aria-expanded={templateTrayIsCollapsed ? "false" : "true"}
          aria-controls="templateCollapse"
          onClick={() => {
            updateTemplateCollapse(!templateTrayIsCollapsed)
          }}
        >
          <SidebarTitle text={sidebarTitleText} caret={true} />
        </button>
      </div> */}
      <div
        class={`template-container collapse ${
          templateTrayIsCollapsed ? "" : "show"
        }`}
        id="templateCollapse"
      >
        {/* {props.mode === "template" ? (
          <div class="d-flex flex-row bg-gray-7 template-tags-container">
            <button
              class={`btn btn-slim`}
              onClick={() => {
                setFilterValue("select-all")
              }}
            >
              <span
                class={`badge m-2 p-2 badge-filter ${
                  "select-all" === filterValue ? "badge-filter-active" : ""
                }`}
              >
                All
              </span>
            </button>
            {templatetags && templatetags.length > 0
              ? templatetags.map((tag) => (
                  <button
                    class={`btn btn-slim`}
                    onClick={() => {
                      setFilterValue(tag.content)
                    }}
                  >
                    <span
                      class={`badge m-2 p-2 badge-filter ${
                        tag.content === filterValue ? "badge-filter-active" : ""
                      }`}
                    >
                      {tag.content}
                    </span>
                  </button>
                ))
              : null}
          </div>
        ) : null} */}
        <div
          class="row bg-gray-9  template-select-container template-container-gradient round-corners-bottom"
          style={{ marginLeft: 0, marginRight: 0 }}
        >
          {props.mode === "template" || props.mode === "elements" ? (
            <div class="col-sm-12 my-2 d-flex flex-row flex-wrap align-items-start justify-content-start">
              {isLoadingTemplateCreate &&
              (props.mode === "template" || props.mode === "elements") ? (
                <TemplateLoading />
              ) : null}

              {(props.mode === "template" || props.mode === "elements") &&
              filteredTemplates.length > 0
                ? filteredTemplates.map((template) =>
                    renderTemplate(props, template, publisher?.templateSelected)
                  )
                : null}
              {filteredTemplates.length === 0 && props.mode === "template" ? (
                <div class="py-3 px-2 gray-3 w-100 text-center">
                  No templates
                </div>
              ) : null}
              {filteredTemplates.length === 0 && props.mode === "elements" ? (
                <div class="py-3 px-2 gray-3 w-100 text-center">
                  No elements
                </div>
              ) : null}
            </div>
          ) : null}
          <>
            {props.mode === "socialdesign" ? (
              <div
                class="col-sm-12 my-2 d-flex flex-column align-items-start justify-content-start"
                style={{
                  height: forms.social.searchkeyword ? "max-content" : "auto",
                }}
              >
                <div class="w-100">
                  <div class="d-flex flex-row align-items-center justify-content-center mt-2 mt-md-0 mb-2">
                    <div class="input-group">
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        placeholder={
                          uniqueIdentifier === "0" && viewportSize === "lg"
                            ? `Keyword/Designer`
                            : `Search keyword or designer`
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            loadSocialKeywordTemplates(
                              forms.social.searchkeyword
                            )
                          }
                        }}
                        onChange={(e) =>
                          updateFormField(
                            "social",
                            "searchkeyword",
                            e.target.value
                          )
                        }
                        value={forms.social.searchkeyword}
                      ></input>
                      <div class="input-group-append">
                        {forms.social.searchkeyword ? (
                          <button
                            class="btn btn-link btn-form-input-bg-sm btn-form-submit-sm"
                            onClick={() => {
                              updateFormField("social", "searchkeyword", "")
                            }}
                          >
                            <MdClose class="gray-1" />
                          </button>
                        ) : null}
                        <button
                          class="btn btn-primary btn-form-submit-sm"
                          onClick={() => {
                            loadSocialKeywordTemplates(
                              forms.social.searchkeyword
                            )
                          }}
                        >
                          <MdSearch class="white-1" />
                        </button>
                      </div>
                    </div>
                    {userRole ? (
                      <button
                        class="btn btn-minimal btn-success btn-notification-new w-100 white-1 d-flex flex-row align-items-center justify-content-center"
                        type="button"
                        data-toggle="modal"
                        data-target="#notificationSaveModal"
                      >
                        <HiOutlinePencilAlt size={16} />
                        {/* <FeatureIcon
                              context="megaphone"
                              color="dkgreen"
                              iconcolor="white"
                              iconfill="white"
                            /> */}
                        {/* <div class="mt-2 font-weight-light line-height-1">
                              New post
                            </div> */}
                      </button>
                    ) : null}
                  </div>
                </div>
                <div class="w-100">
                  <div class="d-flex flex-column align-items-center justify-content-center mt-3 mt-md-0">
                    {isLoadingTemplateCreate &&
                    props.mode === "socialdesign" ? (
                      <TemplateLoading />
                    ) : null}

                    {forms.social.searchkeyword ? (
                      <div class="d-flex flex-row flex-wrap align-items-start justify-content-center">
                        {filteredSocialKeywordTemplates.map((template) =>
                          renderTemplate(props, template, null)
                        )}
                      </div>
                    ) : (
                      <ConsoleHomeFeed
                        context="home"
                        loadNewTemplate={loadNewTemplate}
                        userRole={props.userRole}
                        setDrawerOpen={setDrawerOpen}
                        loadSocialKeywordTemplates={loadSocialKeywordTemplates}
                        updateFormField={updateFormField}
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
