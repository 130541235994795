import React from "react"
import { connect } from "react-redux"

//Actions
import { updatePublisherField } from "../../actions/createPublisher"

//Packages
import { Helmet } from "react-helmet"

//Components

//Icons
import {
  FaFacebookSquare,
  FaTwitter,
  FaInstagram,
  FaRegCommentDots,
  FaSnapchatGhost,
} from "react-icons/fa"
import { MdFileDownload } from "react-icons/md"

//Redux
const mapStateToProps = ({ publisher, isLoading, isLoadingCanvasImage }) => {
  return {
    publisher,
    isLoading,
  }
}

const mapDispatchToProps = {
  updatePublisherField,
}

const Snippet = (props) => {
  const {
    imageSrc,
    webHostedImage,
    imageexport,
    updatePublisherField,
    updateTemplateElements,
    updateShareIntent,
    userPlan,
  } = props

  const pageUrl = ""
  const templateCta = ""
  const templateHashtag = ""

  let FB
  if (typeof window !== `undefined`) {
    FB = window.FB
  }

  const shareImage =
    imageexport && imageexport.shortlink
      ? imageexport.shortlink
      : webHostedImage
  // const shareImage = webHostedImage

  //See https://stackoverflow.com/a/10802339

  const hostedImageFb = webHostedImage
    ? webHostedImage.substring(0, webHostedImage.lastIndexOf(".")) +
      "-1200x630" +
      webHostedImage.substring(webHostedImage.lastIndexOf("."))
    : ""

  const shareImageFb =
    imageexport && imageexport.shortlinkfb
      ? imageexport.shortlinkfb
      : hostedImageFb

  // console.log("[[[imageexport]]]")
  // console.log(imageexport)
  // console.log("[[[shareImage]]]")
  // console.log(shareImage)
  // console.log("[[[hostedImageFb]]]")
  // console.log(hostedImageFb)
  // console.log("[[[shareImageFb]]]")
  // console.log(shareImageFb)

  return (
    <>
      <Helmet>
        <script
          async={true}
          defer={true}
          crossOrigin="anonymous"
          src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v9.0&appId=319016709000095&autoLogAppEvents=1"
        />
      </Helmet>
      <div class="row mt-2">
        <div class="col-lg-12 d-flex align-items-center justify-content-center">
          {userPlan && userPlan === "proplus" ? (
            <>
              <button
                class="sharer"
                // target="_blank"
                // href={`https://www.facebook.com/sharer/sharer.php?u=${imageSrc}`}
                // rel="noopener noreferrer"
                // onClick={() => updateShareIntent("facebook")}
                onClick={() => {
                  if (FB) {
                    FB.ui(
                      {
                        method: "share",
                        href: shareImageFb,
                      },
                      function (response) {}
                    )
                  }
                }}
              >
                <FaFacebookSquare size={30} />
              </button>
              <a
                class="sharer"
                target="_blank"
                href={`https://twitter.com/intent/tweet?text=${templateCta}${templateHashtag}&url=${shareImage}`}
                rel="noopener noreferrer"
                onClick={() => updateShareIntent("twitter")}
              >
                <FaTwitter size={30} />
              </a>
              <button
                class="sharer"
                type="button"
                // data-toggle="modal"
                // data-target="#instagramModal"
                onClick={() => updateShareIntent("instagram")}

                // onClick={() => {
                //   this.props.setSocialModal("instagram")
                //   this.props.updateEngage(
                //     "instagram",
                //     landingdetail.templateid,
                //     landingdetail.id,
                //     campaignId
                //   )
                // }}
              >
                <FaInstagram size={30} />
              </button>
              <button
                class="sharer"
                type="button"
                // data-toggle="modal"
                // data-target="#instagramModal"
                onClick={() => updateShareIntent("snapchat")}

                // onClick={() => {
                //   this.props.setSocialModal("snapchat")
                //   this.props.updateEngage(
                //     "snapchat",
                //     landingdetail.templateid,
                //     landingdetail.id,
                //     campaignId
                //   )
                // }}
              >
                <FaSnapchatGhost size={28} />
              </button>
              <button
                class="sharer sharer-tk"
                style={{ marginLeft: 10, marginRight: 10 }}
                type="button"
                // data-toggle="modal"
                // data-target="#instagramModal"
                onClick={() => updateShareIntent("tiktok")}

                // onClick={() => {
                //   this.props.setSocialModal("tiktok")
                //   this.props.updateEngage(
                //     "tiktok",
                //     landingdetail.templateid,
                //     landingdetail.id,
                //     campaignId
                //   )
                // }}
              ></button>
            </>
          ) : null}
          {/* {landingdetail.social && landingdetail.social.msg === 1 ? (
          this.props.currentOS === "iOS" ||
          this.props.currentOS === "Mac OS" ||
          this.props.currentOS === "Android" ? (
            <a
              class="sharer"
              target="_blank"
              href={`sms://&body=${templateCta}${templateHashtag} ${pageUrl}`}
              onClick={() =>
                this.props.updateEngage(
                  "sms",
                  landingdetail.templateid,
                  landingdetail.id,
                  campaignId
                )
              }
            >
              <FaRegCommentDots size={30} />
            </a>
          ) : (
            <button
              class="sharer"
              type="button"
              data-toggle="modal"
              data-target="#smsModal"
              onClick={() =>
                this.props.updateEngage(
                  "sms",
                  landingdetail.templateid,
                  landingdetail.id,
                  campaignId
                )
              }
            >
              <FaRegCommentDots size={30} />
            </button>
          )
        ) : null} */}
          <button
            class="sharer"
            type="button"
            // data-toggle="modal"
            // data-target="#instagramModal"
            onClick={() => updateShareIntent("download")}

            // onClick={() => {
            //   this.props.setSocialModal("download")
            //   this.props.updateEngage(
            //     "download",
            //     landingdetail.templateid,
            //     landingdetail.id,
            //     campaignId
            //   )
            // }}
          >
            <MdFileDownload size={30} />
          </button>

          {/* <a class="sharer" href={fileUrl} download>
            <MdFileDownload size={30} />
          </a> */}
        </div>
      </div>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
