import React from "react"
import { Line, Transformer } from "react-konva"

const LineShape = ({
  shapeProps,
  isSelected,
  stageDimensions,
  onSelect,
  onChange,
  elementId,
}) => {
  const shapeRef = React.useRef()
  const trRef = React.useRef()

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current])
      trRef.current.getLayer().batchDraw()
    }
  }, [isSelected])

  return (
    <React.Fragment>
      <Line
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        draggable={true}
        onDragEnd={(e) => {
          console.log("line - onDragEnd - e")
          console.log(e)
          console.log("line - onDragEnd - e.target")
          console.log(e.target)
          console.log("line - onDragEnd - e.target.x()")
          console.log(e.target.x())
          console.log("line - onDragEnd - e.target.y()")
          console.log(e.target.y())
          console.log("line - onDragEnd - shapeProps")
          console.log(shapeProps)

          const scaleX = shapeProps.transformed
            ? e.target.scaleX()
            : e.target.scaleX() * (1 / stageDimensions.stageScale)
          const scaleY = shapeProps.transformed
            ? e.target.scaleY()
            : e.target.scaleY() * (1 / stageDimensions.stageScale)

          // // we will reset it back
          // e.target.scaleX(1)
          // e.target.scaleY(1)

          const origPoints = e.target.points()

          // const newPoints = [
          //   origPoints[0] * scaleX,
          //   origPoints[1] * scaleY,
          //   origPoints[2] * scaleX,
          //   origPoints[3] * scaleY,
          // ]

          // const newPoints = [50, 50, 250, 50]
          const newPoints = [
            e.target.x(),
            e.target.y(),
            origPoints[2] + e.target.x(),
            e.target.y(),
          ]

          console.log("<<<<<line -  drag - origPoints")
          console.log(origPoints)

          console.log("<<<<<line -  drag - newPoints")
          console.log(newPoints)

          // if (e && e.target && e.target.x() && e.target.y()) {

          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
            // points: newPoints,
          })

          // onChange({
          //   ...shapeProps,
          //   x: e.target.x(),
          //   y: e.target.y(),
          // })
        }}
        onTransformStart={() => {
          const node = shapeRef.current

          console.log("line transformStart - scaleX")
          console.log(node.scaleX())
          console.log("line transformStart - scaleY")
          console.log(node.scaleY())
          console.log("line - transformStart - node.width()")
          console.log(node.width())
          console.log("line - transformStart - node.height()")
          console.log(node.height())
        }}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current

          console.log("<<<<<line -  orig - node")
          console.log(node)
          console.log("<<<<<line -  new - e.target")
          console.log(e.target)

          // const scaleX = node.scaleX()
          // const scaleY = node.scaleY()
          const origScaleX = node.scaleX()
          const origScaleY = node.scaleY()
          const scaleX = shapeProps.transformed
            ? node.scaleX()
            : node.scaleX() * (1 / stageDimensions.stageScale)
          const scaleY = shapeProps.transformed
            ? node.scaleY()
            : node.scaleY() * (1 / stageDimensions.stageScale)
          console.log("line - transform - stageDimensions.stageScale")
          console.log(stageDimensions.stageScale)
          console.log("line - transform - 1/stageDimensions.stageScale")
          console.log(1 / stageDimensions.stageScale)
          console.log("line - transform - node.scaleX()")
          console.log(node.scaleX())
          console.log("line - transform - node.scaleY()")
          console.log(node.scaleY())
          console.log("line - transform - scaleX")
          console.log(scaleX)
          console.log("line - transform - scaleY")
          console.log(scaleY)
          // // we will reset it back
          // node.scaleX(1)
          // node.scaleY(1)
          // // node.scaleX(stageDimensions.stageScale)
          // // node.scaleY(stageDimensions.stageScale)

          const origPoints = node.points()

          const transform = node.getAbsoluteTransform()
          const transformRel = node.getTransform()
          const attrs = transform.decompose()
          console.log("<<<<<line - transform")
          console.log(transform)
          console.log("<<<<<line - transformRel")
          console.log(transformRel)
          const localStartPoint = transformRel.point({
            x: origPoints[0],
            y: origPoints[1],
          })
          const localEndPoint = transformRel.point({
            x: origPoints[2],
            y: origPoints[3],
          })
          console.log("<<<<<line - attrs")
          console.log(attrs)
          console.log("<<<<<line - localStartPoint")
          console.log(localStartPoint)
          console.log("<<<<<line - localEndPoint")
          console.log(localEndPoint)

          console.log("<<<<<line - Math.max(5, node.width() * scaleX)")
          console.log(Math.max(5, node.width() * scaleX))
          console.log("<<<<<line - Math.max(node.height() * scaleY)")
          console.log(Math.max(node.height() * scaleY))

          console.log("<<<<<line -  transform - node.width()")
          console.log(node.width())
          console.log("<<<<<line -  transform - node.height()")
          console.log(node.height())

          const newWidth = Math.max(5, node.width() * (1 / scaleX)) //different from other shapes bc it is 1 divided by scaleX

          console.log("<<<<<line -  transform - newWidth")
          console.log(newWidth)
          console.log("<<<<<line -  transform - origPoints")
          console.log(origPoints)

          // const newPoints = []
          // for (var i = 0; i < origPoints.length / 2; i++) {
          //   const point = {
          //     x: origPoints[i * 2] * scaleX,
          //     y: origPoints[i * 2 + 1] * scaleY,
          //   }
          //   newPoints.push(point.x, point.y)
          // }

          // const newPoints = [
          //   origPoints[0],
          //   origPoints[1],
          //   newWidth,
          //   origPoints[3],
          // ]
          const newPoints = [origPoints[0], origPoints[1], 300, origPoints[3]]

          console.log("<<<<<line -  newPoints")
          console.log(newPoints)

          onChange({
            ...shapeProps,
            ...attrs,
            points: newPoints,
            rotation: node.rotation(),
            transformed: true,
            scaleX: 1,
            scaleY: 1,
          })
          // onChange({
          //   ...shapeProps,
          //   ...attrs,
          //   x: node.x(),
          //   y: node.y(),
          //   // set minimal value
          //   width: Math.max(5, node.width() * scaleX),
          //   height: Math.max(node.height() * scaleY),
          //   rotation: node.rotation(),
          //   transformed: true,
          // })
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          enabledAnchors={[
            "middle-left",
            "middle-right",
            // "bottom-left",
            // "bottom-right",
          ]}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox
            }
            return newBox
          }}
        />
      )}
    </React.Fragment>
  )
}

export default LineShape
