//React
import React, { useRef, useState, useEffect, createRef } from "react"
import { connect } from "react-redux"

//Packages
import { Stage, Layer } from "react-konva"

//Actions
import {
  updatePublisherField,
  updateCanvasImageLoading,
} from "../../actions/createPublisher"
// import { changeAttr } from "../../actions/modifyPublisher"

//Components
import Line from "./elements/line"
import Rectangle from "./elements/rectangle"
import Circle from "./elements/circle"
import TextBox from "./elements/textbox"
import URLImage from "./elements/image"
import Loading from "../loading"
import ElementsNone from "./elementsNone"

//Icons
import { FaPlus } from "react-icons/fa"
import { MdFileDownload } from "react-icons/md"

//Redux
const mapStateToProps = ({
  publisher,
  isLoading,
  isLoadingCanvasImage,
  isLoadingTemplateFetch,
}) => {
  return {
    publisher,
    isLoading,
    isLoadingCanvasImage,
    isLoadingTemplateFetch,
  }
}

const mapDispatchToProps = {
  updatePublisherField,
  updateCanvasImageLoading,
  // changeAttr: (context, prop, val, pos, multiple) =>
  //   dispatch(changeAttr(context, prop, val, pos, multiple)),
}

const Snippet = (props) => {
  const {
    stageRef,
    canvasBorderShow,
    changeAttr,
    updatePublisherField,
    publisher,
    isLoadingCanvasImage,
    updateCanvasImageLoading,
    addNewElement,
    updateActiveToolField,
    stageDimensions,
    removeSelectedElement,
    isLoadingTemplateFetch,
    staticResetTextarea,
    setStaticResetTextarea,
  } = props
  // const [rectangles, setRectangles] = React.useState(elements)
  // const [selectedId, selectShape] = React.useState(null)

  console.log("editorCanvas == canvasBorderShow")
  console.log(canvasBorderShow)
  let Konva
  if (typeof window !== `undefined`) {
    Konva = window.Konva
    Konva.showWarnings = false
  }

  // const [layerRefs, setLayerRefs] = useState([])

  const checkDeselect = (e) => {
    // deselect when clicked on empty area
    console.log("const - publisher - PREDESELECT")
    console.log(publisher)
    console.log("deselect - clicked")
    console.log(e.target)
    const clickedOnEmpty = e.target === e.target.getStage()
    if (clickedOnEmpty) {
      console.log("deselect - clicked - empty")
      updatePublisherField("elementSelected", {})
      updateActiveToolField("canvas")
    }
  }

  const updateElements = (newAttrs, i) => {
    const newElements = publisher.elements.slice()
    console.log("editorCanvas // newElements")
    console.log(newElements)
    newElements[i] = newAttrs
    console.log("editorCanvas // newAttrs")
    console.log(newAttrs)
    // setRectangles(rects)
    updatePublisherField("elements", newElements)
    console.log("editorCanvas // newElements ** end")
  }

  const handleKeyPress = (e) => {
    if (e.keyCode === 8) {
      console.log("**Deleting element **")
      removeSelectedElement()
    }
  }

  console.log("const - publisher")
  console.log(publisher)

  // const elementsLength =
  //   publisher && publisher.elements && publisher.elements.length
  //     ? publisher.elements.length
  //     : 0

  // useEffect(() => {
  //   // add or remove refs
  //   setLayerRefs((layerRefs) =>
  //     Array(elementsLength)
  //       .fill()
  //       .map((_, i) => layerRefs[i] || createRef())
  //   )
  // }, [elementsLength])

  console.log("[[stageDimensions - editorCanvas]]")
  console.log(stageDimensions)
  //// STAGE DIMENSIONS & SCALE
  return (
    <div
      class="shadow-md stage-border"
      style={{
        width: stageDimensions.containerWidth,
        height: stageDimensions.containerHeight,
        overflow: "hidden",
        outline: "none",
        // borderWidth: 1,
        // borderColor: "#ff0000",
      }}
      tabIndex={1}
      onKeyDown={handleKeyPress}
    >
      {isLoadingTemplateFetch ? (
        <div class="w-100 h-100 d-flex align-items-center justify-content-center">
          <Loading class="small" />
        </div>
      ) : (
        <>
          {publisher && publisher.elements && publisher.elements.length > 0 ? (
            <div class="fade-in">
              {isLoadingCanvasImage ? (
                <div
                  class="d-flex align-items-center justify-content-center"
                  style={{
                    width: stageDimensions.containerWidth,
                    height: 60,
                    position: "absolute",
                    zIndex: 10,
                  }}
                >
                  <div
                    className="p-3 mt-3 rounded-corners align-self-center"
                    style={{
                      position: "absolute",
                      zIndex: 10,
                      // left: "40%",
                      backgroundColor: "rgba(255,255,255,0.5)",
                      // left: stageDimensions.stageWidth
                      //   ? stageDimensions.stageWidth / 2 - 17
                      //   : 500 / 2 - 17, //17 is half the width of the Loading indicator
                    }}
                  >
                    <Loading class="p-2 medium" />
                  </div>
                </div>
              ) : null}
              <Stage
                width={stageDimensions.stageWidth}
                height={stageDimensions.stageHeight}
                onMouseDown={checkDeselect}
                onTouchStart={checkDeselect}
                ref={stageRef}
                // offsetX={stageDimensions.stageWidth / 2}
                // offsetY={stageDimensions.stageHeight / 2}
                scaleX={stageDimensions.stageScale}
                scaleY={stageDimensions.stageScale}
              >
                <Layer>
                  {/* <Layer name={`layer-bg`}> */}
                  <Rectangle
                    name={`layer-bg`}
                    key={"bg"}
                    elementId="layerBg"
                    shapeProps={{
                      width: stageDimensions.stageWidth,
                      height: stageDimensions.stageHeight,
                      fill: "#ffffff",
                    }}
                  />
                  {/* </Layer> */}

                  {publisher.elements.map((element, i) => {
                    return (
                      <>
                        {element.shape === "line" && !element.hide ? (
                          // <Layer name={`layer-${i}`}>
                          <Line
                            name={`layer-${i}`}
                            key={i}
                            pos={i}
                            shapeProps={element}
                            isSelected={
                              element.id === publisher.elementSelected.id
                            }
                            stageDimensions={stageDimensions}
                            onSelect={() => {
                              // selectShape(element.id)
                              console.log("**selecting LINE")
                              updatePublisherField("elementSelected", {
                                id: element.id,
                                shape: "line",
                                pos: i,
                              })
                              updateActiveToolField("shape")
                            }}
                            onChange={(newAttrs) => {
                              console.log("**line - newAttrs")
                              console.log(newAttrs)
                              updateElements(newAttrs, i)
                            }}
                          />
                        ) : // </Layer>
                        null}
                        {element.shape === "rect" && !element.hide ? (
                          // <Layer name={`layer-${i}`}>
                          <Rectangle
                            name={`layer-${i}`}
                            key={i}
                            pos={i}
                            shapeProps={element}
                            isSelected={
                              element.id === publisher.elementSelected.id
                            }
                            stageDimensions={stageDimensions}
                            onSelect={() => {
                              // selectShape(element.id)
                              updatePublisherField("elementSelected", {
                                id: element.id,
                                shape: "rect",
                                pos: i,
                              })
                              updateActiveToolField("shape")
                            }}
                            onChange={(newAttrs) => {
                              console.log("rect - newAttrs")
                              console.log(newAttrs)
                              updateElements(newAttrs, i)
                            }}
                          />
                        ) : // </Layer>
                        null}
                        {element.shape === "circle" && !element.hide ? (
                          // <Layer name={`layer-${i}`}>
                          <Circle
                            name={`layer-${i}`}
                            key={i}
                            pos={i}
                            shapeProps={element}
                            isSelected={
                              element.id === publisher.elementSelected.id
                            }
                            stageDimensions={stageDimensions}
                            onSelect={() => {
                              // selectShape(element.id)
                              updatePublisherField("elementSelected", {
                                id: element.id,
                                shape: "circle",
                                pos: i,
                              })
                              updateActiveToolField("shape")
                            }}
                            onChange={(newAttrs) => {
                              console.log("circle - newAttrs")
                              console.log(newAttrs)
                              updateElements(newAttrs, i)
                            }}
                          />
                        ) : // </Layer>
                        null}
                        {element.shape === "text" && !element.hide ? (
                          // <Layer name={`layer-${i}`}>
                          <TextBox
                            name={`layer-${i}`}
                            key={i}
                            pos={i}
                            shapeProps={element}
                            content={element.content}
                            fontSize={15}
                            isSelected={
                              element.id === publisher.elementSelected.id
                            }
                            stageDimensions={stageDimensions}
                            stageRef={stageRef}
                            changeAttr={changeAttr}
                            staticResetTextarea={staticResetTextarea}
                            setStaticResetTextarea={setStaticResetTextarea}
                            onSelect={() => {
                              // selectShape(element.id)
                              updatePublisherField("elementSelected", {
                                id: element.id,
                                shape: "text",
                                pos: i,
                              })
                              updateActiveToolField("font")
                            }}
                            onChange={(newAttrs) => {
                              updateElements(newAttrs, i)
                            }}
                          />
                        ) : // </Layer>
                        null}
                        {element.shape === "image" && !element.hide ? (
                          // <Layer
                          //   name={`layer-${i}`}
                          //   // ref={layerRefs[i]}
                          // >
                          <URLImage
                            name={`layer-${i}`}
                            key={i}
                            pos={i} //key doesnt cascade to child
                            // layerRef={layerRefs[i]}
                            shapeProps={element}
                            src={element.src}
                            webpSupport={publisher.webp}
                            updateCanvasImageLoading={updateCanvasImageLoading}
                            imageMode={publisher.imagemode}
                            isSelected={
                              element.id === publisher.elementSelected.id
                            }
                            stageRef={stageRef}
                            changeAttr={changeAttr}
                            addNewElement={addNewElement}
                            onSelect={() => {
                              // selectShape(element.id)
                              console.log(
                                "UPDATEPUBLISHERFIELD - IMAGE - element.id"
                              )
                              console.log(element.id)
                              updatePublisherField("elementSelected", {
                                id: element.id,
                                shape: "image",
                                pos: i,
                              })
                              updateActiveToolField("imageprop")
                            }}
                            onChange={(newAttrs) => {
                              console.log("== IMAGE == ONCHANGE ==")
                              console.log(newAttrs)
                              updateElements(newAttrs, i)
                            }}
                            x={element.x}
                            y={element.y}
                          />
                        ) : // </Layer>
                        null}
                      </>
                    )
                  })}
                </Layer>
                <Layer name={`layer-canvas-border`} listening={false}>
                  {canvasBorderShow ? (
                    <Rectangle
                      name={`layer-canvas-border`}
                      key={"canvas-border"}
                      elementId="canvasBorder"
                      listening={false}
                      shapeProps={{
                        width:
                          stageDimensions.stageExport.scaleW -
                          stageDimensions.stageExport.x * 2,
                        height:
                          stageDimensions.stageExport.scaleH -
                          stageDimensions.stageExport.y * 2,
                        x: stageDimensions.stageExport.x,
                        y: stageDimensions.stageExport.y,
                        // x: 50 * stageDimensions.stageScale,
                        // y: 50 * stageDimensions.stageScale,
                        // width: 600,
                        // height: 600,
                        // x: 50,
                        // y: 50,
                        strokeWidth: 1,
                        stroke: "#666666",
                      }}
                    />
                  ) : null}
                </Layer>
              </Stage>
            </div>
          ) : (
            <ElementsNone />
          )}
          {/* end elementsNone */}
        </>
      )}
      {/* end isLoadingTemplateFetch */}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
