import React, { useEffect, useState } from "react"

//Components
import Loading from "../loading"

//Icons

const Snippet = (props) => {
  const { forms, deleteNotify, isLoadingNotifyCreate } = props

  return (
    <div class="reporting-container w-100 p-4">
      <div>
        <p class="black-4">Do you want to delete this notification?</p>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-center">
        <button
          class="btn btn-link"
          type="button"
          data-dismiss="modal"
          aria-label="Close"
        >
          Cancel
        </button>
        <button
          class="btn btn-danger"
          onClick={() => {
            deleteNotify(forms.notify.deleteid)
          }}
        >
          Delete
        </button>
        {isLoadingNotifyCreate ? <Loading class="small ml-3" /> : null}
      </div>
    </div>
  )
}
export default Snippet
