import React, { useEffect, useState } from "react"

import { Auth } from "aws-amplify"

//Components
import Loading from "../loading"

//Icons
import { FaRegCheckCircle } from "react-icons/fa"

const Snippet = (props) => {
  const {
    removeTemplate,
    currentSelectedTemplate,
    forms,
    isLoadingTemplateCreate,
    clearForm,
    userRole,
  } = props

  const [userSub, setUserSub] = useState(null)
  const [imageThumb, setImageThumb] = useState(null)
  const [templatePlaceholderId, setTemplatePlaceholderId] = useState(null)

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      setUserSub(user.username)
    })
  }, [])

  useEffect(() => {
    if (forms?.savetemplate?.thumb) {
      setImageThumb(forms.savetemplate.thumb)
    }
    // setTemplatePlaceholderId(uuidv4())
  })

  return (
    <div class="reporting-container w-100 p-4">
      <div class="form-group row d-flex flex-row align-items-center justify-content-start">
        <div class="offset-sm-2 col-sm-10">
          <img src={imageThumb} width={150} class="border-1-gray-5" />
        </div>
      </div>
      <div class="form-group row d-flex flex-row align-items-center justify-content-start">
        <label class="col-sm-2 col-form-label font-weight-light line-height-1">
          Confirm deletion
        </label>
        <div class="col-sm-10 bg-gray-7 py-2 rounded-corners">
          <div class="black-5 my-3">
            Do you want to delete the current selected template?
          </div>
          <div class="d-flex flex-row align-items-center justify-content-start mb-3">
            <button
              class="btn btn-link"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                clearForm("savetemplate")
                clearForm("edittemplate")
                clearForm("workingtags")
                $("#pills-new-tab").tab("show") // Select tab by name
              }}
            >
              Cancel
            </button>
            <button
              class="btn btn-danger mx-2"
              onClick={() => {
                removeTemplate(currentSelectedTemplate.id)
              }}
            >
              Delete
            </button>
            {isLoadingTemplateCreate ? <Loading class="small ml-3" /> : null}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Snippet
