import React from "react"
import { connect } from "react-redux"

//Actions
import { updatePublisherField } from "../../actions/createPublisher"

//Components
import PreviewTimelineElement from "./previewTimelineElement"

//Icons
import { MdVolumeUp, MdVolumeMute } from "react-icons/md"

//Redux
const mapStateToProps = ({ publisher, isLoading, isLoadingCanvasImage }) => {
  return {
    publisher,
    isLoading,
  }
}

const mapDispatchToProps = {
  updatePublisherField,
}

const Snippet = (props) => {
  const { publisher, updatePublisherField, updateTemplateElements } = props

  const posterSrc = ""
  const videoSrc =
    "https://storylavaprime.s3.amazonaws.com/static/publisher/sample/golds/_goldsfinal.mp4"

  return (
    <>
      <div class="video-container fade-in d-flex flex-column align-items-center justify-content-center">
        {/* <div class="d-none d-md-block"> */}
        <video
          playsInline
          autoPlay
          muted
          loop
          // width="270"
          // height="480"
          width="400"
          height="400"
          controls={true}
          poster={posterSrc}
          // ref="videoPreview"
        >
          <source src={videoSrc} type="video/mp4" />
        </video>
        <div
          class="d-flex align-items-center justify-content-center p-0 mb-0 mb-md-2"
          style={{
            width: 270,
            borderBottom: "1px solid #e6e6e6",
            backgroundColor: "#f2f2f2",
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
          }}
        >
          <button
            // onClick={this.toggleMute}
            class="d-flex align-items-center justify-content-center"
            style={{ border: 0, background: "transparent", width: "100%" }}
          >
            {/* {this.state.muted === "initial" ? (
              <VolumeAnimation />
            ) : this.state.muted ? (
              <MdVolumeMute
                size={26}
                style={{ marginTop: 6, marginBottom: 8 }}
              />
            ) : (
              <MdVolumeUp size={26} style={{ marginTop: 6, marginBottom: 8 }} />
            )} */}
          </button>
        </div>

        {/* </div> */}

        {/* <div class="d-md-none">
          <video
            playsInline
            autoPlay
            muted
            loop
            width="270"
            height="480"
            controls={true}
            poster={posterSrc}
          >
            <source src={videoSrc} type="video/mp4" />
          </video>
        </div> */}
      </div>

      {/* <div class="video-container">
          <div class="d-none d-md-block">
            <div class="placeholder-vertvideo" />
          </div>

          <div class="d-md-none">
            <div class="placeholder-vertvideo" />
          </div>
        </div> */}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
