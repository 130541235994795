import React from "react"

//Actions

//Components

//Icons

const Snippet = (props) => {
  const { imageSrc, directImageRatio, directImagePreview, viewportSize } = props

  let imageWidth = 500
  if (viewportSize === "xs" || viewportSize === "sm") imageWidth = 300

  let imageHeight = imageWidth / directImageRatio
  return (
    <>
      <div class="video-container fade-in d-flex flex-column align-items-center justify-content-center">
        {/* <div class="d-none d-md-block"> */}

        <img
          class="modal-image-preview"
          // key={imageSrc}
          src={directImagePreview}
          // width={imageWidth}
          // height={imageHeight}
        />
        {/* </div> */}

        {/* <div class="d-md-none">
          <video
            playsInline
            autoPlay
            muted
            loop
            width="270"
            height="480"
            controls={true}
            poster={posterSrc}
          >
            <source src={videoSrc} type="video/mp4" />
          </video>
        </div> */}
      </div>

      {/* <div class="video-container">
          <div class="d-none d-md-block">
            <div class="placeholder-vertvideo" />
          </div>

          <div class="d-md-none">
            <div class="placeholder-vertvideo" />
          </div>
        </div> */}
    </>
  )
}

export default Snippet
// export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
