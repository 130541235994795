import React from "react"
import { connect } from "react-redux"

import EmailVerifyForm from "./emailVerifyForm"

//Actions
import { createNotify, deleteNotify } from "../../actions/createNotify"
import {
  updateFormField,
  updateFormFieldSingle,
} from "../../actions/updateForm"
import {
  updateUserProfile,
  updateEmailVerifyField,
  verifyUserEmail,
  updateEmailVerifyErrors,
  deleteAccount,
} from "../../actions/userManagement"

const mapStateToProps = ({
  forms,
  userdetailemail,
  publisher,
  socialmodal,
  emailVerifyMode,
  verifyUserEmail,
  isLoadingNotifyCreate,
  isProfileUpdateComplete,
  updateEmailVerifyField,
  isLoading,
}) => {
  return {
    forms,
    userdetailemail,
    publisher,
    socialmodal,
    emailVerifyMode,
    verifyUserEmail,
    isLoadingNotifyCreate,
    isProfileUpdateComplete,
    updateEmailVerifyField,
    isLoading,
  }
}

const mapDispatchToProps = {
  createNotify,
  deleteNotify,
  updateFormField,
  updateFormFieldSingle,
  updateUserProfile,
  updateEmailVerifyField,
  verifyUserEmail,
  updateEmailVerifyErrors,
  deleteAccount,
}

const Snippet = (props) => {
  const {
    forms,
    userdetailemail,
    publisher,
    socialmodal,
    saveTemplate,
    createNotify,
    deleteNotify,
    updateFormField,
    updateFormFieldSingle,
    isLoadingNotifyCreate,
    isProfileUpdateComplete,
    updateUserProfile,
    updateEmailVerifyField,
    verifyUserEmail,
    emailVerifyMode,
    updateEmailVerifyErrors,
    context,
    deleteAccount,
  } = props

  let modalId
  let modalTitle
  switch (context) {
    case "verify":
      modalId = "emailVerifyModal"
      modalTitle = "Change email"
      break
    case "verifymobile":
      modalId = "mobileVerifyModal"
      modalTitle = "Update mobile number"
      break
    case "verifydisplay":
      modalId = "displayVerifyModal"
      modalTitle = "Update public display URL"
      break
    case "closeaccount":
      modalId = "closeAccountModal"
      modalTitle = "Close account"
      break
    default:
      modalId = "emailVerifyModal"
      modalTitle = "Verify email"
  }
  return (
    <div
      class="modal modal-sharer fade"
      id={modalId}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="emailVerifyLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header" style={{ borderBottom: 0 }}>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                updateEmailVerifyField(false)
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="modal-subheader d-flex flex-row align-items-center justify-content-start">
              <h4
                class="text-left pl-4 font-weight-bold"
                style={{ paddingTop: 8, marginRight: 8 }}
              >
                {modalTitle}
              </h4>
            </div>

            <div
              class="modal-content-container d-flex flex-column align-items-center justify-content-center"
              style={{ margin: "auto" }}
            >
              {context === "verify" ||
              context === "verifymobile" ||
              context === "verifydisplay" ||
              context === "closeaccount" ? (
                <EmailVerifyForm
                  context={context}
                  forms={forms}
                  userdetailemail={userdetailemail}
                  updateFormField={updateFormField}
                  updateUserProfile={updateUserProfile}
                  updateEmailVerifyField={updateEmailVerifyField}
                  emailVerifyMode={emailVerifyMode}
                  verifyUserEmail={verifyUserEmail}
                  isLoadingNotifyCreate={isLoadingNotifyCreate}
                  isProfileUpdateComplete={isProfileUpdateComplete}
                  updateEmailVerifyErrors={updateEmailVerifyErrors}
                  deleteAccount={deleteAccount}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// export default Snippet
// export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
