import React, { useEffect, useState } from "react"

import { Auth } from "aws-amplify"

//Components
import ImagePreviewTray from "./imagePreviewTray"
import ImageLibraryFormUpload from "./imageLibraryFormUpload"
import Loading from "../loading"

//Icons
import { FaTimes, FaPlus } from "react-icons/fa"

const Snippet = (props) => {
  const {
    saveTemplateComplete,
    forms,
    context,
    publisher,
    imageuploadprogress,
    isLoadingImageUpload,
    changeAttr,
    sendUpload,
    resetImageUpload,
    updateFormsField,
    loadImageLibrary,
    imagelibrary,
    userRole,
    isLoadingImageDelete,
    deleteImages,
    selectMode,
    setSelectMode,
    isLoading,
  } = props

  const [userSub, setUserSub] = useState(null)
  const [imageThumb, setImageThumb] = useState(null)
  const [activeTab, setActiveTab] = useState("library")

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      setUserSub(user.username)
    })
  }, [])

  // console.log("****userSub")
  // console.log(userSub)
  // // console.log("templateSaveForm - imageThumb")
  // // console.log(imageThumb)

  // console.log("imageLibraryForm - imagelibrary")
  // console.log(imagelibrary)
  // console.log("imageLibraryForm - userRole")
  // console.log(userRole)
  const showBrandUpload =
    imagelibrary === "user" ||
    (imagelibrary === "brand" && userRole === "admin")
      ? true
      : false

  return (
    <div class="reporting-container w-100 p-4">
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item">
          <a
            class={`nav-link btn-sm ${activeTab === "library" ? "active" : ""}`}
            id="pills-library-tab"
            // data-toggle="pill"
            // href="#pills-library"
            // role="tab"
            // aria-controls="pills-library"
            // aria-selected="true"
            onClick={() => {
              setActiveTab("library")
              updateFormsField("previewImageLoaded", false)
              resetImageUpload()
            }}
          >
            Library
          </a>
        </li>
        {showBrandUpload ? (
          <li class="nav-item">
            <a
              class={`nav-link btn-sm ${
                activeTab === "upload" ? "active" : ""
              }`}
              id="pills-upload-tab"
              // data-toggle="pill"
              // href="#pills-upload"
              // role="tab"
              // aria-controls="pills-upload"
              // aria-selected="false"
              onClick={() => {
                setActiveTab("upload")
              }}
            >
              Upload
            </a>
          </li>
        ) : null}
      </ul>
      <hr size={1} />
      <div class="tab-content" id="pills-tabContent">
        <div
          class={`tab-pane ${activeTab === "library" ? "show active" : ""}`}
          id="pills-library"
          role="tabpanel"
          aria-labelledby="pills-library-tab"
        >
          <ImagePreviewTray
            mode={`${imagelibrary}media`}
            display="grid"
            context={context}
            publisher={publisher}
            changeAttr={changeAttr}
            resetImageUpload={resetImageUpload}
            setActiveTab={setActiveTab}
            updateFormsField={updateFormsField}
            forms={forms}
            isLoadingImageDelete={isLoadingImageDelete}
            deleteImages={deleteImages}
            selectMode={selectMode}
            setSelectMode={setSelectMode}
            loadImageLibrary={loadImageLibrary}
            imagelibrary={imagelibrary}
            userRole={userRole}
            isLoading={isLoading}
          />
        </div>
        {showBrandUpload ? (
          <div
            class={`tab-pane ${activeTab === "upload" ? "show active" : ""}`}
            id="pills-upload"
            role="tabpanel"
            aria-labelledby="pills-upload-tab"
          >
            <ImageLibraryFormUpload
              context={context}
              changeAttr={changeAttr}
              isLoadingImageUpload={isLoadingImageUpload}
              imageuploadprogress={imageuploadprogress}
              sendUpload={sendUpload}
              forms={forms}
              resetImageUpload={resetImageUpload}
              setActiveTab={setActiveTab}
              updateFormsField={updateFormsField}
              loadImageLibrary={loadImageLibrary}
              imagelibrary={imagelibrary}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}
export default Snippet
