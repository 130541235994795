import React, { useEffect, useState } from "react"

//Icons
import { FaSpinner, FaRegCheckCircle } from "react-icons/fa"
import { MdRefresh } from "react-icons/md"

//Packages
const agentParser = require("ua-parser-js")

const Snippet = ({
  mode,
  display,
  context,
  publisher,
  changeAttr,
  resetImageUpload,
  setActiveTab,
  updateFormsField,
  imagelibrary,
  loadImageLibrary,
  forms,
  isLoadingImageDelete,
  deleteImages,
  selectMode,
  setSelectMode,
  userRole,
  isLoading,
}) => {
  const [tagFilter, setTagFilter] = useState(null)

  const [currentOS, setCurrentOS] = useState("")
  const [currentBrowser, setCurrentBrowser] = useState("")

  useEffect(() => {
    const agentObj = agentParser(window.navigator.userAgent)
    console.log("** agentObj")
    console.log(agentObj)
    setCurrentOS(agentObj.os.name)
    setCurrentBrowser(agentObj.browser.name)
  }, [])

  useEffect(() => {
    //like componentDidMount
    // console.log("** isLoadingImageDelete CHECKING")

    if (isLoadingImageDelete === "complete") {
      // console.log("** isLoadingImageDelete COMPLETE ** loading images again")
      loadImageLibrary(imagelibrary)
      setSelectMode(false)
    }
  }, [isLoadingImageDelete])

  let images = []
  let tags
  let folderPrefix
  // console.log("imagePreviewTray - imagelibrary")
  // console.log(imagelibrary)
  // console.log("imagePreviewTray - publisher")
  // console.log(publisher)

  if (mode === "usermedia" && publisher && publisher.userimages) {
    images = publisher.userimages
    folderPrefix = "u"
    const tagArrays = publisher.userimages.map((image) => image.tagid)
    const flattenedTagArray = [].concat(...tagArrays)
    tags = [...new Set(flattenedTagArray)]
    // console.log("flattenedTagArray")
    // console.log(flattenedTagArray)
    // console.log("uniqueTags")
    // console.log(uniqueTags)
  } else if (mode === "brandmedia" && publisher && publisher.brandimages) {
    images = publisher.brandimages
    folderPrefix = "c"
  }

  const noImages = (context) => {
    return (
      <div class="small gray-4 p-2">
        {context === "upload" ? "Upload images" : "No images"}
      </div>
    )
  }
  if (display === "mini") {
    return (
      <div class="d-flex flex-row flex-wrap align-items-center justify-content-center p-1">
        {images.slice(0, 4).map((img) => {
          // console.log("imagelibrary -- img")
          // console.log(img)
          let imageFolder
          if (mode === "usermedia") imageFolder = img.uid
          if (mode === "brandmedia") imageFolder = img.cid
          const imageSrc = `https://${
            process.env.S3BUCKETMEDIA
          }.s3.amazonaws.com/public/${folderPrefix}/${imageFolder}/${
            img.id
          }-100h.${currentBrowser === "Safari" ? "png" : "webp"}`
          const imageSrcCanvas = `https://${
            process.env.S3BUCKETMEDIA
          }.s3.amazonaws.com/public/${folderPrefix}/${imageFolder}/${
            img.id
          }-1000w.${currentBrowser === "Safari" ? "png" : "webp"}`

          // const imageSrc = img.src
          const imagePoster = img.poster ? img.poster : imageSrc
          // console.log("imagelibrary -- imageSrc")
          // console.log(imageSrc)
          // console.log("imagelibrary -- context")
          // console.log(context)
          return (
            <button
              class="btn btn-slim border-1-gray-6 d-flex align-items-center justify-content-center image-upload-preview-load-button"
              onClick={(e) =>
                changeAttr(
                  context,
                  "src",
                  `${imageSrcCanvas}?t=${Math.random()
                    .toString(36)
                    .substring(2, 15)}`
                )
              }
            >
              {/* ?t=${Math.random()
                  .toString(36)
                  .substring(2, 15)} */}
              <img
                // style={{ marginRight: 2 }}
                class="image-tray image-tray-mini"
                src={imagePoster}
                height="45"
                // width="45"
              />
            </button>
          )
        })}
        {images.length === 0 ? noImages("upload") : null}
      </div>
    )
  } else if (display === "grid") {
    return (
      <div class="row">
        <div class="col-md-3">
          {mode === "usermedia" ||
          (mode === "brandmedia" && userRole === "admin") ? (
            <>
              <button
                class={`btn btn-sm w-100 ${
                  selectMode ? "btn-secondary" : "btn-outline-secondary"
                }`}
                style={{ margin: 1 }}
                onClick={() => {
                  setSelectMode(!selectMode)
                  if (!selectMode === false) {
                    //reset if false
                    updateFormsField("deleteimage", [])
                  }
                }}
              >
                Manage
              </button>
              {selectMode ? (
                <button
                  class={`btn btn-sm btn-outline-danger mt-1 line-height-1`}
                  onClick={() => {
                    deleteImages(mode, forms.deleteimage)
                  }}
                >
                  Delete selected images
                  {isLoadingImageDelete === true &&
                  isLoadingImageDelete !== "complete" ? (
                    <div className="m-2">
                      <FaSpinner size={16} className="icon-spin" />
                    </div>
                  ) : null}
                </button>
              ) : null}
            </>
          ) : null}

          {isLoadingImageDelete && isLoadingImageDelete === "complete" ? (
            <div class="d-flex flex-row align-items-center mt-2">
              <FaRegCheckCircle class="dkgreen-1" size={16} />
              <div class="dkgreen-1 pl-1 font-weight-light small">
                Images deleted
              </div>
            </div>
          ) : null}

          <hr size={1} />
          <button
            class={`btn btn-sm`}
            style={{ margin: 1 }}
            onClick={() => {
              loadImageLibrary(imagelibrary)
            }}
          >
            {isLoading ? (
              <FaSpinner size={16} className="icon-spin black-4" />
            ) : (
              <MdRefresh size={20} />
            )}
          </button>
          <button
            class={`btn btn-sm ${
              tagFilter === null ? "btn-info" : "btn-outline-info"
            }`}
            style={{ margin: 1 }}
            onClick={() => {
              setTagFilter(null)
            }}
          >
            All
          </button>
          {tags && tags.length > 0
            ? tags.map((tag) => (
                <button
                  class={`btn btn-sm ${
                    tagFilter === tag ? "btn-info" : "btn-outline-info"
                  }`}
                  style={{ margin: 1 }}
                  onClick={() => {
                    setTagFilter(tag)
                  }}
                >
                  {tag}
                </button>
              ))
            : null}
        </div>
        <div class="col-md-9 d-flex flex-row flex-wrap align-items-start justify-content-start">
          {images
            .filter((img) =>
              img && img.tagid && tagFilter
                ? img.tagid.includes(tagFilter)
                : img
            )
            .map((img, j) => {
              // console.log("imagelibrary -- img")
              // console.log(img)
              let imageFolder
              if (imagelibrary === "brand") imageFolder = img.cid
              if (imagelibrary === "user") imageFolder = img.uid

              let fileExt = publisher.webp ? "webp" : "png"

              const imageSrc = `https://${process.env.S3BUCKETMEDIA}.s3.amazonaws.com/public/${folderPrefix}/${imageFolder}/${img.id}-100h.${fileExt}`
              const imageSrcCanvas = `https://${process.env.S3BUCKETMEDIA}.s3.amazonaws.com/public/${folderPrefix}/${imageFolder}/${img.id}-1000w.${fileExt}`
              // const imageSrc = img.src
              const imagePoster = img.poster ? img.poster : imageSrc
              return (
                <div
                  class="d-flex flex-row align-items-center justify-content-start"
                  style={{ minHeight: 100, margin: 1 }}
                >
                  {selectMode ? (
                    <div class="d-flex">
                      <img
                        // style={{ position: "absolute" }}
                        class="image-tray image-tray-grid"
                        src={imagePoster}
                        height="100"
                        // width="45"
                      />
                      <input
                        class="form-check-input checkbox-round"
                        // style={{ position: "relative", top: 3, right: 3 }}
                        type="checkbox"
                        value="1"
                        id={`manageImage-${j}`}
                        onClick={(e) => {
                          console.log("[[[[[e.target.checked]]]]]")
                          console.log(e.target.checked)
                          let currentImgToDelete
                          if (e.target.checked === true) {
                            const currentImg = [img.id]
                            currentImgToDelete = [
                              ...forms.deleteimage,
                              ...currentImg,
                            ]
                          } else {
                            const currentFormsImg = [...forms.deleteimage]
                            currentImgToDelete = currentFormsImg.filter(
                              (image) => image !== img.id
                            )
                          }
                          updateFormsField("deleteimage", currentImgToDelete)
                        }}
                      />
                    </div>
                  ) : (
                    <button
                      class="btn btn-slim border-1-gray-6 d-flex align-items-center justify-content-center"
                      style={{ borderRadius: 2, padding: 2, marginRight: 2 }}
                      type="button"
                      // class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => {
                        if (selectMode) {
                          // updateFormField("userdetailemail", "mobile", mobileObj)
                        } else {
                          changeAttr(
                            context,
                            "src",
                            `${imageSrcCanvas}?t=${Math.random()
                              .toString(36)
                              .substring(2, 15)}`
                          )
                          updateFormsField("previewImageLoaded", false)
                          resetImageUpload()
                        }
                      }}
                    >
                      <img
                        // style={{ marginRight: 2 }}
                        class="image-tray image-tray-grid"
                        src={imagePoster}
                        height="100"
                        // width="45"
                      />
                    </button>
                  )}
                </div>
              )
            })}
          {images.length === 0 ? noImages() : null}
        </div>
      </div>
    )
  }
}

export default Snippet
