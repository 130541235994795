//React
import React from "react"
import { connect } from "react-redux"

//Gatsby
import { Link } from "gatsby"

//Packages
import moment from "moment"

//Actions
import { loadSocialUserTemplates } from "../../actions/createPublisher"
import { getNotify } from "../../actions/getNotify"
import { updateFormField } from "../../actions/updateForm"

//Components
import Loading from "../loading"
import FeatureTitle from "../elements/featureTitle"
import NotificationGettingStarted from "../appHome/notificationGettingStarted"

//Icons
import { MdDeleteForever } from "react-icons/md"

//Redux
const mapStateToProps = ({
  company,
  publisher,
  campaign,
  notify,
  sendgroup,
  isLoading,
  usersub,
}) => {
  return { company, publisher, campaign, notify, sendgroup, isLoading, usersub }
}

const mapDispatchToProps = {
  getNotify,
  loadSocialUserTemplates,

  updateFormField,
}

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    if (!this.props.notify || this.props.notify.length === 0) {
      console.log("****getting notify")
      this.props.getNotify()
    }
  }

  renderNotification(notification, error) {
    const {
      loadNewTemplate,
      setDrawerOpen,
      loadSocialUserTemplates,
    } = this.props

    // console.log([[[[[notification]]]]])
    // console.log(notification)
    return (
      <>
        {/* <div class="col-1">
          <IoMdNotificationsOutline
            className="gray-3 p-0"
            size={20}
            style={{ marginTop: -2 }}
          />
        </div> */}
        <div class="font-weight-light d-flex flex-column align-items-start justify-content-start">
          <button
            className="btn btn-minimal d-flex flex-column w-100 px-2"
            onClick={() => {
              loadNewTemplate(notification.templateid, notification.context)
              setDrawerOpen(false)
            }}
          >
            <div class="d-flex flex-column flex-md-row align-items-stretch justify-content-start justify-content-md-center text-center w-100">
              {notification.imageid ? (
                <img
                  src={notification.imageid}
                  class="feed-image mt-2 rounded-corners border-1-gray-4"
                />
              ) : null}
              <div class="d-flex flex-column pt-2 px-3 w-100">
                <div class="feed-message text-left">{notification.content}</div>
                <div class="feed-metadata-container mt-auto">
                  <div class="feed-author">
                    <a
                      onClick={() => loadSocialUserTemplates(notification.uid)}
                      // onClick={() =>
                      //   loadSocialUserTemplates("ebf82958-af76-4620-bf1d-6b125a68904d")
                      // }
                      data-toggle="modal"
                      data-target="#userProfileModal"
                    >
                      {notification.fname ? "@" : ""}
                      {notification.fname} {notification.lname}
                    </a>
                  </div>
                  <div class="feed-date mt-auto">
                    {moment
                      .unix(notification.datecreated)
                      .format("MMM. D, YYYY h:mm a")}
                  </div>
                  <div class="mt-2 dkblue-1 text-left small">{error}</div>
                </div>
              </div>
            </div>
          </button>
        </div>
      </>
    )
  }

  render() {
    const {
      company,
      campaign,
      publisher,
      notify,
      isLoading,
      loadNewTemplate,
      updateFormField,
      userRole,
      loadSocialKeywordTemplates,
      usersub,
    } = this.props

    console.log("consoleHomeComponent - render - company")
    console.log(company)
    console.log("consoleHomeComponent - render - notify")
    console.log(notify)

    let numberSingular =
      company && company.numbers && company.numbers.length === 1 ? true : false

    return (
      <div className="feed-container fade-in">
        {/* <FeatureTitle context="action" color="dkblue" title="Updates" /> */}
        <div class="mb-4" />
        {/* <h3 class="mb-3">Updates</h3> */}
        {/* {isLoading ? (
          <div class="p-4">
            <Loading />
          </div>
        ) : null} */}

        {notify && notify.length > 0 ? (
          notify.map((notification) => {
            const activeTemplateIndex = publisher.templates.findIndex(
              (template) => template.id === notification.templateid
            )

            // console.log("[[[** MAP - notification]]]")
            // console.log(notification)
            // console.log("[[[activeTemplateIndex]]]")
            // console.log(activeTemplateIndex)
            return (
              <>
                <div class="row row-feed mb-2 round-corners border-1-gray-6">
                  {typeof activeTemplateIndex !== "undefined" ? (
                    <div class="w-100" style={{ position: "relative" }}>
                      {userRole && usersub && usersub === notification.uid ? (
                        <button
                          class="template-delete btn btn-slim ml-auto px-3"
                          style={{
                            position: "absolute",
                            // marginRight: -30,
                            zIndex: 10,
                            top: 0,
                            right: -10,
                          }}
                          data-toggle="modal"
                          data-target="#notificationDeleteModal"
                          onClick={() =>
                            updateFormField(
                              "notify",
                              "deleteid",
                              notification.id
                            )
                          }
                        >
                          <MdDeleteForever size={20} className="gray-4" />
                        </button>
                      ) : null}
                      {this.renderNotification(notification)}
                      {/* <button
                      className="btn btn-minimal d-flex flex-row w-100 p-3"
                      onClick={() => {
                        loadNewTemplate(
                          notification.templateid,
                          notification.context
                        )
                      }}
                    >
                      {this.renderNotification(notification)}
                    </button> */}
                    </div>
                  ) : (
                    <div className="btn btn-minimal d-flex flex-row w-100 p-1">
                      {this.renderNotification(
                        notification,
                        "This template has been deleted or no longer exists"
                      )}
                    </div>
                  )}
                </div>
              </>
            )
          })
        ) : (
          <div className="btn btn-minimal d-flex flex-row w-100 p-1">
            {/* {this.renderNotification({
              datecreated: Math.floor(Date.now() / 1000),
              content: "No notifications",
            })} */}
            <NotificationGettingStarted
              loadSocialKeywordTemplates={loadSocialKeywordTemplates}
              updateFormField={updateFormField}
            />
          </div>
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
