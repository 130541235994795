// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React, { useRef, useState, useEffect, useLayoutEffect } from "react"
import { Stage, Layer, Rect, Text, Transformer, Image } from "react-konva"

import { MdPlayArrow, MdPause } from "react-icons/md"
import Loading from "../loading"
import Scrubber from "./elements/scrubber"

const Snippet = (props) => {
  const [videoDuration, setVideoDuration] = useState(1)
  const [finalFramesCount, setFinalFramesCount] = useState(13)
  const [scrubberWidth, setScrubberWidth] = useState(100)
  const [currentTimePosition, setCurrentTimePosition] = useState(0)
  const [trimmerX, setTrimmerX] = useState(0)
  const [trimmerLeft, setTrimmerLeft] = useState(0)
  const [trimmerRight, setTrimmerRight] = useState(0)
  const [loadingVideoFrames, setLoadingVideoFrames] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [timeStart, setTimeStart] = useState(0)
  const [timeEnd, setTimeEnd] = useState(0)

  const trimmerRef = useRef(null)

  const frames = []

  useEffect(() => {
    // console.log("!!! INITIAL useEffect")
    // extractFrames(props.videoRef)
    // console.log("INITIAL finalFramesCount")
    // console.log(finalFramesCount)
    props.videoRef.current.addEventListener("timeupdate", (e) => {
      console.log("[[trimmerRef]]")
      console.log(trimmerRef)
      console.log("[[isPlaying]]")
      console.log(isPlaying)
      console.log("inner finalFramesCount")
      console.log(finalFramesCount)
      // if (trimmerRef && trimmerRef.current) {
      // if (isPlaying) {
      console.log("e.target")
      console.log(e.target)
      const currentTime = convertTimeToPos(
        e.target.currentTime,
        e.target.duration,
        finalFramesCount
      )
      console.log("currentTime")
      console.log(currentTime)
      setCurrentTimePosition(currentTime)
      // } //end if currentPos > this.state.timeEnd

      // const trimmerLeftVal = trimmerRef.current.getBoundingClientRect().left
      // const trimmerRightVal = trimmerRef.current.getBoundingClientRect().right
      // setTrimmerLeft(trimmerLeftVal)
      // setTrimmerRight(trimmerRightVal)
      // console.log("**trimmerLeft")
      // console.log(trimmerLeft)
      // }

      // if (isPlaying && e.target.currentTime > timeEnd) {
      //   console.log("**timeupdate - pause")
      //   videoRef.current.pause()
      //   setIsPlaying(false)
      // } else if (isPlaying) {
      //   setCurrentTimePosition(convertTimeToPos(e.target.currentTime))
      // } //end if currentPos > this.state.timeEnd
    })
  }, [])
  useEffect(() => {
    console.log("!!! NEXT useEffect")

    console.log("&&props.videoRef")
    console.log(props.videoRef)
    console.log("&&videoDuration")
    console.log(videoDuration)
    if (props.videoRef.current) {
      extractFrames(props.videoRef).then((result) => {
        console.log("** start scrubber")
        console.log(result)
      }) //end extractFrames
    }
  }, [videoDuration])

  // verifying `input` is referenced correctly after DOM updates
  useLayoutEffect(() => {
    console.log("props.videoRef.current")
    console.log(props.videoRef.current)
    // props.videoRef.current.removeEventListener("timeupdate", (e) => {})
  })

  const roundNumber = (number) => {
    //round to two decimals
    return Math.floor(number * 100) / 100
  }
  const convertTimeToPos = (time, duration, finalFramesCountVal) => {
    let scrubberWidthVal = scrubberWidth - 19 // minus 20ish for borders each side
    console.log("scrubberWidthVal")
    console.log(scrubberWidthVal)
    let framesWidthVal = finalFramesCountVal * 23

    let videoDurationVal = duration
    console.log("timeStart")
    console.log(timeStart)
    console.log("--videoDurationVal")
    console.log(videoDurationVal)

    let timeBand = videoDurationVal - timeStart
    console.log("timeBand")
    console.log(timeBand)
    let timeProportion = (time - timeStart) / timeBand
    console.log("timeProportion")
    console.log(timeProportion)
    let timeAdvancedPixels = framesWidthVal * timeProportion
    console.log("timeAdvancedPixels")
    console.log(timeAdvancedPixels)
    let posValue = roundNumber(timeAdvancedPixels)
    console.log("posValue")
    console.log(posValue)
    return posValue
  }
  const playVideo = (videoRef, isPlayingVal) => {
    console.log("playVideo - isPlayingVal")
    console.log(isPlayingVal)
    if (isPlayingVal) {
      videoRef.current.pause()
      setIsPlaying(false)
      // return
    } else {
      // isPlaying is false
      // videoRef.current.currentTime = timeStart
      videoRef.current.play()
      setIsPlaying(true)
      // setCurrentTimePosition(0)
      // return
    } //end isPlaying
  }
  const setScrubberDetails = (input) => {
    console.log("input")
    console.log(input)
    return new Promise((resolve) => {
      let sendToCompletion = false
      let shorterThanTrim = false
      let framesWidth = finalFramesCount * 23 //23 pixels is width of one video frame
      let scrubberWidthVal = scrubberWidth

      // //:: 1 :: CHECK IF MAX TRIM LENGTH IS SET FROM DB
      // if (this.props.landingdetail && this.props.landingdetail.trim) {
      //   if (
      //     this.state.videoDuration &&
      //     this.state.videoDuration < this.props.landingdetail.trim
      //   ) {
      //     shorterThanTrim = true
      //   }

      //   scrubberWidth =
      //     (this.props.landingdetail.trim / this.state.videoDuration) * framesWidth
      // } //end landingdetail.trim

      // console.log("//videoDuration")
      // console.log(videoDuration)
      // console.log("//framesWidth")
      // console.log(framesWidth)
      // console.log("//scrubberWidth")
      // console.log(scrubberWidth)

      // let endingTime = roundNumber((videoDuration / framesWidth) * scrubberWidth)

      //:: 2 :: If the video length is less than the max trim time, resize the scrubber to the video length and skip the trimmer page
      // if (shorterThanTrim === true) {
      // sendToCompletion = true
      scrubberWidthVal = framesWidth //that is, the complete width of the scrubber. Not sure if anyone sees the scrubber though because we are skipping the trimmer page
      let endingTime = roundNumber(input.duration)
      // }

      console.log("endingTime")
      console.log(endingTime)

      // const trimmerX = trimmerRef.current.getBoundingClientRect().left
      const trimmerX = 48 + 2 //48 is width of play/pause button, 2 for border
      const trimmerLeftVal = trimmerRef.current.getBoundingClientRect().left
      const trimmerRightVal = trimmerRef.current.getBoundingClientRect().right
      console.log("//trimmerX")
      console.log(trimmerX)
      console.log("//trimmerLeftVal")
      console.log(trimmerLeftVal)
      console.log("//trimmerRightVal")
      console.log(trimmerRightVal)
      setTrimmerX(trimmerX)
      setTrimmerLeft(trimmerLeftVal)
      setTrimmerRight(trimmerRightVal)
      setTimeStart(0)
      setTimeEnd(endingTime)
      setScrubberWidth(scrubberWidthVal)
      resolve({ timeEnd: endingTime })
    }) //end promise
  }

  const createImage = (videoSource, time, i, callback) => {
    console.log("createImage - videoSource")
    console.log(videoSource)
    let videoRef = document.createElement("video")
    videoRef.style.width = "23px"
    videoRef.style.height = "40px"
    videoRef.style.margin = "0"
    videoRef.style.padding = "0"
    videoRef.style.border = "0"

    videoRef.src = videoSource
    videoRef.autoplay = true
    videoRef.muted = true
    videoRef.className = "fade-in"

    videoRef.addEventListener("canplaythrough", function handler(e) {
      videoRef.currentTime = time
      videoRef.pause()

      e.target.removeEventListener(e.type, handler) // remove the handler or else it will draw another frame on the same canvas, when the next seek happens

      videoRef.onseeked = function (e2) {
        frames[i] = videoRef
        console.log(`** frames **  ${i}`)
        console.log(frames)
        callback()
      }
    }) // end oncanplaythrough
  }

  // const elementDrag = (e, videoRef) => {
  //   // e.preventDefault()
  //   console.log("**elementDrag - e")
  //   console.log(e)
  //   console.log("** ref :: elementDrag")
  //   console.log(videoRef)
  //   videoRef.current.pause()
  //   // let scrubberWidth = scrubberWidth
  //   let framesWidth = finalFramesCount * 23

  //   let pageX
  //   if (e.pageX) {
  //     pageX = e.pageX
  //   } else if (e.touches && e.touches[0] && e.touches[0].pageX) {
  //     //On Android Chrome, pageX is hidden within e.touches
  //     pageX = e.touches[0].pageX
  //   }

  //   if (pageX < trimmerLeft) {
  //     // console.log("**ELEMENT DRAG CASE 1**")
  //     //If scrubber is dragged all the way to the left, set initial values
  //     let timeStartVal = 0

  //     videoRef.current.currentTime = timeStartVal

  //     setTrimmerX(trimmerLeft)
  //     setTimeStart(timeStartVal)
  //     setCurrentTimePosition(0)
  //     setTimeEnd(roundNumber((videoDuration / framesWidth) * scrubberWidth))
  //   } else if (pageX + scrubberWidth > trimmerRight) {
  //     // console.log("**ELEMENT DRAG CASE 2**")
  //     //If scrubber is dragged all the way to the right, set ending values
  //     let timeStartVal = roundNumber(
  //       videoDuration - (videoDuration / framesWidth) * scrubberWidth
  //     )

  //     videoRef.current.currentTime = timeStartVal

  //     setTrimmerX(trimmerRight - scrubberWidth)
  //     setTimeStart(timeStartVal)
  //     setCurrentTimePosition(0)
  //     setTimeEnd(roundNumber(videoDuration))
  //   } else {
  //     // console.log("**ELEMENT DRAG CASE 3**")
  //     //If scrubber is dragged somewhere in the middle, set those values
  //     let timeStartVal = roundNumber(
  //       (videoDuration / framesWidth) * (pageX - trimmerLeft)
  //     )
  //     videoRef.current.currentTime = timeStartVal

  //     setTrimmerX(pageX)
  //     setTimeStart(timeStartVal)
  //     setCurrentTimePosition(0)
  //     setTimeEnd(
  //       roundNumber(
  //         (videoDuration / framesWidth) * (pageX - trimmerLeft) +
  //           (videoDuration / framesWidth) * scrubberWidth
  //       )
  //     )
  //   }
  // }

  // const closeDragElement = (e, videoRef) => {
  //   console.log("**closeDragElement")
  //   // e.preventDefault()
  //   console.log("**closeDragElement - e")
  //   console.log(e)
  //   window.document.ontouchend = null
  //   window.document.ontouchmove = null
  //   window.document.onmouseup = null
  //   window.document.onmousemove = null
  // }

  // const dragMouseDown = (e, videoRef) => {
  //   console.log("**dragMouseDown")
  //   // e.preventDefault()
  //   console.log("**closeDragElement - e")
  //   console.log(e)
  //   window.document.ontouchend = closeDragElement(e, videoRef)
  //   window.document.ontouchmove = elementDrag(e, videoRef)
  //   window.document.onmouseup = closeDragElement(e, videoRef)
  //   window.document.onmousemove = elementDrag(e, videoRef)
  // }

  const extractFrames = (videoRef, videoSource) => {
    return new Promise((resolve) => {
      // const videoRef = useRef("videoRef")

      console.log("** ref")
      console.log(videoRef)

      //Listen for metadata
      if (videoRef && videoRef.current) {
        videoRef.current.addEventListener("loadedmetadata", (e) => {
          console.log("videoRef.current - loadedmetadata - e")
          console.log(e)
          let duration = e.target.duration
          console.log("((video ::: duration))")
          console.log(duration)

          setVideoDuration(duration)

          // console.log("videoRef.current")
          // console.log(videoRef.current)
          // console.log("videoRef.current.width")
          // console.log(videoRef.current.width)
          // console.log("videoRef.current.height")
          // console.log(videoRef.current.height)
          let dimensionRatio = e.target.videoWidth / e.target.videoHeight

          console.log(`((video dimensionRatio : ${dimensionRatio}))`)

          //:: 1 :: CHECK VIDEO METADATA AND SET ERROR IF NECESSARY
          if (duration > 30) {
            // this.props.updateUploadStage(
            //   0,
            //   "Oops, try uploading a video less than 30 seconds long"
            // )
            return
            // } else if (dimensionRatio > 1 || !dimensionRatio) {
          } else if (dimensionRatio > 1) {
            //if vertical, dimensionRatio will be less than 1. If horiz, will be greater than 1
            // this.props.updateUploadStage(
            //   0,
            //   "Oops, try uploading a vertical video, instead of horizontal"
            // )
            return
          } else {
            // this.props.updateUploadStage(2)
          }

          //:: 2 :: CREATE MINI VIDEOS, FREEZE FRAMED AT THE RIGHT TIME, AND PLACE THEM INTO THE VIDEO FRAME BAR
          let interval = duration / 12
          let currentTime = 0

          //Iterate over the array of time stamps 13 times
          const timeArray = [...Array(13)].map(
            (_, i) => i * interval + currentTime
          )

          console.log("duration")
          console.log(duration)
          console.log("timeArray")
          console.log(timeArray)

          setFinalFramesCount(timeArray.length)
          let timeItemCounter = 0

          //Remove prior images
          if (trimmerRef && trimmerRef.current) {
            trimmerRef.current.innerHtml = ""
          }
          // while (trimmerRef.current.firstChild) {
          //   trimmerRef.current.removeChild(trimmerRef.current.firstChild)
          // }

          timeArray.forEach((time, i) => {
            timeItemCounter++
            console.log("timeItemCounter")
            console.log(timeItemCounter)
            console.log("createImage - pre - videoRef")
            console.log(e.target.currentSrc)
            createImage(e.target.currentSrc, time, i, function () {
              frames.forEach((frame) => {
                trimmerRef.current.appendChild(frame)
              })
            }) //end this.createImage
          }) //end timeArray.forEach
          const returnObj = { duration, videoRef }
          setScrubberDetails(returnObj)

          resolve(returnObj)
        }) //end loadeddata
      } //end if videoRef
    }) //end promise
  }

  return (
    <div class={`row pt-0 mt-0 trimmer-container`} style={{ paddingLeft: 14 }}>
      {/* CTP: {currentTimePosition} :: timeStart: {timeStart} :: timeEnd: {timeEnd}{" "}
      :: finalFramesCount {finalFramesCount} :: trimmerX {trimmerX} ::
      videoDuration {videoDuration} */}
      <div class="col-lg-12 mt-1 d-flex flex-row justify-content-center align-items-center trimmer-container-row">
        {/* ///TRIMMER PLAY/PAUSE BUTTON */}
        <div
          class="d-flex flex-column justify-content-center align-items-center"
          style={{
            backgroundColor: "#000000",
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
            height: 48,
            padding: 4,
            borderRight: "1px solid #666666",
          }}
          onClick={() => playVideo(props.videoRef, isPlaying)}
        >
          {isPlaying ? (
            <MdPause color="#ffffff" size={26} />
          ) : (
            <MdPlayArrow color="#ffffff" size={26} />
          )}
        </div>
        {/* End Trimmer Play/Pause Button */}

        {/* ///TRIMMER BODY: VIDEO FRAME BAR + SCRUBBER \\\ */}
        <div class="trimmer-body">
          {/* ///A. The video frame bar\\\ */}

          <div
            class="d-flex flex-row justify-content-center align-items-center"
            style={{
              position: "relative",
              // width: 300, //add 14 to account for borders
              width: `${finalFramesCount * 23 + 0}px`, //add 14 to account for borders
              // border: "4px solid black",
              // borderRight: "10px solid black",
              // borderTopRightRadius: 4,
              // borderBottomRightRadius: 4,
              height: 48,
              backgroundColor: "#000000",
            }}
          >
            <div
              class="d-flex align-items-center justify-content-center w-100"
              style={{ position: "absolute", zIndex: 0 }}
            >
              {/* <Loading class="small" /> */}
            </div>
            <div
              class="d-flex flex-row justify-content-center align-items-center w-100"
              style={{ zIndex: 1 }}
            >
              <div
                style={{
                  position: "absolute",
                  height: 48,
                  backgroundColor: "#fff",
                  width: 5,
                  borderRadius: 3,
                  boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.4)",
                  // left: 0,
                  left: currentTimePosition ? currentTimePosition : 0,
                }}
              ></div>
              <div
                class="d-flex flex-row justify-content-start align-items-center w-100"
                ref={trimmerRef}
                // style={{ height: 10, border: "1px solid green" }}
                // ref={(element) => (this.frameContainerElement = element)}
              ></div>
            </div>
          </div>

          {/* ///B. The scrubber\\\ */}
          <Stage
            width={scrubberWidth}
            height={48}
            // onMouseDown={checkDeselect}
            // onTouchStart={checkDeselect}
            // ref={stageRef}
            class="d-flex flex-row align-items-center align-self-start"
            style={{
              position: "absolute",
              zIndex: 2,
              marginLeft: 0,
              // width: 30,
              // width: `${scrubberWidth}px`,
              width: scrubberWidth,
              height: 48,
              // border: "4px solid #F8CE46",
              // borderRight: "10px solid #F8CE46",
              // borderLeft: "10px solid #F8CE46",
              // borderRadius: 4,
              // left: 0,
              left: trimmerX ? trimmerX : 0,
              top: 0,
            }}
          >
            <Layer>
              <Scrubber
                // key={i}
                shapeProps={{
                  // fill: "#009ce0",
                  height: 48,
                  id: "rect2",
                  shape: "rect",
                  width: scrubberWidth,
                  x: 0,
                  y: 0,
                }}
                // isSelected={rect.id === publisher.elementSelected.id}
                // onSelect={() => {
                //   // selectShape(rect.id)
                //   updatePublisherField("elementSelected", {
                //     id: rect.id,
                //     shape: "rectangle",
                //   })
                // }}
                // onChange={(newAttrs) => {
                //   console.log("rect - newAttrs")
                //   console.log(newAttrs)
                //   updateElements(newAttrs, i)
                // }}
              />
            </Layer>
          </Stage>

          {/* End The scrubber */}
        </div>
        {/* End Trimmer Body*/}
      </div>
    </div>
  )
}
// class Snippet extends React.Component {
//   constructor(props) {
//     super(props)
//   }

//   render() {
//     return (

//     )
//   }
// }
export default Snippet
