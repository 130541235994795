//React
import React from "react"
import { connect } from "react-redux"

//Gatsby
import { Link } from "gatsby"

//Actions
import {
  updatePublisherField,
  sendUpload,
  sendUploadCloud,
  setImageLibraryContext,
} from "../../actions/createPublisher"

//Components
import EditorSave from "./tools/editorSave"
import CanvasTools from "./tools/canvasTools"
import FontAttributes from "./tools/fontAttributes"
import ImageAttributes from "./tools/imageAttributes"
import StageAttributes from "./tools/stageAttributes"
import ShapeAttributes from "./tools/shapeAttributes"
import LayerControls from "./tools/layerControls"

//Icons
import { FaChevronRight, FaInstagram, FaFacebookSquare } from "react-icons/fa"
import { FaCommentDots, FaRobot, FaShare } from "react-icons/fa"

//Exceptions

//Redux
const mapStateToProps = ({
  publisher,
  isLoading,
  isLoadingImageUpload,
  imageuploadprogress,
  activeTool,
  conversationActive,
  company,
}) => {
  return {
    publisher,
    isLoading,
    isLoadingImageUpload,
    imageuploadprogress,
    activeTool,
    conversationActive,
    company,
  }
}

const mapDispatchToProps = {
  updatePublisherField,
  sendUpload,
  setImageLibraryContext,
}

const Snippet = (props) => {
  const {
    saveImage,
    saveImageCloud,
    saveTemplate,
    changeAttr,
    publisher,
    updatePublisherField,
    sendUpload,
    addNewElement,
    isLoadingImageUpload,
    imageuploadprogress,
    editorContext,
    activeTool,
    reorderLayers,
    removeSelectedElement,
    setImageLibraryContext,
    deselectStage,
    conversationActive,
    recordAudio,
    company,
  } = props

  const publisherMode = publisher && publisher.mode ? publisher.mode : "image"
  const selectedElementShape =
    publisher && publisher.elementSelected && publisher.elementSelected.shape
      ? publisher.elementSelected.shape
      : null

  // console.log("editorTools - publisherMode")
  // console.log(publisherMode)
  // console.log("editorTools - selectedElementShape")
  // console.log(selectedElementShape)

  const selectedScene =
    publisher && publisher.sceneSelected ? publisher.sceneSelected : {}

  let loadedScenes
  let sceneLayers
  if (publisher.loadedScenes) {
    loadedScenes = publisher.loadedScenes
    const currentScene = loadedScenes.find(
      (scene) => scene.sceneid === selectedScene.sceneid
    )
    // console.log("currentScene")
    // console.log(currentScene)
    sceneLayers =
      currentScene && currentScene.scenelayers ? currentScene.scenelayers : []
  }
  // console.log("loadedScenes")
  // console.log(loadedScenes)
  // console.log("sceneLayers")
  // console.log(sceneLayers)
  // const mediaArrayFiltered = mediaArray.filter(
  //   (media) => media.context === publisher.mode
  // )

  return (
    <div class="row d-flex flex-row-reverse flex-md-column align-items-center justify-content-center align-items-md-end row-tools-inner">
      {/* START COL */}
      <div class="col-4 col-md-12 d-flex flex-column align-items-center justify-content-center">
        <EditorSave
          publisher={publisher}
          publisherMode={publisherMode}
          saveImage={saveImage}
          saveImageCloud={saveImageCloud}
          saveTemplate={saveTemplate}
          recordAudio={recordAudio}
          conversationActive={conversationActive}
          company={company}
        />
        <hr size={1} class="d-none d-md-block" />
      </div>
      {/* END COL */}
      {/* START COL */}
      <div class="col-8 col-md-12 d-flex flex-column align-items-center justify-content-center row-tools-sub">
        {editorContext === "side" ||
        (editorContext === "top" && activeTool === "canvas") ? (
          <CanvasTools
            elements={publisher.elements}
            addNewElement={addNewElement}
            updatePublisherField={updatePublisherField}
            deselectStage={deselectStage}
          />
        ) : null}
        <hr size={1} class="d-none d-md-block" />

        {publisherMode === "video" &&
        (editorContext === "side" ||
          (editorContext === "top" && activeTool === "layers")) ? (
          <LayerControls
            title="Layers"
            publisherMode={publisherMode}
            sceneSelected={publisher.sceneSelected}
            sceneLayers={sceneLayers}
            updatePublisherField={updatePublisherField}
            changeAttr={changeAttr}
            sendUpload={sendUpload}
            reorderLayers={reorderLayers}
          />
        ) : null}
        {publisherMode === "image" &&
        (editorContext === "side" ||
          (editorContext === "top" && activeTool === "layers")) ? (
          <LayerControls
            title="Layers"
            publisherMode={publisherMode}
            sceneSelected={publisher.elementSelected}
            sceneLayers={publisher.elements}
            updatePublisherField={updatePublisherField}
            changeAttr={changeAttr}
            sendUpload={sendUpload}
            reorderLayers={reorderLayers}
          />
        ) : null}

        {publisherMode === "image" &&
        !selectedElementShape &&
        (editorContext === "side" ||
          (editorContext === "top" && activeTool === "stage")) ? (
          <StageAttributes
            updatePublisherField={updatePublisherField}
            stage={publisher.stage}
          />
        ) : null}

        {selectedElementShape === "text" &&
        (editorContext === "side" ||
          (editorContext === "top" && activeTool === "font")) ? (
          <FontAttributes
            context="image"
            changeAttr={changeAttr}
            elements={publisher.elements}
            elementSelected={publisher.elementSelected}
            removeSelectedElement={removeSelectedElement}
          />
        ) : null}
        {(selectedElementShape === "rect" ||
          selectedElementShape === "circle") &&
        (editorContext === "side" ||
          (editorContext === "top" && activeTool === "shape")) ? (
          <ShapeAttributes
            context="image"
            changeAttr={changeAttr}
            elements={publisher.elements}
            elementSelected={publisher.elementSelected}
            removeSelectedElement={removeSelectedElement}
          />
        ) : null}

        {(selectedElementShape === "image" || publisherMode === "video") &&
        (editorContext === "side" ||
          (editorContext === "top" && activeTool.includes("image"))) ? (
          <ImageAttributes
            title="Brand library"
            context={publisherMode}
            publisher={publisher}
            elements={publisher.elements}
            elementSelected={publisher.elementSelected}
            changeAttr={changeAttr}
            sendUpload={sendUpload}
            isLoadingImageUpload={isLoadingImageUpload}
            imageuploadprogress={imageuploadprogress}
            updatePublisherField={updatePublisherField}
            editorContext={editorContext}
            activeTool={activeTool}
            removeSelectedElement={removeSelectedElement}
            setImageLibraryContext={setImageLibraryContext}
          />
        ) : null}
      </div>
      {/* END COL */}
    </div>
  )
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(Snippet))
