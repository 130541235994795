import React from "react"
import { connect } from "react-redux"

//Actions
import { updateShareField } from "../../actions/updateForm"

import MediaContainer from "./mediaContainer"

//Icons
import {
  FaFacebookSquare,
  FaTwitter,
  FaInstagram,
  FaRegCommentDots,
  FaSnapchatGhost,
} from "react-icons/fa"
import { MdFileDownload } from "react-icons/md"

const mapStateToProps = ({
  socialmodal,
  isLoadingMediaPreview,
  isLoading,
  share,
  publisher,
  imageexport,
  imageuploadprogress,
}) => {
  return {
    socialmodal,
    isLoadingMediaPreview,
    isLoading,
    share,
    publisher,
    imageexport,
    imageuploadprogress,
  }
}

const mapDispatchToProps = {
  updateShareField,
}

const Snippet = (props) => {
  const {
    publisher,
    socialmodal,
    isLoadingMediaPreview,
    share,
    updateShareField,
    webHostedImage,
    directImageSrc,
    directImageRatio,
    directImagePreview,
    viewportSize,
    imageexport,
    imageuploadprogress,
    userPlan,
  } = props
  const updateShareIntent = (platform) => {
    updateShareField("intent", platform)
  }

  return (
    <div
      class="modal modal-sharer fade"
      id="sharerModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="sharerModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header" style={{ borderBottom: 0 }}>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                updateShareIntent("reset")
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {/* <div class="modal-subheader d-flex flex-row align-items-center justify-content-center">
              <h4
                class="text-center"
                style={{ paddingTop: 8, marginRight: 8 }}
              ></h4>
            </div> */}

            <div
              class="modal-content-container d-flex flex-column align-items-center justify-content-center"
              style={{ margin: "auto" }}
            >
              <MediaContainer
                publisher={publisher}
                socialmodal={socialmodal}
                share={share}
                isLoadingMediaPreview={isLoadingMediaPreview}
                updateShareField={updateShareField}
                webHostedImage={webHostedImage}
                directImageSrc={directImageSrc}
                directImageRatio={directImageRatio}
                directImagePreview={directImagePreview}
                viewportSize={viewportSize}
                imageexport={imageexport}
                imageuploadprogress={imageuploadprogress}
                userPlan={userPlan}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// export default Snippet
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
// export default connect(mapStateToProps, null)(Snippet)
